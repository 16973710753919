import { Avatar, Disclosure, Icon, Text } from '@profitowi/component-library';
import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  icon: string;
  bgColor?: string;
  custom?: boolean;
  fieldNames: string | string[];
}
function CollapsibleContainer({
  children,
  fieldNames,
  icon,
  custom = false,
  bgColor = 'bg-green-600',
}: Props) {
  return (
    <Disclosure
      header={(open: boolean) => (
        <div className="flex items-center px-6">
          {open ? (
            ''
          ) : (
            <div className="flex grow items-center my-4">
              <Avatar bg={bgColor} className="mr-3 !h-16 !w-16 flex items-center justify-center">
                <Icon name={icon} custom={custom} className={custom ? 'w-12' : 'text-[36px]'} />
              </Avatar>
              <div className="flex flex-col w-4/5">
                {Array.isArray(fieldNames) ? (
                  fieldNames.map((name, index) => {
                    if (index < 2) {
                      return (
                        <Text key={name[0] + index} size="base" weight="bold" className="p-0">
                          {name}
                        </Text>
                      );
                    } else if (index === fieldNames.length - 1)
                      return (
                        <Text
                          key={name[0] + index}
                          weight="bold"
                          size="xs"
                          className="text-gray-600">
                          +{index - 1} WIĘCEJ
                        </Text>
                      );
                  })
                ) : (
                  <Text size="base" weight="bold" className="p-0">
                    {fieldNames}
                  </Text>
                )}
              </div>
            </div>
          )}
          <Icon name={open ? 'chevron-expand' : 'chevron-down'} className=" ml-auto mr-4" />
        </div>
      )}>
      <div className="px-6">{children}</div>
    </Disclosure>
  );
}

export default CollapsibleContainer;
