import { Box, Button, Icon, Loader } from '@profitowi/component-library';
import { AriaTextFieldOptions } from '@react-aria/textfield';
import { AxiosError, AxiosResponse } from 'axios';
import { ClassValue } from 'clsx';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import useNotificationStore from 'hooks/useNotificationStore';
import { deleteFileById, uploadAttachment } from 'services/forms';
import { FileUploadContext } from 'types/forms';

type Props = AriaTextFieldOptions<'input'> & {
  className?: ClassValue;
  name: string;
  context: FileUploadContext;
};

type Payload = {
  payload: FormData;
  fileName: string;
};

const fieldToFileInfos = (value: string) =>
  value
    ?.split('|')
    .filter((v) => v)
    .map((fileInfo) => {
      const [fileId, fileName] = fileInfo.split(';');
      return { fileId: Number(fileId), fileName: fileName };
    });

const fileInfosToField = (fileInfos: { fileId: number; fileName: string }[]) =>
  fileInfos.map(({ fileId, fileName }) => `${fileId};${fileName}`).join('|');

const clearFileInput = (ctrl: any) => {
  try {
    ctrl.value = null;
  } catch (ex) {}
  if (ctrl.value) {
    ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
  }
};

const FileUploadField = ({ name, context }: Props) => {
  const { agentId, assessmentId, aClientId, id, aId, aSubjectId } = context;
  const [field, , helpers] = useField<string>(name);
  const [helpingValue, setHelpingValue] = useState(field.value ? field.value : '');
  const { addNotification, removeNotification } = useNotificationStore(
    ({ addNotification, removeNotification }) => ({
      addNotification,
      removeNotification,
    })
  );

  useEffect(() => {
    helpers.setValue(helpingValue);
  }, [helpingValue]);

  const upload = useMutation<AxiosResponse, AxiosError, Payload>(
    ['uploadAttachment', aSubjectId, name],
    ({ payload, fileName }) =>
      uploadAttachment(
        payload,
        agentId,
        assessmentId,
        aClientId,
        id,
        aId,
        aSubjectId,
        name,
        fileName
      ),
    {
      onSuccess: ({ data }) => {
        setHelpingValue((prev) => {
          deleteDuplicates(data, prev);
          let nonDuplicates = fieldToFileInfos(prev).filter((fi) => fi.fileName != data.fileName);
          const rest = fileInfosToField(nonDuplicates);
          return `${rest}${rest ? '|' : ''}${data.fileId};${data.fileName}`;
        });
        addNotification({
          id: 'postArea-success',
          title: 'Przesyłanie pliku powiodło się',
          type: 'success',
        });
        removeNotification('postArea-error');
      },
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Przesyłanie pliku nie powiodło się',
          type: 'error',
        });
      },
    }
  );

  const deleteById = useMutation<AxiosResponse, AxiosError, number>(
    (fileId) => deleteFileById(agentId, assessmentId, aClientId, id, aId, aSubjectId, fileId),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Usuwanie nie powiodło sie',
          type: 'error',
        });
      },
    }
  );

  const deleteDuplicates = (data: any, prev: any) => {
    fieldToFileInfos(prev)
      .filter((fi) => fi.fileName == data.fileName)
      .map(({ fileId }) => {
        deleteById.mutate(fileId);
      });
  };

  const changeHandler = (event: any) => {
    const files = event.currentTarget.files;
    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      const file = files.item(i);
      formData.append('file', file);

      if (formData) {
        upload.mutate({
          fileName: file.name,
          payload: formData,
        });
      }
    }

    // this makes onchange fire even if files with same names are selected
    clearFileInput(document.getElementById('fileUpload_' + name));
  };

  const openFileDialog = () => {
    const input: HTMLElement | null = document.getElementById('fileUpload_' + name);
    input?.click();
  };

  return (
    <div className="flex flex-col gap-3 w-full mt-3">
      {field.value && (
        <div className="flex flex-wrap shrink-0 gap-3 max-w-full">
          {field.value.split('|').map((fileInfo) => {
            if (!fileInfo) return null;
            const [fileId, fileName] = fileInfo.split(';');
            return (
              <Box key={fileId} className="flex justify-between shrink-0 w-full">
                <Button
                  key={fileId}
                  onPress={() => {}}
                  className="flex-row flex-nowrap items-center">
                  {fileName}
                </Button>
                <Button
                  variant="light"
                  onPress={(e) => {
                    deleteById.mutate(Number(fileId));
                    setHelpingValue((prev) => {
                      let others = fieldToFileInfos(prev).filter(
                        (fi) => fi.fileId != Number(fileId)
                      );
                      return `${fileInfosToField(others)}`;
                    });
                  }}
                  className="cursor-pointer">
                  <Icon name="x" className="text-xl" />
                </Button>
              </Box>
            );
          })}
          {upload.isLoading && (
            <div className="flex justify-center w-full">
              <Loader className="h-12 w-12 m-3" />
            </div>
          )}
        </div>
      )}
      <input
        type="file"
        id={'fileUpload_' + name}
        name="file"
        className="hidden"
        accept=".pdf,.jpg,.jpeg,.png,.tiff"
        multiple
        onChange={changeHandler}
      />
      <Button
        variant="outline-primary"
        className="flex-row flex-nowrap items-center"
        onPress={openFileDialog}>
        Dodaj pliki
        <Icon name="file-earmark-plus" className="text-xl ml-3" />
      </Button>
    </div>
  );
};

export default FileUploadField;
