import AREAS from 'components/AreaIcons/areaIcons';

import { ClientType } from './client';
import { PriorityType } from './customTypes';

export type AreaField = {
  clientId: number;
  clientType: ClientType;
  areas?: Area[];
};

export type InitialArea = Partial<Area>;

export type Area = {
  id: number;
  areaType: keyof typeof AREAS;
  priority: PriorityType;
  meeting: string;
};

export type AreaNames = {
  INDIVIDUAL: [];
  COMPANY: [];
};

export type AreaList = {
  id: number;
  areaType: keyof typeof AREAS;
  priority: PriorityType;
  meeting: string;
  data: Area;
};

export type AreaDict = {
  name: string;
  secondaryIcon: undefined | string;
  icon: string;
  custom: boolean;
  bgColor: string;
};

export const options = [
  {
    priority: 'LOWEST',
    text: 'Nieważne',
  },
  {
    priority: 'LOW',
    text: 'Mniej ważne',
  },
  {
    priority: 'HIGH',
    text: 'Ważne',
  },
  {
    priority: 'HIGHEST',
    text: 'Bardzo ważne',
  },
];
