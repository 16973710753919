import { Row } from 'react-table';

import Table from 'components/Table/table';

import { ProductConditions } from '../../../types/products';
import { columns } from './columns';

type Props = {
  productConditionsData: ProductConditions[];
  productId?: number;
  onDeleteRecommendation: (documentId: number) => void;
};

const ProductConditionsTable = ({
  productConditionsData,
  productId,
  onDeleteRecommendation,
}: Props) => {
  return (
    <Table
      data={productConditionsData}
      columns={columns(onDeleteRecommendation)}
      rowKey={productId}
      getRowId={(row) => row.recConditionId}
    />
  );
};

export default ProductConditionsTable;
