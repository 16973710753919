import { CSSProperties } from 'react';

import { AnswerType, FIELD_TYPE } from 'types/forms';
import { PossibleAnswers, SummaryClient } from 'types/summary';

import Header from '../components/Header';
import PriorityDots from '../components/PriorityDots';
import SectionTitle from '../components/SectionTitle';

const style = {
  page: {
    height: '100%',
  },
  pageBreak: {
    pageBreakBefore: 'always',
  },
  content: {
    main: {
      width: '70%',
      margin: 'auto',
    },
    text: {
      fontFamily: 'Sora',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '25px',
    },
    answerContainer: {
      width: '40%',
      display: 'table-cell',
      verticalAlign: 'middle',
    },
  },
};

const questionContainer: CSSProperties = {
  display: 'table',
  width: '100%',
  marginBottom: '15px',
  pageBreakInside: 'avoid',
  minHeight: '35px',
};

type Props = {
  clients: SummaryClient[];
};

export enum SLIDER_TYPE {
  PRIORITY_3_SLIDE_ENUM = 'PRIORITY_3_SLIDE_ENUM',
  SLIDE_ENUM = 'SLIDE_ENUM',
}

const SummaryAnswers = ({ clients }: Props) => {
  const getPriority = (
    answer: string,
    type: keyof typeof SLIDER_TYPE,
    possibleAnswers: PossibleAnswers
  ) => {
    if (type === SLIDER_TYPE.SLIDE_ENUM) {
      return possibleAnswers
        .sort((a, b) => a.orderNo - b.orderNo)
        .map((answer) => answer.value)
        .indexOf(answer);
    }
    switch (answer) {
      case 'nieważne':
        return 0;
      case 'ważne':
        return 1;
      case 'bardzo ważne':
        return 2;
      default:
        return 0;
    }
  };

  const getPriorityLevels = (answerType: AnswerType, possibleAnswers: PossibleAnswers) => {
    if (answerType.subGroup === SLIDER_TYPE.PRIORITY_3_SLIDE_ENUM)
      return ['nieważne', 'ważne', 'bardzo ważne'];
    if (answerType.subGroup === SLIDER_TYPE.SLIDE_ENUM)
      return possibleAnswers.sort((a, b) => a.orderNo - b.orderNo).map((answer) => answer.value);
  };

  const renderAnswer = (
    answer: string,
    answerType: AnswerType,
    possibleAnswers: PossibleAnswers
  ) => {
    switch (answerType.fieldType) {
      case FIELD_TYPE.SLIDER:
        return (
          <PriorityDots
            priorityLevels={
              Object.values(SLIDER_TYPE).includes(answerType.subGroup as SLIDER_TYPE)
                ? getPriorityLevels(answerType, possibleAnswers)
                : undefined
            }
            priority={getPriority(answer, answerType.subGroup as SLIDER_TYPE, possibleAnswers)}
          />
        );
      case FIELD_TYPE.CALENDAR: {
        return <span style={style.content.text}>{answer.slice(0, 10)}</span>;
      }
      default:
        return (
          <span style={style.content.text}>
            {answer} {answerType.unit ? answerType.unit : ''}{' '}
          </span>
        );
    }
  };

  return (
    <div id="answer" style={{ ...style.page }}>
      <div style={{ ...style.content.main }}>
        {clients.length > 0 &&
          clients.map(
            (client) =>
              client.forms.length > 0 &&
              client.forms.map((form) => (
                <div style={{ pageBreakBefore: 'always' }}>
                  <Header text="Szczegóły analizy potrzeb w obszarze">
                    <SectionTitle text={form.name} />
                  </Header>

                  {form.details.length > 0 &&
                    form.details.map(
                      (detail) =>
                        detail.details.length > 0 &&
                        detail?.details
                          ?.sort((a, b) => a.orderNo - b.orderNo)
                          ?.map(
                            ({ question, answer, answerType, possibleAnswers }) =>
                              answer &&
                              question && (
                                <div style={questionContainer}>
                                  <div style={{ display: 'table-cell', width: '50%' }}>
                                    <span style={{ ...style.content.text, color: '#474747' }}>
                                      {question}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      ...style.content.answerContainer,
                                      textAlign: 'right',
                                    }}>
                                    {renderAnswer(answer, answerType, possibleAnswers)}
                                  </div>
                                </div>
                              )
                          )
                    )}
                </div>
              ))
          )}
      </div>
    </div>
  );
};

export default SummaryAnswers;
