import { Button, Icon } from '@profitowi/component-library';

const variants = {
  pencil: 'pencil-square',
  arrow: 'arrow-right-short',
  'paper-plane': 'send',
  download: 'download',
  cancel: 'x-octagon',
  expand: 'chevron-expand',
  check: 'check-lg',
};

type Props = {
  cancelLabel?: string;
  confirmLabel?: string;
  isConfirmDisabled?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  leftButtonIcon?: keyof typeof variants;
  rightButtonIcon?: keyof typeof variants;
  className?: string;
};

const ModalActions = ({
  leftButtonIcon = 'cancel',
  rightButtonIcon = 'pencil',
  cancelLabel = 'Anuluj',
  confirmLabel = 'Zapisz',
  isConfirmDisabled = false,
  isLoading = false,
  onCancel = () => {},
  onConfirm = () => {},
  className = '',
}: Props) => {
  return (
    <div className={`flex space-x-8 items-center justify-center w-full ${className}`}>
      <Button isDisabled={isLoading} onPress={onCancel} type="reset" variant="outline-primary">
        {cancelLabel}
        <Icon name={variants[leftButtonIcon]} className="ml-2 text-md" />
      </Button>
      <Button
        onPress={onConfirm}
        type="submit"
        variant="primary"
        isDisabled={isConfirmDisabled || isLoading}>
        {confirmLabel}
        <Icon name={variants[rightButtonIcon]} className="ml-2 text-md" />
      </Button>
    </div>
  );
};
export default ModalActions;
