import { Alert, Loader, Modal } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';

import { AGENT } from 'constants/queries/agent';
import { getAgent } from 'services/agent';
import { Agent } from 'types/agent';

import UserDetails from './AgentDetails';
import UserEdit from './AgentForm';

const AgentModal = () => {
  const { id = '' } = useParams();

  const {
    data: agent,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery<Agent, AxiosError>([AGENT.AGENT], () => getAgent(id));

  return (
    <Modal
      description="Uzupełnij dane na potrzeby prezentacji"
      title="Konto użytkownika"
      size="4xl"
      textSize="xl"
      visible>
      {isSuccess && agent && (
        <Routes>
          <Route index element={<UserDetails agent={agent} />} />
          <Route path="edit" element={<UserEdit agent={agent} refetch={refetch} />} />
        </Routes>
      )}

      {isError && (
        <Alert className="mx-6 my-4" type="error">
          {error?.message}
        </Alert>
      )}

      {isLoading && <Loader className="h-12 w-12" />}

      <Outlet />
    </Modal>
  );
};

export default AgentModal;
