import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { AssessmentDto } from 'types/assessment';

type UseCrmClient = {
  crmClientId: string;
  setCrmClientId: (crmClientId: string) => void;
};

export const useCrmClient = create<UseCrmClient>((set) => ({
  crmClientId: '',
  setCrmClientId: (crmClientId) => set({ crmClientId }),
}));

type AssessmentState = {
  assessment: Partial<AssessmentDto>;
  resetAssessment: () => void;
  setAssessment: (assessment: AssessmentDto) => void;
};

const initialValue = {};

// FIXME: remove devtools if process.env.NODE_ENV === 'production'
export const useAssessmentStore = create<AssessmentState>(
  devtools((set) => ({
    assessment: initialValue,
    resetAssessment: () => set({ assessment: initialValue }, false, 'resetAssessment'),
    setAssessment: (assessment) => set({ assessment }, false, 'setAssessment'),
  }))
);
