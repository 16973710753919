import { getFamilyImage } from 'assets/summaryPdfIcons/family';

import Header from '../components/Header';

const styles = {
  page: {
    backgroundColor: '#FFFFFF',
    height: '400px',
  },
  body: {
    height: '100%',
  },

  content: {
    container: {
      display: 'table',
      height: '100%',
      width: '100%',
    },
    text: {
      display: 'table-cell',
      paddingLeft: '80px',
      fontSize: '25px',
      fontFamily: 'Sora',
      fontStyle: 'normal',
      verticalAlign: 'middle',
      fontWeight: '700',
    },
  },
};

const SecondPage = () => {
  return (
    <div style={styles.page}>
      <div style={styles.body}>
        <Header text="Trudne decyzje podejmujemy razem" />

        <div style={styles.content.container}>
          <div style={{ width: '50%', display: 'table-cell', verticalAlign: 'middle' }}>
            <span style={styles.content.text}>
              Ułatwiamy podjęcie trudnych decyzji w sprawie ubezpieczeń, kredytów i oszczędności
            </span>
          </div>

          <img
            src={`data:image/png;base64,${getFamilyImage()}`}
            style={{ position: 'absolute', right: 2, transform: 'scale(0.7)' }}
            alt=""
          />
          <div
            style={{
              display: 'table-cell',
              backgroundColor: '#011983',
              width: '25%',
              height: '100%',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SecondPage;
