import { Avatar, Badge, Box, Text } from '@profitowi/component-library';
import { useMemo } from 'react';

import { BasicClientData } from 'types/assessment';

interface Props {
  assessmentId: number;
  date: string;
  onClick: () => void;
  tags?: string[];
  client: BasicClientData;
}

function TabTile({ assessmentId, date, onClick, tags, client }: Props) {
  const { firstName, lastName, name } = client;
  const initials = useMemo(
    () => [name?.[0], firstName?.[0], lastName?.[0]].join(''),
    [firstName, lastName, name]
  );
  const title = useMemo(
    () => [name, firstName, lastName].filter(Boolean).join(' '),
    [firstName, lastName, name]
  );

  return (
    <Box className="flex grow flex-col justify-between px-4 py-5">
      <div className="flex justify-between">
        <Text className="text-secondary" size="sm" weight="semibold">
          {date || 'Brak danych'}
        </Text>

        <Text className="text-secondary" size="xs">
          #{assessmentId}
        </Text>
      </div>
      <div className="flex items-center">
        <Avatar circular className="mr-3">
          {initials || '?'}
        </Avatar>
        <button className="hover:underline" onClick={onClick}>
          <Text size="lg" weight="bold">
            {title || 'Brak danych'}
          </Text>
        </button>
      </div>
      <div>
        {tags?.map((tag) => (
          <Badge key={tag} className="mt-3 mr-2">
            {tag}
          </Badge>
        ))}
      </div>
    </Box>
  );
}

export default TabTile;
