import businessContinuity from './pop-up-business-continuity.jpg';
import capitalMultiplication from './pop-up-capital-multiplication.jpg';
import car from './pop-up-car.jpg';
import child from './pop-up-child.jpg';
import companyGoods from './pop-up-company-goods.jpg';
import courtCosts from './pop-up-court-costs.jpg';
import employeeMedicalPackages from './pop-up-employee-medical-packages.jpg';
import financialProblems from './pop-up-financial-problems.jpg';
import group from './pop-up-group.jpg';
import houseCredit from './pop-up-house-credit.jpg';
import ilnesses from './pop-up-ilnesses.jpg';
import loanForAnyPurpose from './pop-up-loan-for-any-purpose.jpg';
import pension from './pop-up-pension.jpg';
import relativesProtection from './pop-up-relatives-protection.jpg';
import houseInsurance from './pop_up_dom2.jpg';
import ocInLife from './pop_up_oc.jpg';
import travels from './pop_up_pordoze.jpg';

const infoGraphics = {
  car: car,
  home: houseInsurance,
  children: child,
  ambulance: ilnesses,
  piggy: relativesProtection,
  'rocking-chair': pension,
  'money-arrow-up': capitalMultiplication,
  luggage: travels,
  weight: ocInLife,
  savings: houseCredit,
  loan: loanForAnyPurpose,
  hearth: group,
  medical: employeeMedicalPackages,
  plant: companyGoods,
  suitcase: businessContinuity,
  exchange: courtCosts,
  document: financialProblems,
};

export default infoGraphics;
