import { AutoCompleteField, InputField, Modal, Option, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import * as yup from 'yup';

import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import ModalActions from '../../../components/ModalActions';
import { requiredFieldMessage } from '../../../constants/requiredFieldMessage';
import { copyRecommendations, getSimplifiedProductsList } from '../../../services/products';
import { Product } from '../../../types/products';

const validationSchema = yup.object().shape({
  sourceRecommendationName: yup.string(),
  sourceRecommendationCode: yup.string(),
  nameSearch: yup.string().required(requiredFieldMessage),
  shortcutSearch: yup.string().required(requiredFieldMessage),
});

type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  refetchProducts: () => void;
};

const AutoFillAllAutoompleteFieldsOnChange = ({
  recommendationOptions,
}: {
  recommendationOptions: Omit<Product, 'recConditions'>[];
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { nameSearch, shortcutSearch } = values as { nameSearch: number; shortcutSearch: number };

  useEffect(() => {
    const selectedRecommendation = recommendationOptions.find((recommendation) => {
      return recommendation.productId === nameSearch;
    });

    setFieldValue('shortcutSearch', selectedRecommendation?.productId);
  }, [nameSearch]);

  useEffect(() => {
    const selectedRecommendation = recommendationOptions.find((recommendation) => {
      return recommendation.productId === shortcutSearch;
    });

    setFieldValue('nameSearch', selectedRecommendation?.productId);
  }, [shortcutSearch]);

  return null;
};

function CopyRecommendation({ refetchProducts }: Props) {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [nameSearch, setNameSearch] = useState<string>('');
  const [shortcutSearch, setShortcutSearch] = useState<string>('');
  const [productNameOptions, setProductNameOptions] = useState<Option[]>([]);
  const [productShortcutOptions, setProductShortcutOptions] = useState<Option[]>([]);
  const [debouncedQueryParam] = useDebounce([nameSearch, shortcutSearch], 500);

  const {
    mutate: addRecommendationMutate,
    isLoading: addIsLoading,
    isError: addIsError,
    error: addError,
  } = useMutation<AxiosResponse<any>, AxiosError, { sourceProductId: number }>(
    ({ sourceProductId }) => {
      return copyRecommendations(sourceProductId, state?.productId);
    },
    {
      onSuccess: () => {
        navigate(-1);
        refetchProducts();
      },
    }
  );

  const { data = [] } = useQuery(
    [debouncedQueryParam],
    () => getSimplifiedProductsList(nameSearch, shortcutSearch),
    {
      onSuccess: (data) => {
        const productNameArray = data.map((product) => ({
          value: product.name,
          key: product.productId,
        }));
        const productShortcutArray = data.map((product) => ({
          value: product.shortcut,
          key: product.productId,
        }));

        setProductNameOptions(productNameArray);
        setProductShortcutOptions(productShortcutArray);
      },
    }
  );

  function onSubmit(values: FormValues) {
    const { nameSearch } = values;

    addRecommendationMutate({ sourceProductId: Number.parseInt(nameSearch) });
  }

  function setQuery(value: string, isCode: boolean) {
    setShortcutSearch('');
    setNameSearch('');
    if (!isCode) {
      setNameSearch(value);
      setShortcutSearch('');
    }
    if (isCode) {
      setShortcutSearch(value);
      setNameSearch('');
    }
  }

  return (
    <Modal title={`Kopiuj rekomendacje`} visible>
      <Formik
        initialValues={
          {
            sourceRecommendationName: state?.sourceRecommendationName,
            sourceRecommendationCode: state?.sourceRecommendationCode,
            nameSearch: undefined,
            shortcutSearch: undefined,
          } as unknown as FormValues
        }
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ errors, values }) => (
          <Form className="flex flex-col gap-4 h-full">
            <Text as="strong" size="md" className="my-0">
              Rekomendacje kopiowane z:
            </Text>
            <InputField label="Nazwa produktu" name="sourceRecommendationName" isDisabled />
            <InputField label="Kod produktu" name="sourceRecommendationCode" isDisabled />

            <Text as="strong" size="md" className="mt-2">
              Rekomendacje kopiowane do:
            </Text>
            <AutoCompleteField
              label="Wyszukaj produkt"
              name="nameSearch"
              options={productNameOptions}
              query={nameSearch}
              setQuery={(value) => setQuery(value, false)}
            />
            <AutoCompleteField
              label="Wyszukaj kod"
              name="shortcutSearch"
              options={productShortcutOptions}
              query={shortcutSearch}
              setQuery={(value) => setQuery(value, true)}
            />

            <ModalActions
              className="mt-4"
              isLoading={addIsLoading}
              isConfirmDisabled={!!Object.values(errors).length}
              onCancel={() => navigate(-1)}
            />

            <AutoFillAllAutoompleteFieldsOnChange recommendationOptions={data} />
            {addIsError && <ErrorMessages error={addError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default CopyRecommendation;
