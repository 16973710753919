import { Alert, AutoCompleteField, Loader, SelectField } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, Formik, FormikValues } from 'formik';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import ModalActions from 'components/ModalActions';
import { CLIENT } from 'constants/queries/client';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore } from 'hooks/useStore';
import { createAssessment } from 'services/assessment';
import { getClients } from 'services/client';
import { AssessmentDto, AssessmentPayload } from 'types/assessment';
import { ClientType, Clients } from 'types/client';

const initialValues = {
  customer: '',
  legalForm: '',
};
const options: { key: ClientType; value: string }[] = [
  { key: ClientType.INDIVIDUAL, value: 'Osoba prywatna' },
  { key: ClientType.COMPANY, value: 'Firma' },
];

type Payload = {
  agentId: number;
  payload?: AssessmentPayload;
  assessmentType: 'SIMPLE' | 'FULL';
};

type Props = {
  setSelectedCrmClientId: Dispatch<SetStateAction<string>>;
  type?: string;
};

const CustomerSearchForm = ({ setSelectedCrmClientId, type }: Props) => {
  const [query, setQuery] = useState('');
  const agentId = useAgentStore((state) => state.agent.agentId);
  const setAssessment = useAssessmentStore((state) => state.setAssessment);

  const [debouncedQueryParam] = useDebounce(query, 500);
  const navigate = useNavigate();

  const { data: clients = [] } = useQuery<Clients, AxiosError>(
    [CLIENT.GET_CLIENTS, debouncedQueryParam],
    () => getClients(debouncedQueryParam, agentId),
    {
      enabled: debouncedQueryParam.length >= 3,
    }
  );

  const { error, isError, isLoading, mutate } = useMutation<AssessmentDto, AxiosError, Payload>(
    (payload) => createAssessment(payload),
    {
      onSuccess: (assessment) => {
        setAssessment(assessment);
        navigate('/aboutProfitowi');
      },
    }
  );

  const clientOptions = useMemo(
    () => clients.map(({ crmClientId, name }) => ({ key: crmClientId, value: name })),
    [clients]
  );

  const handleSubmit = ({ customer, legalForm }: FormikValues) => {
    if (agentId) {
      if (customer && clients) {
        const client = clients.find(({ crmClientId }) => crmClientId === customer);

        client && setSelectedCrmClientId(client?.crmClientId);
      } else {
        const payload = {
          agentId: agentId,
          payload: {
            clientType: legalForm,
          },
          assessmentType: type as 'SIMPLE' | 'FULL',
        };

        mutate(payload);
      }
    }
  };

  return (
    <Formik validateOnBlur initialValues={initialValues} onSubmit={handleSubmit}>
      <Form className="space-y-8">
        {isError && <Alert type="error"> {error?.message}</Alert>}
        {isLoading && (
          <div className="absolute inset-0 bg-gray-light opacity-70">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Loader className="h-12 w-12" />
            </div>
          </div>
        )}

        {/* TODO modify component so it can be disabled */}
        <SelectField options={options} name="legalForm" label="Wybierz formę klienta" />

        <div className="flex justify-center">lub</div>

        {/* TODO modify component so it can be disabled */}
        <AutoCompleteField
          label="Wyszukaj"
          name="customer"
          options={clientOptions}
          query={query}
          setQuery={setQuery}
        />

        <ModalActions
          confirmLabel="Rozpocznij analizę"
          rightButtonIcon="arrow"
          onCancel={() => navigate('/')}
        />
      </Form>
    </Formik>
  );
};

export default CustomerSearchForm;
