import { Icon, Sidebar, Text } from '@profitowi/component-library';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useNotificationStore from 'hooks/useNotificationStore';
import { useStepValidation } from 'hooks/useStepValidation';
import { ASSESSMENT_TYPE } from 'types/assessment';

import logo from '../../assets/logo-white.svg';
import AgentAvatar from './AgentAvatar';
import SidebarButton from './SidebarButton';

const MainSidebar = () => {
  const [currentTab, setCurrentTab] = useState<string>('');
  const [previousTab, setPreviousTab] = useState<string>();
  const { validateSteps, stepsState, clearValidationSteps } = useStepValidation();
  const { removeNotification } = useNotificationStore();
  const navigate = useNavigate();

  useEffect(() => {
    removeNotification('postArea-error');
    setPreviousTab(currentTab);

    if (
      currentTab &&
      previousTab &&
      (previousTab === ASSESSMENT_TYPE.AREA ||
        previousTab === ASSESSMENT_TYPE.FORM ||
        previousTab === ASSESSMENT_TYPE.RECOMMENDATION ||
        previousTab === ASSESSMENT_TYPE.YOUR_DATA)
    )
      validateSteps(previousTab);
  }, [currentTab, validateSteps, removeNotification]);

  const navigateToDashboard = () => {
    clearValidationSteps();
    navigate('/');
  };

  return (
    <Sidebar orientation="left" className="!bg-primary">
      <div className="space-y-8">
        <div className="flex justify-center">
          <img className="cursor-pointer" src={logo} alt="" onClick={navigateToDashboard} />
        </div>

        <nav className="flex flex-col align-items-left">
          <SidebarButton
            key={ASSESSMENT_TYPE.ABOUT_PROFITOWI}
            type={ASSESSMENT_TYPE.ABOUT_PROFITOWI}
            setCurrentTab={setCurrentTab}
            to="/aboutProfitowi">
            <Icon name="chat-left-text-fill" className="mr-3 w-4" />
            <Text>O Profitowi</Text>
          </SidebarButton>

          <SidebarButton
            key={ASSESSMENT_TYPE.AREA}
            type={ASSESSMENT_TYPE.AREA}
            setCurrentTab={setCurrentTab}
            to="/howCanWeHelp">
            <Icon name="shield-check" className="mr-3 w-4" />
            <Text>W czym pomagamy</Text>
            {stepsState[ASSESSMENT_TYPE.AREA]?.valid === false && (
              <Icon name="exclamation-square-fill" className="text-xl ml-auto text-red-600" />
            )}
          </SidebarButton>

          <SidebarButton
            key={ASSESSMENT_TYPE.YOUR_DATA}
            type={ASSESSMENT_TYPE.YOUR_DATA}
            setCurrentTab={setCurrentTab}
            to="/yourData">
            <Icon name="person" className="mr-3 w-4" />
            <Text>Twoje dane</Text>
            {stepsState[ASSESSMENT_TYPE.YOUR_DATA]?.valid === false && (
              <Icon name="exclamation-square-fill" className="text-xl ml-auto text-red-600" />
            )}
          </SidebarButton>

          <SidebarButton
            key={ASSESSMENT_TYPE.FORM}
            setCurrentTab={setCurrentTab}
            type={ASSESSMENT_TYPE.FORM}
            to="/yourNeeds">
            <Icon name="file" className="mr-3 w-4" />
            <Text>Twoje potrzeby</Text>
            {stepsState[ASSESSMENT_TYPE.FORM]?.valid === false && (
              <Icon name="exclamation-square-fill" className="text-xl ml-auto text-red-600" />
            )}
          </SidebarButton>

          <SidebarButton
            key={ASSESSMENT_TYPE.RECOMMENDATION}
            setCurrentTab={setCurrentTab}
            type={ASSESSMENT_TYPE.RECOMMENDATION}
            to="/recommendedProducts">
            <Icon name="bookmark-check" className="mr-3 w-4" />
            <Text>Rekomendowane produkty</Text>
            {stepsState[ASSESSMENT_TYPE.RECOMMENDATION]?.valid === false && (
              <Icon name="exclamation-square-fill" className="text-xl ml-auto text-red-600" />
            )}
          </SidebarButton>

          <SidebarButton
            key={ASSESSMENT_TYPE.SUMMARY}
            setCurrentTab={setCurrentTab}
            type={ASSESSMENT_TYPE.SUMMARY}
            to="/summary">
            <Icon name="collection" className="mr-3 w-4" />
            <Text>Podsumowanie</Text>
          </SidebarButton>
        </nav>
      </div>

      <AgentAvatar />
    </Sidebar>
  );
};

export default MainSidebar;
