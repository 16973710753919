import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { omit } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const AutoSave = <T extends {}>({
  handleSubmit,
  omitKeys = [],
}: {
  handleSubmit: (values: T, isAutoSave: boolean) => void;
  omitKeys?: string[];
}) => {
  const { dirty, isSubmitting, values } = useFormikContext<T>();

  const validateValues = omit(values, omitKeys);
  const prevValues = useRef(validateValues);

  const debouncedHandleSubmit = useDebouncedCallback((values) => handleSubmit(values, true), 500);

  useEffect(() => {
    if (dirty && !isEqual(prevValues.current, validateValues)) {
      prevValues.current = validateValues;
      debouncedHandleSubmit(values);
    }
  }, [debouncedHandleSubmit, dirty, isSubmitting, values, validateValues]);

  return null;
};

export default AutoSave;
