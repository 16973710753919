import { Alert, Button, Icon, InputField, Option, Select } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { COMMUNICATION_TYPES } from 'constants/communicationTypes';
import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { getPolling, postVerification, validateCode } from 'services/verification';
import { ClientMarketingConsents } from 'types/client';
import { Statements } from 'types/statements';
import { CodeParams } from 'types/verification';

const styles = {
  disabled: 'pointer-events-none opacity-10',
};

type Props = {
  statements: Statements[];
  setIsDisabled: (isDisabled: boolean) => void;
  setFetching?: (fetching: boolean) => void;
  aclientId?: number;
};

const VerificationCode = ({ statements, aclientId, setIsDisabled, setFetching }: Props) => {
  const [communicationType, setCommunicationType] = useState<Option>(COMMUNICATION_TYPES[0]);
  const [field, meta] = useField('verificationCode');
  const [isRefetch, setIsRefetch] = useState<boolean>();
  const { addNotification, removeNotification } = useNotificationStore(
    ({ addNotification, removeNotification }) => ({
      addNotification,
      removeNotification,
    })
  );

  const { values, isValid } = useFormikContext<ClientMarketingConsents>();
  const agentId = useAgentStore((state) => state.agent.agentId);

  const { error, touched } = meta;

  useEffect(() => {
    if (isValid && field.value.length === 6) handleValidateCode(field.value);
  }, [field.value]);

  const { data: confirmationCode, mutate: sendConfirmation } = useMutation<
    AxiosResponse,
    AxiosError,
    CodeParams
  >(
    ({ agentId, clientId, verificationType, confirmationType, payload }) =>
      postVerification(agentId, clientId, verificationType, confirmationType, payload),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Wystąpił błąd podczas wysyłania potwierdzenia',
          type: 'error',
        });
      },
      onSuccess: () => {
        addNotification({
          id: 'postArea-success',
          title: `${communicationType.value} został wysłany pomyślnie`,
          type: 'success',
        });
        removeNotification('postArea-error');
      },
    }
  );

  const { isError } = useQuery<Boolean, AxiosError>(
    'pollingForConfirmation',
    () => getPolling(agentId, values.personalData.id, confirmationCode?.data),
    {
      enabled: !!confirmationCode && isRefetch,
      refetchInterval: 30000,
    }
  );

  const { mutate: validationMutate, isError: isValidateCodeError } = useMutation<
    boolean,
    AxiosError,
    any
  >(({ agentId, clientId, code, payload }) => validateCode(agentId, clientId, code, payload), {
    onError: (error) => {
      addNotification({
        id: 'postArea-error',
        message: error.message,
        title: 'Wystąpił błąd podczas potwierdzania',
        type: 'error',
      });
    },
    onSuccess: () => {
      removeNotification('postArea-error');
      removeNotification('postArea-autosave');
      addNotification({
        id: 'postArea-success',
        title: `${communicationType.value} został potwierdzony pomyślnie.`,
        type: 'info',
      });
      setIsRefetch(false);
      setIsDisabled(false);
    },
  });

  const createStatementsPayload = (selectedStatements: string[]) =>
    statements.map(({ statementId }) => {
      return { statementId, consent: selectedStatements.includes(statementId.toString()) };
    });

  const createPayload = () => {
    const { statements, personalData } = values;

    return {
      clientData: {
        personalData: {
          ...personalData,
          phone: personalData.phone.startsWith('48')
            ? personalData.phone.slice(2, personalData.phone.length)
            : personalData.phone,
        },
        statements: createStatementsPayload(statements),
      },
      aClientId: aclientId,
    };
  };

  const getConfirmation = () => {
    setIsRefetch(true);
    setFetching?.(true);
    const payload = createPayload();

    sendConfirmation({
      agentId: agentId,
      clientId: values.personalData.id,
      verificationType: communicationType?.key.toString().split('_')[1] as 'SMS' | 'EMAIL',
      confirmationType:
        communicationType?.key.toString().split('_')[0] === 'CODE' ? 'CODE' : 'LINK',
      payload,
    });
  };

  const handleValidateCode = (code: string) => {
    const payload = createPayload();
    validationMutate({
      agentId: agentId,
      clientId: values.personalData.id,
      code: code,
      payload,
    });
  };

  const isAllRequiredStatements = () => {
    const requiredStatements = statements.filter(({ required }) => !!required);

    return requiredStatements.every(({ statementId }) =>
      values.statements.find((item) => parseInt(item) === statementId)
    );
  };

  return (
    <div
      className={clsx({ [styles.disabled]: !isValid || !isAllRequiredStatements() }, 'space-y-8')}>
      {isError && (
        <Alert type="error" className="mb-4">
          Wystąpił błąd przy próbie potwierdzenia
        </Alert>
      )}
      <div className="flex items-center space-x-4">
        <Select
          selected={communicationType}
          options={COMMUNICATION_TYPES}
          setSelected={(value: Option) => setCommunicationType(value)}
        />
        <div className="flex flex-col gap-2">
          <Button size="sm" onPress={() => communicationType && isValid && getConfirmation()}>
            {isRefetch ? 'Wyślij ponownie' : 'Wyślij'}
            <Icon name="send" className="ml-2 text-md" />
          </Button>
        </div>
      </div>

      {communicationType?.key.toString().split('_')[0] === 'CODE' && (
        <div>
          {isValidateCodeError && (
            <Alert type="error" className="mb-4">
              Podany kod jest błędny
            </Alert>
          )}

          <InputField
            name="verificationCode"
            label="Wpisz kod"
            maxLength={6}
            validationState={error && touched ? 'invalid' : 'valid'}
            isRequired
          />
        </div>
      )}
    </div>
  );
};

export default VerificationCode;
