import { Tab } from '@headlessui/react';

import ClientNavigation from 'components/ClientNavigation';
import PageContent from 'components/PageContent';
import { useAssessmentStore } from 'hooks/useStore';

import ClientForm from './components/ClientForm';

const CustomerData = () => {
  const clients = useAssessmentStore((state) => state.assessment.clients || []);

  return (
    <PageContent title="Twoje dane">
      <ClientNavigation clients={clients}>
        {clients.map((client) => (
          <Tab.Panel key={client.clientId}>
            <ClientForm client={client} />
          </Tab.Panel>
        ))}
      </ClientNavigation>
    </PageContent>
  );
};

export default CustomerData;
