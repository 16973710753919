import { SummaryClient } from 'types/summary';

import Header from '../components/Header';
import SectionTitle from '../components/SectionTitle';

const style = {
  page: {
    backgroundColor: '#FFFFFF',
    color: '#19103B',
    height: '100%',
    fontFamily: 'Sora',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'initial',
  },
  content: {
    main: { width: '70%', margin: 'auto' },
  },
};

type Props = {
  clients: SummaryClient[];
};

const Recommendation = ({ clients }: Props) => {
  return (
    <div style={{ ...style.page }}>
      <div style={style.content.main}>
        {clients.length > 0 &&
          clients.map((client) => (
            <>
              {client?.recommendations.length > 0 && (
                <>
                  <Header text="Podsumowanie">
                    <SectionTitle
                      text={`Rekomendowane produkty ${
                        client?.fullName.length !== 0 ? client.fullName : ''
                      }`}
                    />
                  </Header>
                  <div style={{ pageBreakAfter: 'always' }}>
                    {client.recommendations
                      .sort((a, b) => a.orderNo - b.orderNo)
                      .map((form) => (
                        <div style={{ marginBottom: '50px' }}>
                          <h2>{form.formName}</h2>
                          {form &&
                            form.products
                              .sort((a, b) => a.orderNo - b.orderNo)
                              .map(({ name }) => (
                                <div style={{ marginBottom: '30px', marginLeft: '50px' }}>
                                  <div>
                                    <span style={{ fontWeight: 600 }}>{name}</span>
                                  </div>
                                </div>
                              ))}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default Recommendation;
