import { identity, pickBy } from 'lodash';

import { Agent, AgentPayload } from 'types/agent';
import { AssessmentDto } from 'types/assessment';
import http from 'utils/axios';

export const getAgent = async (agentId: string) => {
  const { data } = await http.get<Agent>(`/agents/${agentId}`);

  return data;
};

export const updateAgent = ({ agentId, payload }: AgentPayload) =>
  http.put(`/agents/${agentId}`, payload);

export const getAgentAssessments = async (id: number, clientId?: number) => {
  const params = pickBy({ clientId }, identity);
  const { data } = await http.get<AssessmentDto[]>(`/agents/${id}/assessments`, { params });

  return data;
};

export const getPdfAgentPreview = async (agentId: number) => {
  const { data } = await http.get(`/agents/${agentId}/preview`, { responseType: 'blob' });
  return data;
};
