import { InputField, Modal, Option, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import ModalActions from '../../../components/ModalActions';
import { PRODUCTS } from '../../../constants/queries/products';
import { requiredFieldMessage } from '../../../constants/requiredFieldMessage';
import { getDocumentsIda } from '../../../services/documents';
import { addProduct, editProduct } from '../../../services/products';
import { DocumentsIda } from '../../../types/documents';

const validationSchema = yup.object().shape({
  idaProductId: yup.number().required(requiredFieldMessage),
  productName: yup.string().required(requiredFieldMessage),
  formCode: yup.string().required(requiredFieldMessage),
});

type FormValues = yup.InferType<typeof validationSchema>;

export enum ProductFormModalType {
  ADD = 'add',
  EDIT = 'edit',
}

type Props = {
  modalType: ProductFormModalType;
  refetchProducts: () => void;
};

function ProductFormModal({ modalType, refetchProducts }: Props) {
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const {
    data: productsIda,
    isFetching: productsIdaIsLoading,
    isError: productsIdaIsError,
    error: productsIdaError,
  } = useQuery<DocumentsIda[], AxiosError, Option[]>(
    [PRODUCTS.PRODUCT_IDA],
    () => getDocumentsIda(),
    {
      select: (data: DocumentsIda[]): Option[] => {
        return data.map((document: DocumentsIda) => ({
          value: document.name,
          key: document.productId,
        }));
      },
    }
  );

  const {
    mutate: addMutate,
    isLoading: addIsLoading,
    isError: addIsError,
    error: addError,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    { idaProductId: number; productName: string; formCode: string }
  >(
    ({ idaProductId, productName, formCode }) => {
      return addProduct(idaProductId, productName, formCode);
    },
    {
      onSuccess: () => {
        navigate(-1);
        refetchProducts();
      },
    }
  );

  const {
    mutate: editMutate,
    isLoading: editIsLoading,
    isError: editIsError,
    error: editError,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    { idaProductId: number; productName: string; formCode: string }
  >(
    ({ idaProductId, productName, formCode }) => {
      return editProduct(idaProductId, state?.productId, productName, formCode);
    },
    {
      onSuccess: () => {
        navigate(-1);
        refetchProducts();
      },
    }
  );

  function onSubmit(values: FormValues) {
    const { idaProductId, productName, formCode } = values;

    if (modalType === ProductFormModalType.ADD) {
      addMutate({
        idaProductId,
        productName,
        formCode,
      });
    }

    if (modalType === ProductFormModalType.EDIT) {
      editMutate({
        idaProductId,
        productName,
        formCode,
      });
    }
  }

  return (
    <Modal title={`${modalType === ProductFormModalType.ADD ? 'Dodaj' : 'Edytuj'} produkt`} visible>
      <Formik
        initialValues={
          {
            idaProductId: modalType === ProductFormModalType.ADD ? undefined : state?.idaProductId,
            productName: modalType === ProductFormModalType.ADD ? undefined : state?.productName,
            formCode: modalType === ProductFormModalType.ADD ? undefined : state?.formCode,
          } as unknown as FormValues
        }
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ errors, dirty, isValid }) => (
          <Form className="space-y-8">
            <InputField label="Nazwa produktu" name="productName" />
            <InputField
              label="Kod produktu"
              name="formCode"
              isDisabled={modalType === ProductFormModalType.EDIT}
            />
            <SelectField options={productsIda || []} label="Przypisanie IDA" name="idaProductId" />

            <ModalActions
              className="mt-8"
              isLoading={addIsLoading || editIsLoading || productsIdaIsLoading}
              isConfirmDisabled={!!Object.values(errors).length && !dirty && !isValid}
              onCancel={() => navigate(-1)}
            />

            {addIsError && <ErrorMessages error={addError} />}
            {editIsError && <ErrorMessages error={editError} />}
            {productsIdaIsError && <ErrorMessages error={productsIdaError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ProductFormModal;
