import { Row } from 'react-table';

import Table from 'components/Table/table';
import { AttachmentDataList } from 'types/documents';

import { columns } from './columns';

type Props = {
  attachmentDataList: AttachmentDataList[];
  isProductIda?: boolean;
  productId?: number;
  setSelectedRows?: (selectedRows: any[], rowKey?: number) => void;
  onDeleteDocument: (documentId: number) => void;
};

const DocumentsTable = ({
  attachmentDataList,
  isProductIda = false,
  productId,
  setSelectedRows,
  onDeleteDocument,
}: Props) => {
  return (
    <Table
      data={attachmentDataList}
      columns={columns(onDeleteDocument)}
      isSelectable={!isProductIda}
      onSelectionChange={setSelectedRows}
      rowKey={productId}
      getRowId={(row: Row) => row.id}
    />
  );
};

export default DocumentsTable;
