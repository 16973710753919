import { Text } from '@profitowi/component-library';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import ModalActions from 'components/ModalActions';

type CalculationSumProps = {
  setSum: (sum: number) => void;
  setModalOpen: (value: boolean) => void;
  code: string;
};

const CalculationSum = ({ setSum, setModalOpen, code }: CalculationSumProps) => {
  const { values: originalValues } = useFormikContext();
  const workableValues = originalValues as { [key: string]: number };
  const isMonthCalc = code === 'ACCIDENT_ACCIDENT_3_b_2' || code === 'ACCIDENT_ACCIDENT_3_a_2';
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (isMonthCalc) {
      const value = Object.keys(workableValues)
        .map((key) => (!!workableValues[key] ? workableValues[key] : 1))
        .reduce((calculatedValues, currentValue) => calculatedValues * currentValue, 1);

      setTotal(value);
    } else {
      const value = Object.keys(workableValues).reduce((acc, curr) => {
        if (!workableValues[curr]) return acc;
        return acc + workableValues[curr];
      }, 0);

      setTotal(value);
    }
  }, [originalValues]);

  return (
    <>
      <div className="flex flex-row flex-nowrap">
        <div className="my-4 mx-6 text-right font-bold grow flex justify-between">
          <Text weight="bold">{isMonthCalc ? 'Łączna wartość dochodów do pokrycia' : 'Suma:'}</Text>
          <Text size="md" weight="bold">
            {total} zł
          </Text>
        </div>
      </div>
      <ModalActions
        onCancel={() => setModalOpen(false)}
        onConfirm={() => {
          setSum(total);
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default CalculationSum;
