import { Avatar, Text } from '@profitowi/component-library';
import { useMemo } from 'react';

import { useAgentStore } from 'hooks/useAgentStore';

const AgentAvatar = () => {
  const { agentCode, firstName, lastName, photoBase64 } = useAgentStore((state) => state.agent);

  const initials = useMemo(
    () =>
      [firstName?.[0], lastName?.[0]]
        .filter(Boolean)
        .map((s) => s.toUpperCase())
        .join(''),
    [firstName, lastName]
  );
  const name = useMemo(
    () => [firstName, lastName].filter(Boolean).join(' '),
    [firstName, lastName]
  );

  return (
    <div className="flex items-center space-x-4">
      <Avatar circular className="bg-secondary">
        {photoBase64 ? (
          <img
            className="w-full h-full rounded-full"
            src={`data:image/png;base64, ${photoBase64}`}
            alt=""
          />
        ) : (
          initials
        )}
      </Avatar>

      <div>
        <Text className="text-white" weight="bold" size="base">
          {name}
        </Text>
        <Text className="text-secondary" size="xs" weight="normal">
          {agentCode}
        </Text>
      </div>
    </div>
  );
};

export default AgentAvatar;
