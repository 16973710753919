import { isValidNip, isValidPesel, isValidRegon } from 'polish-identification-numbers';
import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    isDigitsOnly(): this;
    isNaturalNumber(): this;
    isIntegerNumber(): this;
    isFloatPrecision2(): this;
    isValidPhoneNumber(): this;
    isValidEmail(): this;
    isFormattedDate(): this;
    isValidPesel(): this;
    isValidNip(): this;
    isValidRegon(): this;
    isValidAlphabeticSpecialSigns(): this;
    isValidAlphabeticNumberSpecialSigns(): this;
  }
}

const MB = 1024 * 1024;
const FILE_SIZE = 20 * MB;
export const SUPPORTED_FORMATS = ['.jpg', '.jpeg', '.png', '.tiff', '.pdf'];

yup.addMethod<yup.AnySchema>(
  yup.mixed,
  'fileSizeAndFormat',
  function (size = FILE_SIZE, formats: string[] = SUPPORTED_FORMATS) {
    return this.test(
      'fileSize',
      `Łączny rozmiar dodanych plików przekracza dopuszczalny limit ${size / MB} MB`,
      (value) => {
        return value && value.size <= FILE_SIZE;
      }
    ).test(
      'fileFormat',
      `Akceptowane formaty to ${formats.reduce((acc, current, index) => {
        return `${acc}${index == 0 ? '' : ', '}${current}`;
      }, '')}`,
      (value) => value && formats.includes(value.type)
    );
  }
);

yup.addMethod<yup.StringSchema>(yup.string, 'isDigitsOnly', function () {
  return this.matches(/^(\s*|\d+)$/, 'Podana wartość powinna składać się z samych cyfr');
});

yup.addMethod<yup.StringSchema>(yup.string, 'isNaturalNumber', function () {
  return this.matches(/^([1-9]\d*)|(0)$/, 'Podana wartość powinna być liczbą naturalną');
});

yup.addMethod<yup.StringSchema>(yup.string, 'isIntegerNumber', function () {
  return this.matches(/^([-]?[1-9]\d*)|(0)$/, 'Podana wartość powinna być liczbą całkowitą');
});

yup.addMethod<yup.StringSchema>(yup.string, 'isFloatPrecision2', function () {
  return this.matches(
    /^([-]?([1-9]\d*)|(0))*([.,]\d{1,2})?|0$/,
    'Podana wartość powinna być liczbą stałoprzecinkową (2 miejsca po przecinku)'
  );
});

yup.addMethod(yup.string, 'isValidPhoneNumber', function () {
  return this.matches(/^((?:48\d{9})|((?!48\d)\d{9,16}))$/, 'Nieprawidłowy numer telefonu');
});

yup.addMethod(yup.string, 'isValidEmail', function () {
  return this.matches(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    'Nieprawidłowy adres email'
  );
});

yup.addMethod(yup.string, 'isFormattedDate', function () {
  return this.matches(
    /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-\d{4}$/,
    'Nieprawidłowa data (format: DD-MM-YYYY)'
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'isValidPesel', function () {
  return this.isDigitsOnly().test({
    message: 'Nieprawidłowy numer PESEL',
    name: 'isValidPesel',
    test: (value = '') => value === '' || isValidPesel(value),
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'isValidRegon', function () {
  return this.isDigitsOnly().test({
    message: 'Nieprawidłowy numer REGON',
    name: 'isValidRegon',
    test: (value = '') => value === '' || isValidRegon(value),
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'isValidNip', function () {
  return this.isDigitsOnly()
    .length(10, 'Pole powinno zawierać tylko 10 cyfr')
    .test({
      message: 'Nieprawidłowy numer NIP',
      name: 'isValidNip',
      test: (value = '') => value === '' || isValidNip(value),
    });
});

yup.addMethod<yup.StringSchema>(yup.string, 'isValidAlphabeticSpecialSigns', function () {
  return this.matches(
    /^[\p{L}!@#$&()\-`.+,/" ]*$/u,
    '​Pole powinno zawierać tylko znaki alfabetyczne i znaki specjalne'
  ).max(255, 'Przekroczono dozwoloną ilość znaków');
});

yup.addMethod<yup.StringSchema>(yup.string, 'isValidAlphabeticNumberSpecialSigns', function () {
  return this.matches(
    /^[\p{L}0-9!@#$&()\-`.+,/_" ]*$/u,
    '​Pole powinno zawierać cyfry, litery i znaki specjalne'
  )
    .max(255, 'Przekroczono dozwoloną ilość znaków')
    .default('');
});
