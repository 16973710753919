import { AFormData, AFormSubjectData, ClientsAFormsInfo } from 'types/forms';
import http from 'utils/axios';

type StrNumUndef = string | number | undefined;

export const getForms = async () => {
  const { data } = await http.get('/forms');

  return data;
};

export const getClientsAFormsInfo = async (agentId: StrNumUndef, assessmentId: StrNumUndef) => {
  const { data } = await http.get<ClientsAFormsInfo[]>(
    `/agents/${agentId}/assessments/${assessmentId}/forms`
  );
  return data;
};

export const getFormData = async (formId: StrNumUndef) => {
  const { data } = await http.get(`/forms/${formId}`);
  return data;
};

export const getAFormData = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  formId: StrNumUndef,
  aFormId: StrNumUndef
) => {
  const { data } = await http.get<AFormData>(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/forms/${formId}/aForms/${aFormId}/baseData`
  );
  return data;
};

export const getAFormSubjectData = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  formId: StrNumUndef,
  aFormId: StrNumUndef,
  aFormSubjectId: StrNumUndef
) => {
  const { data } = await http.get<AFormSubjectData>(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/forms/${formId}/aForms/${aFormId}/aFormSubjects/${aFormSubjectId}/answers`
  );
  return data;
};

export const createEmptyAForm = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  formId: StrNumUndef
) => {
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/forms/${formId}/aForms/create`
  );
};

export const createEmptyAFormSubject = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  formId: StrNumUndef,
  aFormId: StrNumUndef
) => {
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/forms/${formId}/aForms/${aFormId}/aFormSubjects/create`
  );
};

export const saveAAnswers = async (
  payload: { state: string; aAnswers: { value: string; aAnswerId: number }[] },
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  formId: StrNumUndef,
  aFormId: StrNumUndef,
  aFormSubjectId: StrNumUndef
) => {
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/forms/${formId}/aForms/${aFormId}/aFormSubjects/${aFormSubjectId}/answers`,
    payload
  );
};

export const cancelAFormForAFormSubject = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  formId: StrNumUndef,
  aFormId: StrNumUndef,
  aFormSubjectId: StrNumUndef
) => {
  return http.delete(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/forms/${formId}/aForms/${aFormId}/aFormSubjects/${aFormSubjectId}`
  );
};

export const uploadAttachment = async (
  payload: FormData,
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  formId: StrNumUndef,
  aFormId: StrNumUndef,
  aFormSubjectId: StrNumUndef,
  questionCode: StrNumUndef,
  fileName?: StrNumUndef,
  attachmentType?: StrNumUndef
) => {
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/forms/${formId}/aForms/${aFormId}/aFormSubjects/${aFormSubjectId}/attachment?questionCode=${questionCode}${
      fileName ? `&fileName=${fileName}` : ''
    }${attachmentType ? `&attachmentType=${attachmentType}` : ''}`,
    payload
  );
};

export const deleteFileById = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  formId: StrNumUndef,
  aFormId: StrNumUndef,
  aFormSubjectId: StrNumUndef,
  fileId: StrNumUndef
) => {
  return http.delete(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/forms/${formId}/aForms/${aFormId}/aFormSubjects/${aFormSubjectId}/files/${fileId}`
  );
};
