import { Icon, Sidebar, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import AlertBox from 'components/AlertBox';
import ConfirmationModal from 'components/ConfirmationModal';
import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { usePageLayout } from 'hooks/usePageLayout';
import { useAssessmentStore, useCrmClient } from 'hooks/useStore';
import { cancelAssessment, redoAssessment } from 'services/assessment';
import { AssessmentDto, StateType } from 'types/assessment';

import { Notifications } from './Notifications';
import Appointment from './components/Appointment';
import SidebarButton from './components/SidebarButton';

type AssessmentPayload = {
  agentId: number;
  assessmentId: number;
};

const ToolSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSummaryPath = location.pathname.startsWith('/summary');

  const agentId = useAgentStore((state) => state.agent.agentId);
  const { assessment, resetAssessment, setAssessment } = useAssessmentStore();
  const { assessmentId = NaN, state: assessmentState } = assessment;
  const { setCrmClientId } = useCrmClient();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const { displayedNotCompleted } = usePageLayout();

  const { addNotification } = useNotificationStore(({ addNotification }) => ({
    addNotification,
  }));

  const { mutate: cancel } = useMutation<AxiosResponse, AxiosError, AssessmentPayload>(
    ({ assessmentId, agentId }) => cancelAssessment(assessmentId, agentId),
    {
      onSuccess: () => {
        setCrmClientId('');

        resetAssessment();
        navigate('/');
      },
    }
  );

  const { mutate: redo } = useMutation<AssessmentDto, AxiosError, AssessmentPayload>(
    ({ assessmentId, agentId }) => redoAssessment(assessmentId, agentId),
    {
      onSuccess: (assessment) => {
        setAssessment(assessment);
        navigate('/aboutProfitowi');
      },
    }
  );

  const isAssessmentFinished = assessmentState === StateType.FINISHED;

  return (
    <Sidebar orientation="right">
      <div>
        <Text size="lg" weight="bold" className="mb-8">
          <Icon className="mr-3" name="grid" />
          <span>Menu narzędziowe</span>
        </Text>

        {isSummaryPath ? (
          <Appointment assessmentId={assessmentId} agentId={agentId} />
        ) : (
          <SidebarButton onPress={() => navigate('/summary')}>
            <Icon name="send" custom={false} className="mr-3 w-4" />
            <Text>Wyślij analizę do klienta</Text>
          </SidebarButton>
        )}

        <nav>
          <SidebarButton
            isDisabled={isAssessmentFinished}
            onPress={() => {
              addNotification({
                id: 'postArea-autosave',
                title: 'Analiza została zapisana',
                type: 'success',
              });
            }}>
            <Icon name="check" className="mr-3 w-4" />
            <Text>Zapisz analizę</Text>
          </SidebarButton>

          <SidebarButton
            isDisabled={isAssessmentFinished}
            onPress={() => setCancelModalVisible(true)}>
            <Icon name="x-octagon" className="mr-3 w-4" />
            <Text>Anuluj analizę</Text>
          </SidebarButton>

          {isAssessmentFinished && (
            <SidebarButton
              onPress={() =>
                redo({
                  agentId,
                  assessmentId,
                })
              }>
              <Icon name="arrow-repeat" className="mr-3 w-4" />
              <Text>Wznów analizę</Text>
            </SidebarButton>
          )}
        </nav>
      </div>

      <div>
        <Notifications />
        {displayedNotCompleted && (
          <AlertBox
            type="error"
            header="Wypełnij wymagane pola"
            message=""
            showButton={false}
            iconClass="text-2xl"
          />
        )}
      </div>

      <ConfirmationModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        confirmText="Nie"
        action={() =>
          cancel({
            agentId,
            assessmentId,
          })
        }
        message="Czy na pewno chcesz anulować analizę?"
      />
    </Sidebar>
  );
};

export default ToolSidebar;
