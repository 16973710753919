import { SummaryData } from 'types/form';
import http from 'utils/axios';

export const getSummary = async (assessmentId: number, aClientId: number, agentId: number) => {
  const { data } = await http.get<SummaryData>(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/summary`
  );

  return data;
};

export const finalize = async (payload: any, assessmentId: number, agentId: number) => {
  const { sendSummaryToAgent = false, subject, content } = payload;
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/finalize?sendSummaryToAgent=${sendSummaryToAgent}`,
    {
        subject,
        content,
    }
  );
};

export const postMeetingDate = async (assessmentId: number, agentId: number, date: string) => {
  const { data } = await http.patch(
    `/agents/${agentId}/assessments/${assessmentId}/meeting?date=${date}`
  );
  return data;
};

export const deleteMeetingDate = async (assessmentId: number, agentId: number) => {
  const { data } = await http.delete(`/agents/${agentId}/assessments/${assessmentId}/meeting`);
  return data;
};

export const getMeetingDate = async (assessmentId: number, agentId: number) => {
  const { data } = await http.get(`/agents/${agentId}/assessments/${assessmentId}/meeting`);

  return data;
};

export const getSummaryPdf = async (agentId: number, assessmentId: number) => {
  const { data } = await http.get<string>(
    `/agents/${agentId}/assessments/${assessmentId}/summary`,
    { responseType: 'blob' }
  );

  return data;
};

export const createSummaryPdf = async (assessmentId: number, agentId: number, payload: string) =>
  http.post(`/agents/${agentId}/assessments/${assessmentId}/summary`, payload, {
    headers: {
      'content-type': 'text/plain',
    },
  });

export const getSummaryDataPdf = async (assessmentId: number, agentId: number) => {
  const { data } = await http.get(`/agents/${agentId}/assessments/${assessmentId}/summary/data`);

  return data;
};
