import { Alert, Loader, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import FORMS from 'components/FormIcons/formIcons';
import { useAgentStore } from 'hooks/useAgentStore';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import { getClientsAFormsInfo, getForms } from 'services/forms';
import { ASSESSMENT_TYPE, BasicClientData } from 'types/assessment';
import { AREA_TYPE, ClientsAFormsInfo, FormData } from 'types/forms';

import Tile from './Tile';
import { shownAreas } from './lookups';

type FormBasicInfo = Pick<FormData, 'formId' | 'areaType' | 'code' | 'name' | 'version'> & {
  orderNo: number;
};
type FormsResponse = Record<AREA_TYPE, Array<FormBasicInfo>>;

type FormTilesProps = {
  forms: FormsResponse;
  clientsAFormsInfo: ClientsAFormsInfo[];
  client: BasicClientData;
};

function FormTiles({ forms, clientsAFormsInfo, client }: FormTilesProps) {
  const formList = useMemo(
    () =>
      Object.entries(forms)
        .filter(([areaType]) => shownAreas[client.clientType].includes(areaType as AREA_TYPE))
        .flatMap(([, areaForms]) => areaForms)
        .sort((a, b) => a.orderNo - b.orderNo),
    [client, forms]
  );

  return (
    <>
      {formList.map((formBasicInfo) => {
        const { formId, name, code } = formBasicInfo;
        const { icon, custom, bgColor } = FORMS[code as keyof typeof FORMS];

        const { expired, state } = clientsAFormsInfo.find((e) => e.formCode === code) ?? {
          expired: false,
          state: '',
        };

        return (
          <Tile
            backgroundColor={bgColor}
            custom={custom}
            expired={expired}
            icon={icon}
            key={formId}
            state={state}
            text={name}
            colspan={[AREA_TYPE.SHORTEN_COMPANY, AREA_TYPE.SHORTEN_PRIVATE].includes(
              code as keyof typeof FORMS as AREA_TYPE
            )}
            to={`/yourNeeds/form/${client.aclientId}/${formId}`}
          />
        );
      })}
    </>
  );
}

type Props = {
  client: BasicClientData;
};

const ClientAreasForms = ({ client }: Props) => {
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId);
  const agentId = useAgentStore((state) => state.agent.agentId);
  const { validateSteps } = useStepValidation();

  const forms = useQuery<FormsResponse, AxiosError>('forms', () => getForms());

  const clientsAForms = useQuery<ClientsAFormsInfo[], AxiosError>(
    ['clientsAForms', client.aclientId, client.aclientId, client.clientType],
    () => getClientsAFormsInfo(agentId, assessmentId),
    {
      onSuccess: () => {
        validateSteps(ASSESSMENT_TYPE.FORM);
      },
    }
  );

  const isError = forms.isError || clientsAForms.isError;
  const isLoading = forms.isLoading || clientsAForms.isLoading;
  const isSuccess = forms.isSuccess && clientsAForms.isSuccess;

  return (
    <div className="space-y-6">
      <Text size="lg" weight="bold">
        Formularze dla obszarów zainteresowań
      </Text>

      {isError && (
        <Alert type="error">{forms.error?.message || clientsAForms.error?.message}</Alert>
      )}
      {isLoading && <Loader className="h-12 w-12" />}
      {isSuccess && (
        <div className="grid 2xl:grid-cols-2 gap-6">
          <FormTiles forms={forms.data} clientsAFormsInfo={clientsAForms.data} client={client} />
        </div>
      )}
    </div>
  );
};

export default ClientAreasForms;
