import { Button, InputField, Option, SelectField } from '@profitowi/component-library';
import { ArrayHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getProductAreaQuestions, getProductAreas } from '../../../services/products';
import { ConditionType, ConditionTypeEnum } from '../../../types/products';
import { AddRecommendationFormValues } from './AddRecommendation';

const selectableOperators = [
  {
    key: ConditionTypeEnum.GREATER,
    value: ConditionType.GREATER,
  },
  {
    key: ConditionTypeEnum.NON_EMPTY,
    value: ConditionType.NON_EMPTY,
  },
  {
    key: ConditionTypeEnum.EQUAL,
    value: ConditionType.EQUAL,
  },
  {
    key: ConditionTypeEnum.NOT_EQUAL,
    value: ConditionType.NOT_EQUAL,
  },
];

function AddRecommendationForm({ index, helpers }: { index: number; helpers: ArrayHelpers }) {
  const [questionCode, setQuestionCode] = useState<Option[]>([]);
  const [formCode, setFormCode] = useState<Option[]>([]);
  const [isSelectable, setIsSelectable] = useState<boolean>(false);
  const [questionAnswers, setQuestionAnswers] = useState<Option[]>([]);
  const { values }: { values: AddRecommendationFormValues } = useFormikContext();
  const { recommendations = [] } = values;

  const { data } = useQuery([], () => getProductAreas(), {
    onSuccess: (data) => {
      const tempAreas = data.map((value) => ({
        value: value.name,
        key: value.code,
      }));
      setFormCode(tempAreas);
    },
  });

  const { data: questionsData } = useQuery(
    [recommendations[index].formCode],
    () => getProductAreaQuestions(recommendations[index].formCode),
    {
      onSuccess: (questions) => {
        const tempAreaQuestions = questions.map((value) => ({
          value: value.content,
          key: value.code,
        }));
        setQuestionCode(tempAreaQuestions);
      },
    }
  );

  useEffect(() => {
    if (!questionsData || !questionsData.length) return;

    recommendations[index] = {
      ...recommendations[index],
      conditionType: '',
      conditionValue: '',
    } as any;

    const tempAreaQuestionAnswers = questionsData
      .find((question) => question.code === recommendations[index].questionCode)
      ?.answers.map((question: any) => ({
        value: question.value,
        key: question.value,
      }));

    setIsSelectable(() => {
      if (!recommendations[index].questionCode?.length) {
        return false;
      } else return true;
    });

    setQuestionAnswers(tempAreaQuestionAnswers || []);
  }, [recommendations[index].questionCode]);

  useEffect(() => {
    if (!!recommendations[index].conditionValue) {
    }
    recommendations[index] = {
      ...recommendations[index],
      questionCode: '',
      conditionType: '',
      conditionValue: '',
    } as any;
  }, [recommendations[index].formCode]);

  useEffect(() => {
    if (!!recommendations[index].conditionValue) {
    }
    recommendations[index] = {
      ...recommendations[index],
      conditionValue: '',
    } as any;
  }, [recommendations[index].conditionType]);

  return (
    <div className="flex flex-col my-2" key={'recommendation_' + index}>
      <div className="flex flex-col gap-4">
        <SelectField
          options={formCode}
          label="Obszar"
          name={`recommendations[${index}].formCode`}
        />
        <SelectField
          options={questionCode}
          label="Pytanie"
          name={`recommendations[${index}].questionCode`}
        />
        <SelectField
          options={isSelectable ? selectableOperators : []}
          label="Operator"
          name={`recommendations[${index}].conditionType`}
        />
        {questionAnswers.length ? (
          <SelectField
            options={questionAnswers}
            label="Wartość"
            name={`recommendations[${index}].conditionValue`}
          />
        ) : (
          <InputField
            label="Wartość tekstowa"
            name={`recommendations[${index}].conditionValue`}
            isDisabled={
              !recommendations[index].formCode?.length ||
              !recommendations[index].questionCode?.length ||
              !recommendations[index].conditionType?.length ||
              recommendations[index].conditionType === ConditionTypeEnum.NON_EMPTY
            }
          />
        )}

        <div className="flex justify-end">
          <Button
            onPress={(e) => (helpers as any).remove(index)}
            size="sm"
            isDisabled={recommendations?.length < 2}>
            Usuń
          </Button>
        </div>

        <div className="border-2 rounded bg-slate-400 w-full mb-4"></div>
      </div>
    </div>
  );
}

export default AddRecommendationForm;
