import { Alert, Loader, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { useAgentStore } from 'hooks/useAgentStore';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import { getSurveysAndStates } from 'services/surveys';
import { ASSESSMENT_TYPE, BasicClientData } from 'types/assessment';
import { SurveysStates } from 'types/surveys';

import SurveyTile from './SurveyTile';

function surveyTiles(
  surveysStates: SurveysStates | undefined,
  client: BasicClientData
): JSX.Element[] {
  if (!surveysStates) return [];
  const { surveys, surveyStates } = surveysStates;
  return surveys.reduce((acc: JSX.Element[], survey) => {
    const { expired, state } = surveyStates.find((e) => e.surveyCode === survey.code) ?? {
      expired: false,
      state: '',
    };
    return [...acc, <SurveyTile survey={survey} client={client} expired={expired} state={state} />];
  }, []);
}

type Props = {
  client: BasicClientData;
};

const ClientSurveys = ({ client }: Props) => {
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId);
  const agentId = useAgentStore((state) => state.agent.agentId);
  const { validateSteps } = useStepValidation();

  const { data, isLoading, isError, isSuccess, error } = useQuery<SurveysStates, AxiosError>(
    ['surveysAndStates', agentId, assessmentId],
    () => getSurveysAndStates(agentId, assessmentId),
    {
      onSuccess: () => {
        validateSteps(ASSESSMENT_TYPE.SURVEY);
      },
    }
  );

  return (
    <>
      <Text size="lg" weight="bold">
        Wybierz ankietę
      </Text>
      <div className="grid grid-cols-2 gap-10 pt-8">
        {isError && <Alert type="error">{error?.message}</Alert>}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && surveyTiles(data, client)}
      </div>
    </>
  );
};

export default ClientSurveys;
