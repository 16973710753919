import { Alert, Box, CheckBox, Text } from '@profitowi/component-library';
import { useState } from 'react';

import { useAssessmentStore } from 'hooks/useStore';
import { StateType } from 'types/assessment';

interface Props {
  text: string;
  selected?: boolean;
  onChange: (recommendationId: number, isSelected: boolean) => void;
  id: number;
}

const Tile = ({ onChange, text, selected = false, id }: Props) => {
  const [isSelected, setIsSelected] = useState(selected);
  const assessmentState = useAssessmentStore((state) => state.assessment.state);

  if (!text)
    return (
      <Alert className="mx-6 my-4" type="error">
        Brak danych
      </Alert>
    );

  return (
    <Box className="my-4 mx-4 flex grow justify-start items-center relative !py-8 !px-6">
      <CheckBox
        isDisabled={assessmentState === StateType.FINISHED}
        id={id.toString()}
        isSelected={isSelected}
        onChange={() => {
          setIsSelected(!isSelected);
          return onChange(id, !isSelected);
        }}>
        <label htmlFor={id.toString()}>
          <Text size="base" weight="bold">
            {text}
          </Text>
        </label>
      </CheckBox>
    </Box>
  );
};

export default Tile;
