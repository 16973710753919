import dots from 'assets/summaryPdfIcons/dots.png';
import logo from 'assets/summaryPdfIcons/logo.png';

import { SummaryAgent } from 'types/summary';

import { months } from '../constants/moths';

const style = {
  page: {
    background: '#FFF',
    color: '#FFF',
    height: '100%',
    width: '100%',
    fontFamily: 'Sora',
    fontStyle: 'normal',
    lineHeight: 'initial',
    overflow: 'hidden',
  },
  content: {
    height: '87%',
    width: '100%',
  },
  line: {
    display: 'inline-block',
  },
  center: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  timestamp: {
    fontSize: '19px',
    fontWeight: '600',
    lineHeight: '24px',
    color: '#00E5C9',
  },
  footerSection: {
    width: '87%',
    height: '13%',
    display: 'table',
  },
  footerRightBlock: {
    background: '#00E5C9',
    width: '10%',
    height: '13%',
    bottom: 0,
    right: 0,
  },
  sectionRightBox: {
    background: '#19103B',
    height: '100%',
    width: '10%',
    top: 0,
    right: 0,
    display: 'inline-block',
  },
  profileImageContainer: {
    width: '40%',
    height: '100%',
    display: 'inline-block',
    background: '#19103B',
  },
  section: {
    background: '#011983',
    width: '50%',
    height: '100%',
    padding: '40px 56px',
    display: 'inline-block',
  },
  header: {
    fontSize: '30px',
    fontWeight: '600',
    lineHeight: '42px',
  },
  main: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    margin: '40px 0 20px 0',
    overflow: 'hidden',
  },
  profile: {
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '22px',
    color: '#00E5C9',
    marginRight: '40px',
  },
  profileImage: {
    width: '100%',
    height: '100%',
  },
  leftDotsContainer: {
    top: 40,
    left: 5,
    overflow: 'hidden',
    width: 40,
    height: 90,
  },
  dots1: { height: 90 },
  dots2: { top: 40, right: -10, height: 90 },
};

type Props = {
  agent: SummaryAgent;
  assessmentDate: string;
};

const AgentInfo = ({ agent, assessmentDate }: Props) => {
  return (
    <section id="agent" className="sheet">
      <div style={{ ...style.page, position: 'relative' }}>
        <div style={{ ...style.content, float: 'left' }}>
          <div style={{ ...style.profileImageContainer, float: 'left' }}>
            <img
              alt=""
              style={style.profileImage}
              src={`data:image/png+xml;base64,${agent.photoBase64} `}
            />
          </div>

          <div
            style={{
              ...style.section,
              float: 'left',
              position: 'relative',
              boxSizing: 'border-box',
            }}>
            <div style={{ ...style.leftDotsContainer, position: 'absolute' }}>
              <img
                src={dots}
                alt=""
                style={{ ...style.dots1, objectFit: 'none', position: 'absolute' }}
              />
            </div>
            <img src={dots} alt="" style={{ ...style.dots2, position: 'absolute' }} />
            <img src={dots} alt="" style={{ ...style.dots2, position: 'absolute', right: -95 }} />
            <div style={style.header}>
              Profitowi to ludzie.
              <br />
              Ja jestem {agent.firstName} {agent.lastName}
            </div>
            <div style={style.main}>
              {agent.description?.split('<br>').map((line) => (
                <p>
                  {line}
                  <br />
                </p>
              ))}
            </div>
            <div>
              <a
                href={agent.knfLink}
                style={{
                  textDecoration: 'none',
                  whiteSpace: 'nowrap',
                }}>
                <span style={style.profile}>Profil na KNF</span>
              </a>
              <a
                href={agent.linkedinLink}
                style={{
                  textDecoration: 'none',
                  whiteSpace: 'nowrap',
                }}>
                <span style={style.profile}>Profil na LINKEDIN</span>
              </a>
            </div>
          </div>
          <div style={{ ...style.sectionRightBox, float: 'left' }}></div>

          <div
            style={{
              ...style.footerSection,
              float: 'left',
            }}>
            <div style={style.center}>
              <img src={logo} alt="" style={{ transform: 'scale(0.6)' }} />
            </div>
            <div style={style.center}>
              <span style={{ ...style.timestamp, float: 'right' }}>
                {assessmentDate.slice(8, 10)} {months[parseInt(assessmentDate.slice(5, 7)) - 1]}{' '}
                {assessmentDate.slice(0, 4)}
              </span>
            </div>
          </div>

          <div
            style={{
              ...style.footerRightBlock,
              position: 'absolute',
            }}></div>
        </div>
      </div>
    </section>
  );
};

export default AgentInfo;
