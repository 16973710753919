import create from 'zustand';

type Notification = {
  id: string;
  message?: string;
  title: string;
  type: 'error' | 'info' | 'success' | 'warning';
};

type NotificationState = {
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  removeNotification: (id: string) => void;
};

const useNotificationStore = create<NotificationState>((set, get) => ({
  notifications: [],
  addNotification: (notification) => {
    const { notifications } = get();
    const { id } = notification;
    const index = notifications.findIndex((notification) => notification.id === id);

    if (index === -1) {
      set(() => ({ notifications: [notification, ...notifications] }));
    } else {
      set(() => ({
        notifications: [
          notification,
          ...notifications.filter((notification) => notification.id !== id),
        ],
      }));
    }

    if (notification.type !== 'error') {
      setTimeout(() => {
        set(() => ({
          notifications: notifications.filter((notification) => notification.id !== id),
        }));
      }, 10000);
    }
  },
  removeNotification: (id) =>
    set((state) => ({
      notifications: state.notifications.filter((notification) => notification.id !== id),
    })),
}));

export default useNotificationStore;
