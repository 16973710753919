import { Box, Loader } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import FormBody from 'components/AssessmentForm/FormBody';
import { aanswersToInitialValues, valuesToValidAAnswers } from 'components/AssessmentForm/helpers';
import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { usePageLayout } from 'hooks/usePageLayout';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import {
  cancelASurveyForASurveySubject,
  getASurveySubjectData,
  saveAAnswers,
} from 'services/surveys';
import { ASSESSMENT_TYPE } from 'types/assessment';
import { FileUploadContext } from 'types/forms';
import { ASurveySubjectData } from 'types/surveys';
import { createValidationSchema } from 'utils/yupHelpers';

type ASurveyProps = {
  aClientId?: number;
  asurveyId: any;
  asurveySubjectId: any;
  surveyData: any;
  cancelEffect: () => void;
  subjectCount: number;
};

const ASurvey = ({
  aClientId,
  asurveyId,
  asurveySubjectId,
  cancelEffect,
  surveyData,
  subjectCount,
}: ASurveyProps) => {
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const { setDisplayedNotCompleted } = usePageLayout();

  const agentId = useAgentStore((state) => state.agent.agentId);
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId);
  const { validateSteps } = useStepValidation();
  const { addNotification, removeNotification } = useNotificationStore();

  const validationSchema = useMemo(
    () => createValidationSchema(surveyData.data.questions),
    [surveyData.data.questions]
  );

  const aSurveySubjectData = useQuery<ASurveySubjectData, AxiosError>(
    ['aSurveySubjectData', agentId, assessmentId, aClientId, surveyId, asurveyId, asurveySubjectId],
    () =>
      getASurveySubjectData(agentId, assessmentId, aClientId, surveyId, asurveyId, asurveySubjectId)
  );

  const save = useMutation<AxiosResponse, AxiosError, any>(
    (payload) =>
      saveAAnswers(
        payload,
        agentId,
        assessmentId,
        aClientId,
        surveyId,
        asurveyId,
        asurveySubjectId
      ),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Wystąpił błąd podczas zapisywania danych',
          type: 'error',
        });
      },
      onSuccess: () => {
        validateSteps(ASSESSMENT_TYPE.SURVEY);
        addNotification({
          id: 'postArea-autosave',
          title: 'Automatyczne zapisywanie',
          type: 'info',
        });
        removeNotification('postArea-error');
      },
    }
  );

  const cancel = useMutation<AxiosResponse, AxiosError>(
    () =>
      cancelASurveyForASurveySubject(
        agentId,
        assessmentId,
        aClientId,
        surveyId,
        asurveyId,
        asurveySubjectId
      ),
    {
      onSuccess: () => {
        if (subjectCount <= 1) navigate('/surveys');
        else cancelEffect();
      },
    }
  );

  const customHandleSubmit = (formikValues: any, errors?: any, questions?: any) => {
    const values = formikValues as { [char: string]: any };
    const payload = {
      state: isEmpty(errors) ? 'COMPLETED' : 'NOT_COMPLETED',
      aAnswers: valuesToValidAAnswers(values, errors, questions, aSurveySubjectData.data?.aanswers),
    };
    save.mutate(payload);
    setDisplayedNotCompleted(!isEmpty(errors));
  };

  const onCancel = () => {
    cancel.mutate();
  };

  if (aSurveySubjectData.isLoading || aSurveySubjectData.isRefetching)
    return (
      <Box className="!px-5 !py-12">
        <Loader className="h-12 w-12" />
      </Box>
    );

  const context: FileUploadContext = {
    agentId: agentId,
    assessmentId: assessmentId,
    aClientId: aClientId,
    id: surveyId,
    aId: asurveyId,
    aSubjectId: asurveySubjectId,
  };

  return (
    <Box className="!px-5 !py-12">
      <Formik
        initialValues={aanswersToInitialValues(
          surveyData.data?.questions ?? [],
          aSurveySubjectData.data?.aanswers
        )}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        validateOnMount>
        <FormBody
          hackySubmit={customHandleSubmit}
          questions={surveyData.data.questions}
          onCancel={onCancel}
          context={context}
        />
      </Formik>
    </Box>
  );
};

export default ASurvey;
