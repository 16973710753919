import qs from 'qs';

import { Documents, DocumentsIda } from 'types/documents';
import http from 'utils/axios';

export const getDocuments = async (nameSearch?: string, shortcutSearch?: string) => {
  const { data } = await http.get<Documents[]>(`/products`, {
    params: {
      nameSearch,
      shortcutSearch,
    },
  });

  return data;
};

export const getDocumentsIda = async () => {
  const { data } = await http.get<DocumentsIda[]>(`/products/ida`);

  return data;
};

export const addDocumentsAttachment = async (file: string, productId: number, fileName: string) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { fileName },
  };

  const { data } = await http.post(`/products/${productId}/productAttachment/import`, file, config);

  return data;
};

export const addDocumentsIda = async (file: string, fileName: string, productId: number) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { fileName },
  };

  const { data } = await http.post(
    `/products/ida/${productId}/productAttachment/import`,
    file,
    config
  );

  return data;
};

export const deleteDocumentAttachment = async (fileId?: number) => {
  const { data } = await http.delete<string>(`/products/${fileId}`);

  return data;
};

export const deleteDocumentAttachments = async (fileIds?: number[] | number) => {
  const { data } = await http.delete(`/products`, {
    params: { fileId: fileIds },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data;
};
