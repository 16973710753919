import { Alert } from '@profitowi/component-library';
import { AxiosError } from 'axios';

type Props = {
  error?: AxiosError | null | any;
};

const ErrorMessages = ({ error }: Props) => (
  <>
    {error?.response?.data?.errors?.map((error: string) => (
      <Alert className="my-2" type="error">
        {error}
      </Alert>
    )) ?? (
      <Alert className="my-2" type="error">
        {error?.message}
      </Alert>
    )}
  </>
);

export default ErrorMessages;
