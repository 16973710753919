import { Button, Icon } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { Documents } from 'types/documents';

export const columns: Column<Documents>[] = [
  {
    id: 'expander',
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, toggleAllRowsExpanded }) => {
      return (
        <span
          {...getToggleAllRowsExpandedProps()}
          onClick={() => {
            if (isAllRowsExpanded) {
              toggleAllRowsExpanded(false);
            } else {
              toggleAllRowsExpanded(true);
            }
          }}>
          {isAllRowsExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      );
    },
    Cell: ({ row }: Cell<Documents>) =>
      row.original.attachmentDataList?.filter((item) => item !== null).length ? (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      ) : null,
  },
  {
    Header: 'Nazwa produktu',
    accessor: 'name',
    sortType: 'string',
  },
  {
    Header: 'Skrót',
    accessor: 'shortcut',
    sortType: 'string',
  },
  {
    id: 'operations',
    Cell: ({
      row: {
        original: { productId },
      },
    }: Cell<Documents>) => {
      const navigate = useNavigate();

      return (
        <div className="flex space-x-6 justify-end whitespace-nowrap">
          <Button variant="light" onPress={() => navigate('add', { state: { productId } })}>
            Dodaj dokument
          </Button>
        </div>
      );
    },
  },
];
