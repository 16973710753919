import { Button, Icon, Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import ModalActions from '../../../components/ModalActions';
import { requiredFieldMessage } from '../../../constants/requiredFieldMessage';
import { addRecommendations } from '../../../services/products';
import AddRecommendationForm from './AddRecommendationForm';

const validationSchema = yup.object().shape({
  recommendations: yup.array().of(
    yup.object().shape({
      formCode: yup.string().required(requiredFieldMessage),
      questionCode: yup.string().required(requiredFieldMessage),
      conditionType: yup.string().required(requiredFieldMessage),
      conditionValue: yup.string(),
    })
  ),
});
export type AddRecommendationFormValues = yup.InferType<typeof validationSchema>;

type Props = {
  refetchProducts: () => void;
};

function AddRecommendation({ refetchProducts }: Props) {
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const {
    mutate: addRecommendationMutate,
    isLoading: addIsLoading,
    isError: addIsError,
    error: addError,
  } = useMutation<
    AxiosResponse<any>,
    AxiosError,
    {
      formCode: string;
      questionCode: string;
      conditionType: string;
      conditionValue?: string;
    }[]
  >(
    (recommendations) => {
      return addRecommendations(state?.productId, recommendations);
    },
    {
      onSuccess: () => {
        navigate(-1);
        refetchProducts();
      },
    }
  );

  function onSubmit(values: AddRecommendationFormValues) {
    const { recommendations } = values;

    addRecommendationMutate(recommendations ? [...recommendations] : []);
  }

  return (
    <Modal title={`Dodaj rekomendacje`} visible>
      <Formik
        initialValues={
          {
            recommendations: [
              {
                formCode: '',
                questionCode: '',
                conditionType: '',
                conditionValue: '',
              },
            ],
          } as AddRecommendationFormValues
        }
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ errors, values }) => {
          return (
            <Form className="space-y-8 h-full">
              <FieldArray name="recommendations">
                {(helpers) => {
                  return (
                    <div>
                      <div className="flex justify-end items-center mb-4">
                        <Button
                          variant="outline-primary"
                          onPress={() => helpers.push('')}
                          size="sm">
                          <Text weight="semibold" className="flex items-center gap-2 font-bold">
                            <Icon className="text-xl font-bold" name="plus-lg" /> Dodaj kolejną
                            zasadę
                          </Text>
                        </Button>
                      </div>

                      <div className="h-[55vh] overflow-auto">
                        {values?.recommendations?.map((recommendation, index: number) => {
                          return <AddRecommendationForm helpers={helpers} index={index} />;
                        })}
                      </div>

                      <ModalActions
                        className="mt-4"
                        isLoading={addIsLoading}
                        isConfirmDisabled={!!Object.values(errors).length}
                        onCancel={() => navigate(-1)}
                      />

                      {addIsError && <ErrorMessages error={addError} />}
                    </div>
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddRecommendation;
