import { Box, Button, Icon, Text } from '@profitowi/component-library';
import React from 'react';

interface Props {
  type: 'warning' | 'error' | 'success';
  header: string;
  message: string;
  iconClass?: string;
  className?: string;
  showButton?: boolean;
}

function AlertBox({
  type,
  message,
  header,
  showButton = false,
  iconClass = '',
  className = '',
}: Props) {
  return (
    <Box
      className={`grid my-8 col-1 gap-2 justify-items-center rounded-none ${className}`}
      shadow="md">
      {type === 'error' && (
        <Icon name="exclamation-triangle" className={`text-rose-600 ${iconClass}`} />
      )}
      {type === 'warning' && (
        <Icon name="arrow-clockwise" className={`text-primary ${iconClass}`} />
      )}
      {type === 'success' && (
        <Icon name="check-circle-fill" className={`text-emerald-500 ${iconClass}`} />
      )}
      <Text weight="bold" size="md" className="text-center">
        {header}
      </Text>
      <Text weight="normal" size="base" className="text-center">
        {message}
      </Text>
      {showButton ? (
        <Button className="block w-full" variant="outline-primary">
          Spróbuj ponownie
        </Button>
      ) : (
        ''
      )}
    </Box>
  );
}

export default AlertBox;
