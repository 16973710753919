import { Button } from '@profitowi/component-library';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

type SidebarButtonProps = {
  children: ReactNode;
  isDisabled?: boolean;
  onPress: () => void;
  className?: string;
  variant?: 'light' | 'primary' | 'secondary' | 'outline-primary';
};
function SidebarButton({
  children,
  isDisabled = false,
  onPress,
  variant = 'light',
  className,
}: SidebarButtonProps) {
  return (
    <Button
      isDisabled={isDisabled}
      onPress={() => onPress()}
      className={clsx('block w-full text-left', className)}
      variant={variant}>
      <div className="flex">{children}</div>
    </Button>
  );
}

export default SidebarButton;
