const AREAS = {
  AUTO: {
    name: 'Ubezpieczenie pojazdu',
    secondaryIcon: undefined,
    icon: 'car',
    custom: true,
    bgColor: 'bg-violet-800',
  },
  HOME: {
    name: 'Ubezpieczenie nieruchomości',
    secondaryIcon: undefined,
    icon: 'home',
    custom: true,
    bgColor: 'bg-blue-800',
  },
  KIDS: {
    name: 'Zabezpieczenie przyszłości dziecka',
    secondaryIcon: undefined,
    icon: 'children',
    custom: true,
    bgColor: 'bg-orange-400',
  },
  ACCIDENT: {
    name: 'Pomoc w sytuacji choroby lub wypadku',
    secondaryIcon: undefined,
    icon: 'ambulance',
    custom: true,
    bgColor: 'bg-yellow-400',
  },
  FINANCE: {
    name: 'Zabezpieczenie Twoich bliskich oraz zobowiązań finansowych',
    secondaryIcon: undefined,
    icon: 'piggy',
    custom: true,
    bgColor: 'bg-cyan-600',
  },
  RETIREMENT: {
    name: 'Prywatna emerytura z korzyściami podatkowymi',
    secondaryIcon: undefined,
    icon: 'rocking-chair',
    custom: true,
    bgColor: 'bg-rose-400',
  },
  SAVINGS: {
    name: 'Efektywne oszczędzanie i pomnażanie kapitału',
    secondaryIcon: undefined,
    icon: 'money-arrow-up',
    custom: true,
    bgColor: 'bg-green-600',
  },
  TRAVEL: {
    name: 'Bezpieczne podróże',
    secondaryIcon: undefined,
    icon: 'luggage',
    custom: true,
    bgColor: 'bg-amber-600',
  },
  LIABILITY: {
    name: 'Szkody małe i duże',
    secondaryIcon: undefined,
    icon: 'weight',
    custom: true,
    bgColor: 'bg-lime-600',
  },
  LOANS: {
    name: 'Kredyty',
    icon: 'savings',
    secondaryIcon: 'loan',
    custom: true,
    bgColor: 'bg-indigo-600',
  },
  EMPLOYEES: {
    name: 'Zdrowie i życie Twoich pracowników',
    icon: 'hearth',
    secondaryIcon: 'medical',
    custom: true,
    bgColor: 'bg-fuchsia-500',
  },
  PROPERTY: {
    name: 'Zabezpieczenie majątku firmy',
    secondaryIcon: undefined,
    icon: 'plant',
    custom: true,
    bgColor: 'bg-sky-600',
  },
  CONTINUITY: {
    name: 'Sukcesja i motywacja kluczowych pracowników',
    icon: 'suitcase',
    secondaryIcon: undefined,
    custom: true,
    bgColor: 'bg-amber-500',
  },
  INDUSTRY: {
    name: 'OC dla Twojej działalności',
    secondaryIcon: undefined,
    icon: 'exchange',
    custom: true,
    bgColor: 'bg-emerald-500',
  },
  INVESTMENT: {
    name: 'Kredyt, Leasing, Factoring',
    secondaryIcon: undefined,
    icon: 'document',
    custom: true,
    bgColor: 'bg-pink-600',
  },
  SHORTEN_COMPANY: {
    name: 'Skrócona analiza potrzeb klienta',
    secondaryIcon: undefined,
    icon: 'analysis',
    custom: true,
    bgColor: 'bg-emerald-600',
  },
  SHORTEN_PRIVATE: {
    name: 'Skrócona analiza potrzeb klienta',
    secondaryIcon: undefined,
    icon: 'travel',
    custom: true,
    bgColor: 'bg-emerald-600',
  },
};
export default AREAS;
