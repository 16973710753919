import { Modal } from '@profitowi/component-library';

import * as photos from '../assets';

type Props = {
  name: string;
  setImage: () => void;
};

const ModalDescription = ({ name = '', setImage }: Props) => {
  return (
    <Modal title="" visible hasCloseButton setVisible={setImage} className="bg-transparent !p-0">
      <div className="!m-0">
        {/* FIXME: alt */}
        <img alt="" src={(photos.default as any)[name]} />
      </div>
    </Modal>
  );
};

export default ModalDescription;
