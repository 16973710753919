import { Modal, Option, SelectField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import ErrorMessages from '../../../components/ErrorMessages/ErrorMessages';
import ModalActions from '../../../components/ModalActions';
import { PRODUCTS } from '../../../constants/queries/products';
import { requiredFieldMessage } from '../../../constants/requiredFieldMessage';
import { getDocumentsIda } from '../../../services/documents';
import { changeProductsIda } from '../../../services/products';
import { DocumentsIda } from '../../../types/documents';
import { Product } from '../../../types/products';

const validationSchema = yup.object().shape({
  newIdaId: yup.number().required(requiredFieldMessage),
});
export type ChangeProductsIdaFormValues = yup.InferType<typeof validationSchema>;

type Props = {
  refetchProducts: () => void;
};

function ChangeProductsIda({ refetchProducts }: Props) {
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const {
    data: productsIda,
    isFetching: productsIdaIsLoading,
    isError: productsIdaIsError,
    error: productsIdaError,
  } = useQuery<DocumentsIda[], AxiosError, Option[]>(
    [PRODUCTS.PRODUCT_IDA],
    () => getDocumentsIda(),
    {
      select: (data: DocumentsIda[]): Option[] => {
        return data.map((document: DocumentsIda) => ({
          value: document.name,
          key: document.productId,
        }));
      },
    }
  );

  const {
    mutate: mutateChangeProductIda,
    isLoading: changeProductIdaIsLoading,
    isError: changeProductIdaIsError,
    error: changeProductIdaError,
  } = useMutation<AxiosResponse<any>, AxiosError, { newIdaId: number; productNumbers: number[] }>(
    ({ newIdaId, productNumbers }) => {
      return changeProductsIda(newIdaId, productNumbers);
    },
    {
      onSuccess: () => {
        refetchProducts();
        navigate(-1);
      },
    }
  );

  function onSubmit(values: ChangeProductsIdaFormValues) {
    const { newIdaId } = values;
    const selectedProducts = state?.selectedProducts.map((product: Product) => product.productId);

    mutateChangeProductIda({ newIdaId, productNumbers: selectedProducts });
  }

  return (
    <Modal title={`Zmiana przypisania IDA`} visible>
      <Formik
        initialValues={
          {
            newIdaId: undefined,
          } as unknown as ChangeProductsIdaFormValues
        }
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ errors, dirty, isValid }) => (
          <Form className="space-y-8">
            <SelectField options={productsIda || []} label="Przypisanie IDA" name="newIdaId" />

            <ModalActions
              className="mt-8"
              isLoading={productsIdaIsLoading || changeProductIdaIsLoading}
              isConfirmDisabled={!!Object.values(errors).length && !dirty && !isValid}
              onCancel={() => navigate(-1)}
            />

            {productsIdaIsError && <ErrorMessages error={productsIdaError} />}
            {changeProductIdaIsError && <ErrorMessages error={changeProductIdaError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ChangeProductsIda;
