import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AssessmentForm from 'components/AssessmentForm/AssessmentForm';
import DevAuth from 'components/DevAuth/DevAuth';
import PageLayout from 'components/PageLayout';
import PrivateRoute from 'components/PrivateRoute';
import About from 'pages/About';
import Confirmation from 'pages/Confirmation';
import CustomerData from 'pages/CustomerData';
import Dashboard from 'pages/Dashboard';
import DocumentsPage from 'pages/Documents/DocumentsPage';
import FormsMain from 'pages/FormsMain';
import HowCanWeHelp from 'pages/HelpInformation';
import RecommendedProducts from 'pages/RecommendedProducts';
import Summary from 'pages/Summary';
import FinishAnalysis from 'pages/Summary/FinishAnalysis/FinishAnalysis';
import Surveys from 'pages/Surveys';
import Survey from 'pages/Surveys/components/Survey';

import ProductsPageLayout from './components/ProductsPageLayout';
import ProductsPage from './pages/Products/ProductsPage';
import { ProductsPages } from './types/productsPages';

const App = () => {
  return (
    <div className="xl:container mx-auto">
      <BrowserRouter>
        <Routes>
          <Route path="/confirmation" element={<Confirmation />} />
          <Route element={process.env.NODE_ENV === 'production' ? <PrivateRoute /> : <DevAuth />}>
            <Route path="/*" element={<Dashboard />} />
            <Route element={<ProductsPageLayout />}>
              <Route path={`${ProductsPages.DOCUMENTS}/*`} element={<DocumentsPage />} />
              <Route path={`${ProductsPages.PRODUCTS}/*`} element={<ProductsPage />} />
            </Route>
            <Route path="newAnalysis">
              <Route path="legalForm/:legalForm" element={<>nowa analiza</>} />
              <Route path="customer/:customer" element={<>nowa analiza dla wyszukanego</>} />
            </Route>
            <Route element={<PageLayout />}>
              <Route path="aboutProfitowi" element={<About />} />
              <Route path="howCanWeHelp" element={<HowCanWeHelp />} />
              <Route path="yourData" element={<CustomerData />} />
              <Route path="yourNeeds" element={<FormsMain />} />
              <Route path="yourNeeds/form/:aClientId/:formId" element={<AssessmentForm />} />
              <Route path="summary/*" element={<Summary />} />
              <Route path="recommendedProducts" element={<RecommendedProducts />} />
              <Route path="surveys" element={<Surveys />} />
              <Route path="/surveys/survey/:aClientId/:surveyId" element={<Survey />} />
            </Route>
            <Route path="finish" element={<FinishAnalysis />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
