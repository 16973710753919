import { Text } from '@profitowi/component-library';
import { SliderProps } from '@profitowi/component-library/dist/types/Slider/Slider';
import { useFocusRing } from '@react-aria/focus';
import { useNumberFormatter } from '@react-aria/i18n';
import { SliderThumbOptions, useSlider, useSliderThumb } from '@react-aria/slider';
import { mergeProps } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { SliderState, SliderStateOptions, useSliderState } from '@react-stately/slider';
import clsx from 'clsx';
import React, { useRef } from 'react';

// slider that hides negative values - could replace slider in component-library
const Slider = (props: SliderProps) => {
  const { className, label, options = [] } = props;
  const trackRef = useRef<HTMLDivElement>(null);
  const numberFormatter = useNumberFormatter({ style: 'decimal' });
  const state = useSliderState({ ...props, numberFormatter } as SliderStateOptions);
  const { groupProps, trackProps, labelProps } = useSlider(props, state, trackRef);

  const value = state.getThumbValue(0);
  const handleSetValue = (value: number) => () => state.setThumbValue(0, value);

  return (
    <div
      {...groupProps}
      className={clsx(
        'flex flex-col items-start touch-none space-y-4',
        { 'pb-8': options.length },
        className
      )}>
      {label && <label {...labelProps}>{label}</label>}
      <div className="px-2.5 w-full">
        <div
          {...trackProps}
          ref={trackRef}
          className={clsx('relative h-5 cursor-pointer', {
            'cursor-grabbing': state.isThumbDragging(0),
          })}>
          <div className="absolute top-1/2 -translate-y-1/2 w-full h-1.5 bg-gray-light" />
          <div
            className="absolute top-1/2 -translate-y-1/2 h-1.5 bg-primary"
            style={{ width: `${state.getThumbPercent(0) * 100}%` }}
          />
          <div className="flex justify-between w-full">
            {options.map((option, index) => (
              <div
                className="flex justify-center relative"
                key={option}
                onClick={handleSetValue(index)}>
                <div
                  className={clsx(
                    'w-5 h-5 rounded-full absolute',
                    value > index ? 'bg-primary' : 'bg-gray-light'
                  )}
                />
                <Text
                  as="span"
                  size="xs"
                  weight="semibold"
                  className="absolute top-7 uppercase text-center w-28">
                  {option}
                </Text>
              </div>
            ))}
          </div>
          <Thumb index={0} state={state} trackRef={trackRef} />
        </div>
      </div>
    </div>
  );
};

type ThumbProps = Omit<SliderThumbOptions, 'inputRef'> & { state: SliderState };

const Thumb = (props: ThumbProps) => {
  const { state, trackRef, index } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { thumbProps, inputProps } = useSliderThumb(
    { index, trackRef, inputRef } as SliderThumbOptions,
    state
  );

  const { focusProps } = useFocusRing();

  return (
    <div
      {...thumbProps}
      className={clsx(
        'absolute -translate-x-1/2 w-5 h-5 rounded-full bg-primary',
        state.getThumbPercent(0) < 0 ? 'hidden' : 'bg-primary'
      )}
      style={{ left: `${state.getThumbPercent(index) * 100}%` }}>
      <VisuallyHidden>
        <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
      </VisuallyHidden>
    </div>
  );
};

export default Slider;
