import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';

import { SummaryTable } from 'types/form';

type Props = {
  form: SummaryTable;
};

type TableRow = {
  subjectName: string;
  subjectId: number;
  tableRows: {
    summaryName: string;
    summaryValue: string;
    orderNo: number;
  }[];
};

type Headers = {
  [key: string]: string;
};

function Table({ form }: Props) {
  const createColumns = useMemo(() => {
    const column: Column<Headers>[] = form.tableColumns
      .sort((a: TableRow, b: TableRow) => {
        return a.subjectId - b.subjectId;
      })
      .map((column) => {
        return {
          Header: column.subjectName?.length ? column.subjectName : ' ',
          accessor: column.subjectName?.length ? column.subjectName : ' ',
        };
      });
    return [
      { Header: form.sectionName.length ? form.sectionName : ' ', accessor: 'categories' },
      ...column,
    ];
  }, [form]);

  const createData = useMemo(() => {
    const categoryValues: Headers[] = [];

    form.tableColumns.map((rows) =>
      rows.tableRows.forEach((val) => {
        categoryValues[val.orderNo - 1] = { categories: val.summaryName };
      })
    );
    const data: Headers[] = [];
    form.tableColumns.forEach((column) => {
      column.tableRows.forEach((val) => {
        data[val.orderNo - 1] = {
          ...data[val.orderNo - 1],
          [column.subjectName ? column.subjectName : ' ']: val.summaryValue,
        };
      });
    });

    data.map((val: {}, index: number) => (data[index] = { ...val, ...categoryValues[index] }));
    return data;
  }, [form]);

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: createColumns,
    data: createData,
  });

  return (
    <table className="w-full">
      <thead className="bg-gray-100 text-gray-500 bold">
        {headerGroups.map((headerGroup, index) => {
          return (
            <tr className="border-2" {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column) => (
                <th className="py-4 px-6 border-2 text-sm" key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr key={row.id + index}>
              {row.cells.map((cell, index) => {
                return (
                  <td
                    className="whitespace-nowrap py-4 px-6 border-2 text-sm"
                    key={cell.value + index}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
