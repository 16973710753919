import { ClientType } from 'types/client';
import { AREA_TYPE } from 'types/forms';

export const shownAreas: Record<ClientType, AREA_TYPE[]> = {
  INDIVIDUAL: [
    AREA_TYPE.AUTO,
    AREA_TYPE.HOME,
    AREA_TYPE.KIDS,
    AREA_TYPE.ACCIDENT,
    AREA_TYPE.FINANCE,
    AREA_TYPE.RETIREMENT,
    AREA_TYPE.SAVINGS,
    AREA_TYPE.TRAVEL,
    AREA_TYPE.LIABILITY,
    AREA_TYPE.LOANS,
    AREA_TYPE.SHORTEN_PRIVATE,
  ],
  COMPANY: [
    AREA_TYPE.EMPLOYEES,
    AREA_TYPE.PROPERTY,
    AREA_TYPE.CONTINUITY,
    AREA_TYPE.INDUSTRY,
    AREA_TYPE.INVESTMENT,
    AREA_TYPE.SHORTEN_COMPANY,
  ],
};
