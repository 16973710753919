import { ClientType } from './client';

export type AssessmentPayload = {
  clientId?: number;
  clientType: ClientType;
};

export enum ASSESSMENT_TYPE {
  ABOUT_PROFITOWI = 'ABOUT_PROFITOWI',
  AREA = 'AREA',
  YOUR_DATA = 'YOUR_DATA',
  FORM = 'FORM',
  RECOMMENDATION = 'RECOMMENDATION',
  SURVEY = 'SURVEY',
  SUMMARY = 'SUMMARY',
}

export enum StateType {
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type BasicClientData = {
  aclientId: number;
  clientId: number;
  clientType: ClientType;
  firstName?: string;
  lastName?: string;
  name?: string;
};

export type AssessmentDto = {
  assessmentId: number;
  clients: BasicClientData[];
  filledSteps: string[];
  lastModificationDate: string;
  state: StateType;
  stepsStates: AssessmentStepStates[];
  type: 'SIMPLE' | 'FULL';
  withStatements: boolean;
  startingClientType: ClientType;
};

export type AssessmentStepStates = {
  assessmentId: number;
  details: [];
  stepType: ASSESSMENT_TYPE;
  valid: boolean;
};

export enum FORM_STATE {
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}
