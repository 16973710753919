import * as yup from 'yup';

const alphabeticRegex = /^[\p{L}]+$/u;
const phoneRegex = /^((?:48\d{9})|((?!48\d)\d{9,16}))$/;

export const personalDataSchema = yup.object({
  id: yup.number().default(-1),
  firstName: yup
    .string()
    .isValidAlphabeticSpecialSigns()
    .max(255, 'Przekroczono dozwoloną ilość znaków')
    .required('Imię jest wymagane')
    .default(''),
  lastName: yup
    .string()
    .isValidAlphabeticSpecialSigns()
    .max(255, 'Przekroczono dozwoloną ilość znaków')
    .required('Nazwisko jest wymagane')
    .default(''),
  phone: yup
    .string()
    .matches(phoneRegex, 'Pole powinno zawierać same cyfry')
    .required('Numer kontaktowy jest wymagany')
    .default(''),
  email: yup
    .string()
    .email('Niepoprawny format adresu e-mail')
    .required('E-mail jest wymagany')
    .default(''),
  pesel: yup.string().isValidPesel().default(''),
  street: yup
    .string()
    .isValidAlphabeticNumberSpecialSigns()
    .max(255, 'Przekroczono dozwoloną ilość znaków')
    .nullable()
    .default(''),
  houseNo: yup
    .string()
    .isValidAlphabeticNumberSpecialSigns()
    .max(255, 'Przekroczono dozwoloną ilość znaków')
    .nullable()
    .default(''),
  flatNo: yup
    .string()
    .isValidAlphabeticNumberSpecialSigns()
    .max(255, 'Przekroczono dozwoloną ilość znaków')
    .nullable()
    .default(''),
  zipCode: yup.string().nullable().default(''),
  city: yup
    .string()
    .isValidAlphabeticNumberSpecialSigns()
    .max(255, 'Przekroczono dozwoloną ilość znaków')
    .nullable()
    .default(''),
  clientType: yup.mixed().oneOf(['COMPANY', 'INDIVIDUAL']).default('INDIVIDUAL'),
});

export const validationSchema = yup.object({
  personalData: personalDataSchema,
});
