import { AxiosError } from 'axios';
import { Dictionary, keyBy } from 'lodash';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getAssessment, validateAssessmentStep } from 'services/assessment';
import { AssessmentDto, AssessmentStepStates } from 'types/assessment';

import { useAgentStore } from './useAgentStore';
import { useAssessmentStore } from './useStore';

export function useStepValidation() {
  const [stepsState, setStepsState] = useState<Dictionary<AssessmentStepStates>>({});
  const agentId = useAgentStore((state) => state.agent.agentId);
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId || NaN);

  const { refetch } = useQuery<AssessmentDto, AxiosError>(
    ['getAssessment', agentId, assessmentId],
    () => getAssessment(assessmentId, agentId),
    {
      onSuccess: ({ stepsStates }) => {
        setStepsState(keyBy(stepsStates, 'stepType'));
      },
    }
  );

  const { mutate } = useMutation<AssessmentStepStates[], AxiosError, string | undefined>(
    (step) => validateAssessmentStep(agentId, assessmentId, step),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const clearValidationSteps = () => setStepsState({});

  return { stepsState, validateSteps: mutate, clearValidationSteps };
}
