import { Tab } from '@headlessui/react';
import { Button, Icon, Loader } from '@profitowi/component-library';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

const variants = {
  dark: {
    default: 'hover:text-primary',
    selected: 'bg-primary hover:text-white text-white',
  },
  light: {
    default: 'py-1.5 hover:text-primary ',
    selected: 'hover:text-primary border-b-2 border-light py-1.5',
  },
  gray: {
    default: 'hover:text-primary',
    selected: 'bg-white',
  },
};

const classes = {
  list: {
    spaceX1: 'space-x-1',
    spaceX4: 'space-x-4',
    colors: 'bg-gray border-2 border-gray',
  },
  tab: {
    margin: 'w-auto px-5 py-2',
  },
};

type Props = {
  className?: string;
  variant: 'dark' | 'light' | 'gray';
  categories: string[];
  children?: ReactNode;
  onChange?: (index: number) => void;
  add?: () => void;
  isAdding?: boolean;
};

const ModifiableTabs = ({
  variant = 'dark',
  className,
  categories,
  children,
  onChange = (index) => {},
  add,
  isAdding = false,
}: Props) => {
  return (
    <Tab.Group onChange={onChange}>
      <Tab.List
        className={clsx(
          {
            [classes.list.spaceX1]: variant !== 'light',
            [classes.list.spaceX4]: variant === 'light',
            [classes.list.colors]: variant === 'gray',
          },
          'flex'
        )}>
        {categories.map((category) => (
          <Tab
            key={category}
            className={({ selected }) =>
              clsx(
                'text-sm leading-5 font-medium focus:outline-none',
                {
                  'text-secondary': !selected,
                  [variants[variant].selected]: selected,
                  [variants[variant].default]: !selected,
                  [classes.tab.margin]: variant !== 'light',
                },
                className
              )
            }>
            {category}
          </Tab>
        ))}
        {add && (
          <Button
            className="mt-6 h-14 w-14 flex place-content-center"
            variant="outline-primary"
            isDisabled={isAdding}
            onPress={() => {
              add();
            }}>
            {isAdding ? <Loader className="h-3 w-3 mt-1" /> : <Icon name="plus-lg" />}
          </Button>
        )}
      </Tab.List>
      <Tab.Panels className="pt-6">{children}</Tab.Panels>
    </Tab.Group>
  );
};

export default ModifiableTabs;
