import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useSearchParams } from 'react-router-dom';

import { AGENT } from 'constants/queries/agent';
import { AUTH } from 'constants/queries/auth';
import { useAgentStore } from 'hooks/useAgentStore';
import { useCrmClient } from 'hooks/useStore';
import { authorize, getAuthToken, getAuthorizedUser } from 'services/auth';
import { AgentBase } from 'types/agent';

const PrivateRoute = () => {
  const setAgent = useAgentStore((state) => state.setAgent);
  const setCrmClientId = useCrmClient((state) => state.setCrmClientId);

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const cmrClientId = searchParams.get('id');
  const token = sessionStorage.getItem('__token');

  useEffect(() => {
    cmrClientId && sessionStorage.setItem('crmClientId', cmrClientId);
  }, []);

  useQuery<string, AxiosError>([AUTH.GET_URL], () => authorize(), {
    onSuccess: (authUrl) => authUrl && window.open(authUrl, '_self'),
    enabled: !token && !code,
  });

  useQuery<string, AxiosError>([AUTH.GET_TOKEN, code], () => getAuthToken(code), {
    onSuccess: (token) => sessionStorage.setItem('__token', token),
    enabled: !!code && !token,
  });

  const { data, isFetched: isAgentFetched } = useQuery<AgentBase, AxiosError>(
    [AGENT.AUTHORIZE],
    () => getAuthorizedUser(),
    {
      onSuccess: (data) => {
        setAgent(data);

        const crmClientId = sessionStorage.getItem('crmClientId');
        crmClientId && setCrmClientId(crmClientId);
      },
      enabled: !!token && token.length > 0,
    }
  );

  return data && isAgentFetched ? <Outlet /> : null;
};

export default PrivateRoute;
