import pl from 'date-fns/locale/pl';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
};

const DatePickerField = ({ label, name, placeholder }: Props) => {
  const [field, meta, helpers] = useField(name);

  const { setValue } = helpers;

  return (
    <DatePicker
      locale="pl"
      className="w-full pl-3 pr-9 py-3 border-transparent border-2 text-sm bg-gray-light placeholder-gray-900 font-semibold focus:ring-transparent focus:ring-0 focus:bg-white focus:border-gray focus:outline-none"
      dateFormat="dd-MM-yyyy"
      selected={field.value ? new Date(field.value) : null}
      onChange={(value: Date) => setValue(value.toISOString().slice(0, 10))}
      showPopperArrow={false}
      placeholderText={placeholder}
    />
  );
};

export default DatePickerField;
