import { Button, Icon, Modal } from '@profitowi/component-library';
import React, { useState } from 'react';

import InfoHintContents from './InfoHintContents';

type Props = {
  type: 'info' | 'hint';
  contents: string;
};

const icon = (type: 'info' | 'hint') => {
  if (type == 'info') return 'info-circle';
  return 'question-circle';
};

const InfoHint = ({ contents, type }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button size="sm" variant="light" onPress={() => setModalOpen(true)}>
        <div className="-my-1 -mx-4">
          <Icon name={icon(type)} className="text-xl" />
        </div>
      </Button>
      <Modal
        visible={modalOpen}
        title=""
        setVisible={setModalOpen}
        className="flex flex-col items-center">
        <InfoHintContents contents={contents} />
        <Button onPress={() => setModalOpen(false)}>Zamknij</Button>
      </Modal>
    </>
  );
};

export default InfoHint;
