import { AxiosError, AxiosResponse } from 'axios';
import { ReactNode, useMemo } from 'react';
import { UseMutationResult } from 'react-query';

import ModifiableTabs from 'components/ModifiableTabs/ModifiableTabs';
import { Subject } from 'types/forms';
import { SurveySubject } from 'types/surveys';

import NAMES from './subjectNames';

type Props = {
  children: ReactNode;
  subjects: Subject[] | SurveySubject[];
  multiSubject: boolean;
  clientName: string;
  createNewSubject: UseMutationResult<AxiosResponse<any, any>, AxiosError<any, any>, void, unknown>;
};

const SubjectNavigation = ({
  subjects,
  children,
  multiSubject,
  clientName,
  createNewSubject,
}: Props) => {
  const names = useMemo(() => {
    if (!multiSubject) return [clientName];
    return subjects.map(({ subjectType, subjectDescription }, index) => {
      if (subjectDescription) return subjectDescription;
      return `${NAMES[subjectType as keyof typeof NAMES] ?? 'Podmiot'} ${index + 1}`;
    });
  }, [clientName, multiSubject, subjects]);

  const { mutate: add = undefined, isLoading: isAdding = false } =
    multiSubject && createNewSubject ? createNewSubject : {};

  return (
    <ModifiableTabs
      categories={names}
      add={add}
      isAdding={isAdding}
      variant="dark"
      className="mt-6">
      {children}
    </ModifiableTabs>
  );
};

export default SubjectNavigation;
