const FORMS = {
  AUTO: {
    secondaryIcon: undefined,
    icon: 'car',
    custom: true,
    bgColor: 'bg-violet-800',
  },
  HOME: {
    secondaryIcon: undefined,
    icon: 'home',
    custom: true,
    bgColor: 'bg-blue-800',
  },
  KIDS: {
    secondaryIcon: undefined,
    icon: 'children',
    custom: true,
    bgColor: 'bg-orange-400',
  },
  ACCIDENT: {
    secondaryIcon: undefined,
    icon: 'ambulance',
    custom: true,
    bgColor: 'bg-yellow-400',
  },
  FINANCE: {
    secondaryIcon: undefined,
    icon: 'piggy',
    custom: true,
    bgColor: 'bg-cyan-600',
  },
  RETIREMENT: {
    secondaryIcon: undefined,
    icon: 'rocking-chair',
    custom: true,
    bgColor: 'bg-rose-400',
  },
  SAVINGS: {
    secondaryIcon: undefined,
    icon: 'money-arrow-up',
    custom: true,
    bgColor: 'bg-green-600',
  },
  TRAVEL: {
    secondaryIcon: undefined,
    icon: 'luggage',
    custom: true,
    bgColor: 'bg-amber-600',
  },
  LIABILITY: {
    secondaryIcon: undefined,
    icon: 'weight',
    custom: true,
    bgColor: 'bg-lime-600',
  },
  LOANS: {
    icon: 'savings',
    secondaryIcon: undefined,
    custom: true,
    bgColor: 'bg-indigo-600',
  },
  EMPLOYEE_HEALTH: {
    icon: 'hearth',
    secondaryIcon: 'medical',
    custom: true,
    bgColor: 'bg-fuchsia-500',
  },
  EMPLOYEE_INSURANCE: {
    icon: 'savings',
    secondaryIcon: 'medical',
    custom: true,
    bgColor: 'bg-lime-600',
  },
  TRUCK: {
    secondaryIcon: undefined,
    icon: 'car',
    custom: true,
    bgColor: 'bg-violet-800',
  },
  PROPERTY: {
    secondaryIcon: undefined,
    icon: 'plant',
    custom: true,
    bgColor: 'bg-sky-600',
  },
  CONTINUITY: {
    icon: 'suitcase',
    secondaryIcon: 'business',
    custom: true,
    bgColor: 'bg-amber-500',
  },
  INDUSTRY: {
    secondaryIcon: undefined,
    icon: 'exchange',
    custom: true,
    bgColor: 'bg-emerald-500',
  },
  INVESTMENT: {
    secondaryIcon: undefined,
    icon: 'plant',
    custom: true,
    bgColor: 'bg-pink-600',
  },
  SHORTEN_COMPANY: {
    icon: 'analysis',
    secondaryIcon: undefined,
    custom: true,
    bgColor: 'bg-emerald-600',
  },
  SHORTEN_PRIVATE: {
    icon: 'travel',
    secondaryIcon: undefined,
    custom: true,
    bgColor: 'bg-emerald-600',
  },
};
export default FORMS;
