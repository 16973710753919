import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { ConditionType, ProductConditions } from '../../../types/products';

export const columns = (
  onDeleteRecommendation: (recConditionId: number) => void
): Column<ProductConditions>[] => [
  {
    Header: 'Obszar',
    accessor: 'areaTypeDisplayName',
    sortType: 'string',
  },
  {
    Header: 'Pytanie',
    accessor: 'question',
    sortType: 'string',
  },
  {
    Header: 'Kod pytania',
    accessor: 'questionCode',
    sortType: 'string',
  },
  {
    Header: 'Operator',
    sortType: 'string',
    Cell: ({
      row: {
        original: { conditionType },
      },
    }: Cell<ProductConditions>) => {
      return (
        <div className="flex">
          {ConditionType[conditionType as unknown as keyof typeof ConditionType] || conditionType}
        </div>
      );
    },
  },
  {
    Header: 'Wartość',
    accessor: 'conditionValue',
  },
  {
    id: 'operations',
    Cell: ({
      row: {
        original: { recConditionId },
      },
    }: Cell<ProductConditions>) => {
      return (
        <div className="flex space-x-6 justify-end">
          <Button variant="light" onPress={() => onDeleteRecommendation(recConditionId)}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      );
    },
  },
];
