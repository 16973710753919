import create from 'zustand';

import { AgentBase } from 'types/agent';

const initialAgent = {
  agentCode: '',
  agentId: -1,
  crmAgentId: '',
  email: '',
  firstName: '',
  lastName: '',
  isAdmin: false,
  name: '',
};

type AgentStore = {
  agent: AgentBase;
  setAgent: (agent: AgentBase) => void;
};

export const useAgentStore = create<AgentStore>((set) => ({
  agent: initialAgent,
  setAgent: (agent: AgentBase) => set({ agent }),
}));
