import * as yup from 'yup';

import { isConditionFulfilled } from 'components/AssessmentForm/helpers';
import { ANSWER_SUBGROUP, FIELD_TYPE, Question } from 'types/forms';

const integerSubgroups = [ANSWER_SUBGROUP.NUMBER];
const naturalSubgroups = [
  ANSWER_SUBGROUP.NUMBER_OF_YEAR,
  ANSWER_SUBGROUP.YEAR,
  ANSWER_SUBGROUP.MONTHS,
  ANSWER_SUBGROUP.DAYS,
  ANSWER_SUBGROUP.KILOMETER,
  ANSWER_SUBGROUP.M_2,
];
const floatSubgroups = [
  ANSWER_SUBGROUP.AMOUNT,
  ANSWER_SUBGROUP.AMOUNT_CURRENCY,
  ANSWER_SUBGROUP.PERCENT,
];

const createQuestionSchema = (question: Question) => {
  const { required, answerType, conditionQuestionCode, conditionType, conditionValue } = question;
  const { subGroup, fieldType } = answerType;
  let schema: any;

  switch (fieldType) {
    case FIELD_TYPE.CHECKBOX:
      schema = yup.array();
      break;
    case FIELD_TYPE.CALENDAR:
      schema = yup.date();
      break;
    default:
      schema = yup.string();
      break;
  }

  if (integerSubgroups.map((e) => e.valueOf()).includes(subGroup))
    schema = schema.isIntegerNumber();
  if (naturalSubgroups.map((e) => e.valueOf()).includes(subGroup))
    schema = schema.isNaturalNumber();
  if (floatSubgroups.map((e) => e.valueOf()).includes(subGroup))
    schema = schema.isFloatPrecision2();
  switch (subGroup) {
    case ANSWER_SUBGROUP.NO_ANSWER:
      return; // no schema
    case ANSWER_SUBGROUP.NIP:
      schema = schema.isValidNip();
      break;
    case ANSWER_SUBGROUP.REGON:
      schema = schema.isValidRegon();
      break;
    case ANSWER_SUBGROUP.PESEL:
      schema = schema.isValidPesel();
      break;
    case ANSWER_SUBGROUP.SHORT_TEXT:
      schema = schema.max(256);
      break;
    case ANSWER_SUBGROUP.LONG_TEXT:
      schema = schema.max(4000);
      break;
    case ANSWER_SUBGROUP.PHONE:
      schema = schema.isValidPhoneNumber();
      break;
  }

  if (required) {
    if (conditionQuestionCode && conditionType && conditionValue) {
      schema = schema.when(conditionQuestionCode, {
        is: (value: any) => isConditionFulfilled(value, conditionType, conditionValue),
        then: (schema: any) => schema.required('Pole jest wymagane'),
      });
    } else {
      schema = schema.required('Pole jest wymagane');
    }
    if (fieldType === FIELD_TYPE.CHECKBOX) schema = schema.min(1);
  }

  return schema;
};

export const createValidationSchema = (questions: Question[]) => {
  const shape = Object.fromEntries(
    questions.map((question) => [question.code, createQuestionSchema(question)])
  );

  return yup.object(shape);
};
