import {
  Alert,
  CheckboxField,
  InputField,
  Loader,
  Modal,
  Option,
  Select,
  Text,
  TextareaField,
} from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import ModalActions from 'components/ModalActions';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore } from 'hooks/useStore';
import { getEmailMessage } from 'services/mail';
import { createSummaryPdf, finalize, getSummaryDataPdf } from 'services/summary';
import { EmailResponse } from 'types/mail';

import SummaryPdf from '../SummaryPdf/SummaryPdf';
import { validationSchema } from './validationSchema';

type ModalDescriptionPayload = {
  subject: string;
  content: string;
  confirmRecommendation?: boolean;
};

type Payload = {
  payload: ModalDescriptionPayload;
  assessmentId: number;
  agentId: number;
};

enum EmailSchemaOptions {
  'DEFAULT' = 'DEFAULT',
  'CUSTOM' = 'CUSTOM',
}

const options: { key: EmailSchemaOptions; value: string }[] = [
  { key: EmailSchemaOptions.DEFAULT, value: 'Szablon domyślny' },
  { key: EmailSchemaOptions.CUSTOM, value: 'Szablon własny' },
];

const ModalDescription = () => {
  const [selected, setSelected] = useState<Option>(options[0]);
  const navigate = useNavigate();

  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId || NaN);
  const agentId = useAgentStore((state) => state.agent.agentId);

  const location = useLocation();
  const state = location.state as any;
  const aclientId = state?.aclientId;

  const mailQuery = useQuery<EmailResponse, AxiosError>(
    ['emailMessage', assessmentId, aclientId, agentId],
    () => getEmailMessage(assessmentId, aclientId, agentId)
  );

  const createPdfMutation = useMutation<AxiosResponse, AxiosError, string>((htmlTemplate) =>
    createSummaryPdf(assessmentId, agentId, htmlTemplate)
  );

  const finalizeMutation = useMutation<AxiosResponse, AxiosError, Payload>(
    ({ payload, assessmentId, agentId }) => finalize(payload, assessmentId, agentId),
    {
      onSuccess: () => {
        navigate('/finish');
      },
    }
  );

  const { data } = useQuery<any, AxiosError>(['summaryPdfData'], () =>
    getSummaryDataPdf(assessmentId, agentId)
  );

  const handleSubmit = (
    payload: ModalDescriptionPayload,
    formikHelpers: FormikHelpers<ModalDescriptionPayload>
  ) => {
    if (!payload.confirmRecommendation) {
      formikHelpers.setErrors({
        confirmRecommendation: 'Potwierdzenie rekomendacji jest wymagane',
      });
      return;
    }
    const htmlTemplate = SummaryPdf(data);

    const emailMessage = {
      ...payload,
      content: payload?.content.replace(/\n/g, '<br>') || '',
    };

    createPdfMutation.mutate(htmlTemplate, {
      onSuccess: () => {
        finalizeMutation.mutate({ agentId, assessmentId, payload: emailMessage });
      },
    });
  };

  const handleReset = () => {
    mailQuery.remove();
    navigate('/summary');
  };

  const initialValues = useMemo(() => {
    return {
      subject: mailQuery.data?.subject || '',
      content: mailQuery.data?.content.replace(/<br>/g, '\n') || '',
    };
  }, [mailQuery.data]);

  const contentValue = useMemo(() => {
    if (!mailQuery.data) {
      return '';
    }
    return selected.key !== EmailSchemaOptions.DEFAULT
      ? mailQuery.data.content.replace(/<br>/g, '\n')
      : mailQuery.data.customContent.replace(/<br>/g, '\n');
  }, [mailQuery.data, selected]);
  return (
    <Modal title="" visible>
      <div className="flex flex-col items-center space-y-4">
        <Text size="xl" weight="bold" className="text-primary">
          Wysyłanie podsumowania do Klienta
        </Text>
        <Text size="md" weight="normal">
          Masz zamiar wysłać podsumowanie do Klienta
        </Text>

        {mailQuery.isError && <Alert type="error">{mailQuery.error?.message}</Alert>}
        {mailQuery.isLoading && <Loader className="h-12 w-12" />}
        {mailQuery.isSuccess && (
          <Formik
            initialValues={initialValues}
            onReset={handleReset}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            {({ errors, setFieldValue, values }) => (
              <Form className="space-y-4 w-full">
                {mailQuery.isSuccess && (
                  <div className="w-full">
                    <Select
                      setSelected={(e) => {
                        setSelected(e);
                        setFieldValue('content', contentValue);
                      }}
                      options={options}
                      selected={selected}
                      className="bg-gray-light"
                    />
                  </div>
                )}
                <InputField
                  label="Temat"
                  name="subject"
                  defaultValue={mailQuery.data ? mailQuery.data.subject : ''}
                />
                <TextareaField
                  label="Treść wiadomości"
                  name="content"
                  rows={15}
                  defaultValue={contentValue}
                />
                <CheckboxField name="sendSummaryToAgent">
                  Wyślij kopię na adres e-mail doradcy
                </CheckboxField>
                <div className={`${errors['confirmRecommendation'] && 'text-red-700'}`}>
                  <CheckboxField name="confirmRecommendation">
                    Komu z Twojego otoczenia możemy zaoszczędzić czas i pieniądze?*
                  </CheckboxField>
                </div>
                <ModalActions
                  confirmLabel="Zakończ i wyślij"
                  isConfirmDisabled={createPdfMutation.isLoading || finalizeMutation.isLoading}
                  rightButtonIcon="paper-plane"
                />
              </Form>
            )}
          </Formik>
        )}

        {(createPdfMutation.isLoading || finalizeMutation.isLoading) && (
          <div className="bg-white opacity-80 absolute inset-0 flex align-middle justify-center z-10">
            <Loader className="h-10 w-10" />
          </div>
        )}

        {(createPdfMutation.isError || finalizeMutation.isError) && (
          <Alert type="error">
            {createPdfMutation.error?.message || finalizeMutation.error?.message}
          </Alert>
        )}
      </div>
    </Modal>
  );
};

export default ModalDescription;
