import { Table } from '@profitowi/component-library';
import React from 'react';

export const exampleTexts = (
  <Table
    columns={[
      {
        Header: 'Przykłady podpowiedzi pola Opis',
        accessor: 'example',
        Cell: ({ cell }) => <div className="whitespace-pre-line my-3">{cell.value}</div>,
      },
    ]}
    data={[
      {
        example:
          'PROFITOWI to ludzie, ja jestem Michał Staszewski \n\ni..... do spotkań z moimi Klientami \
            zawsze podchodzę z całym zasobem ciekawości.  Różnorodność osób, sytuacji i potrzeb udowadnia mi \
            codziennie, że nie ma jednego produktu dobrego dla każdego. Wyszukanie tego najlepszego dla mojego \
            rozmówcy to jest to co daje mi satysfakcję w pracy. \n\nPoza spotkaniami z Klientami moją pasja jest \
            czytanie książek( uwielbiam kryminały) i górskie wędrówki.',
      },
      {
        example:
          'PROFITOWI to ludzie, ja jestem Michał Staszewski \n\ni... w ubezpieczeniach najbardziej \
            znam się na produktach inwestycyjnych. Emerytura, oszczędzanie, pomnażanie już zgromadzonego \
            kapitału? Idealny adres. Prywatnie jestem tatą dwójki dzieci, a od niedawna dziadkiem. Uwielbiam \
            gotować i poznawać nowe smaki podróżując po świecie.',
      },
      {
        example:
          'PROFITOWI to ludzie, ja jestem Michalina  Staszewska \n\ni...ubezpieczenia to moja pasja. \
            Ukończyłam Wyższą szkołę Ubezpieczeń w Warszawie, ponieważ od zawsze wiedziałam czym będę się \
            zajmować. 20 letnie doświadczenie powoduje, że nie ma dla mnie rzeczy niemożliwych, a \
            skomplikowane sytuacje tylko bardziej motywują mnie do szukania i analizowania  rozwiązań \
            dostępnych na rynku. \n\nPrywatnie moja pasją jest woda, uwielbiam pływać, nurkować, żeglować, \
            na wodzie spędzam każdą wolną chwilę.',
      },
    ]}
  />
);

export default exampleTexts;
