import { Icon } from '@profitowi/component-library';
import clsx from 'clsx';
import React, { Fragment, ReactNode, useEffect, useMemo } from 'react';
import {
  Column,
  Row,
  SortingRule,
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

type Props = {
  className?: string;
  columns: Column<any>[];
  data: any[];
  isLoading?: boolean;
  pagination?: {
    perPage: number;
    currentPage: number;
    setPage: (page: number) => void;
    setPerPage: (perPage: number) => void;
  };
  isCustomRowStyle?: boolean;
  sortBy?: Array<SortingRule<any>>;
  totalPages?: number;
  isSelectable?: boolean;
  manualSortBy?: boolean;
  totalElementsMessage?: string;
  isExpandable?: boolean;
  rowKey?: number;
  autoResetExpanded?: boolean;
  onSelectionChange?: (selectedRows: any[], rowKey?: number) => void;
  onSortBy?: (rules: Array<SortingRule<any>>) => void;
  customRowStyleCallback?: (value: any) => string;
  renderRowSubComponent?: ({ row }: { row: Row<any> }) => ReactNode;
  getRowId?: (originalRow: any, index: number, parent?: Row<any>) => string;
};

const Table = ({
  className,
  columns,
  getRowId,
  data,
  isLoading = false,
  isExpandable = false,
  pagination,
  sortBy = [],
  isSelectable = false,
  isCustomRowStyle = false,
  rowKey = undefined,
  onSelectionChange = () => null,
  renderRowSubComponent = () => null,
  customRowStyleCallback = () => '',
  onSortBy,
}: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    selectedFlatRows,
    visibleColumns,
  } = useTable(
    {
      data,
      columns,
      getRowId,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageSize: data?.length,
            pageIndex: pagination?.currentPage ? pagination?.currentPage : 0,
          }),
          [state]
        );
      },
      initialState: { sortBy },
      totalPages: 0,
      manualPagination: false,
      manualSortBy: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (isSelectable)
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ]);
    }
  );

  useEffect(() => {
    if (onSortBy) {
      onSortBy(state.sortBy);
    }
  }, [state.sortBy, onSortBy]);

  useEffect(() => {
    onSelectionChange(
      selectedFlatRows.map(({ original }) => original),
      rowKey
    );
  }, [selectedFlatRows.length]);

  return (
    <div className={clsx('space-y-4 max-w-full relative', className)}>
      <div className="max-w-full overflow-y-auto">
        {isLoading && (
          <div className="absolute inset-0 bg-gray-light opacity-70">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
          </div>
        )}

        <table
          className="w-full border-gray max-h-full overflow-auto border-b"
          {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    className="text-secondary text-xs text uppercase px-6 h-12 font-semibold text-left bg-gray-light space-x-1 "
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span className="ml-3"></span>
                    {column.isSorted && (
                      <Icon
                        className="mr-1"
                        name={column.isSortedDesc ? 'caret-down-fill' : 'caret-up-fill'}></Icon>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="max-h-full" {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              const { key, ...props } = row.getRowProps();

              return (
                <Fragment key={key}>
                  <tr
                    className={clsx(
                      'border-t',
                      !isCustomRowStyle && 'bg-gray bg-opacity-10',
                      isCustomRowStyle && customRowStyleCallback(row.values)
                    )}
                    {...props}>
                    {row.cells.map((cell) => {
                      return (
                        <td className="h-12 text-sm px-6" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>

                  {isExpandable && row.isExpanded && (
                    <tr {...props}>
                      <td className="p-0" colSpan={visibleColumns.length}>
                        <div>{renderRowSubComponent({ row })}</div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Checkbox = (props: any) => (
  <input
    {...props}
    className="focus:outline-none focus:ring-0 focus:ring-offset-0 selected:outline-none border-2 w-5 h-5 text-primary hover:border-light border-gray duration-500"
    type="checkbox"
  />
);

export default Table;
