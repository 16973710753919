import { ClientStatements, CommonPersonalData } from 'types/client';
import http from 'utils/axios';

export type CodePayload = {
  clientData:{
    personalData: Omit<CommonPersonalData, 'regon' | 'nip' | 'name'> &
    Required<Pick<CommonPersonalData, 'id'>>;
    statements: ClientStatements[];
  }
  aclientId?: number;
};
type CommunicationType = 'SMS' | 'EMAIL';
type ConfirmationType = 'CODE' | 'LINK';

export const postVerification = (
  agentId: number,
  clientId: number,
  communicationType: CommunicationType,
  confirmationType: ConfirmationType,
  payload: CodePayload
) =>
  http.post(
    `/agents/${agentId}/clients/${clientId}/verification/send/${communicationType}/${confirmationType}`,
    payload
  );

export const validateCode = async (
  agentId: number,
  clientId: number,
  verificationCode: string,
  payload: CodePayload
) => {
  const { data } = await http.post<boolean>(
    `/agents/${agentId}/clients/${clientId}/verification/code/${verificationCode}/validate`,
    payload
  );

  return data;
};

export const getPolling = async (agentId: number, clientId: number, code: string) => {
  const { data } = await http.get<boolean>(
    `/agents/${agentId}/clients/${clientId}/savedInCrm?confirmationId=${code}`
  );

  return data;
};

export const getLinkConfirmation = async (code: string) => {
  const { data } = await http.post<boolean>(`/statements/confirm?code=${code}`);

  return data;
};
