import { Button, Icon, Modal } from '@profitowi/component-library';
import clsx from 'clsx';
import React, { useState } from 'react';

import exampleTexts from './exampleTexts';

type Props = { className?: string };

const Examples = ({ className }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={clsx(className)}>
      <Button size="sm" variant="light" onPress={() => setModalOpen(true)}>
        <div className="-my-1 -mx-4">
          <Icon name="info-circle" className="text-xl" />
        </div>
      </Button>
      <Modal
        visible={modalOpen}
        title=""
        setVisible={setModalOpen}
        className="flex flex-col items-center">
        {exampleTexts}
        <Button onPress={() => setModalOpen(false)}>Zamknij</Button>
      </Modal>
    </div>
  );
};

export default Examples;
