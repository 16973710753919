import { image } from 'assets/summaryPdfIcons/image';

import BottomNavbar from '../components/BottomNavbar';
import Header from '../components/Header';

const styles = {
  page: {
    height: '420px',
    width: '100%',
    display: 'table',
  },
  body: {
    heigth: '100%',
    width: '80%',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  column: {
    width: '20%',
    marginBottom: 'auto 0',
    verticalAlign: 'middle',
  },
  middle: {
    height: '100%',
    width: '60%',
    marginLeft: '10%',
    marginRight: '10%',
  },
  row: {
    display: 'block',
    height: '100%',
  },
  header: {
    display: 'block',
    fontFamily: 'Sora',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '50px',
    lineHeight: '56px',
    color: '#011983',
  },
  content: {
    display: 'block',
    fontFamily: 'Sora',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '30px',
    color: '#19103B',
  },
  image: {
    top: '50px',
    left: '22.5%',
  },
  circle: {
    backgroundColor: '#00E5C9',
    borderRadius: '9999px',
    marginRight: '-0.5rem',
    width: ' 13rem',
    height: '13rem',
    top: '50px',
    left: '15%',
  },
  textArea: {
    display: 'block',
    width: '40%',
    padding: '20px',
    fontFamily: 'Sora',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '30px',
    color: '#19103B',
    background: '#fff',
    top: 0,
    right: 0,
  },
};

const FourthPage = () => {
  return (
    <div style={styles.page}>
      <Header text="Czy można nam ufać ?" />
      <div style={styles.body}>
        <table style={{ height: '100%', width: '100%' }}>
          <th style={styles.column}>
            <div style={styles.row}>
              <div style={{ ...styles.header, whiteSpace: 'nowrap' }}>72</div>
              <div style={styles.content}>Banki i towarzystwa ubezpieczeniowe w jednym miejscu</div>
            </div>
            <div style={styles.row}>
              <div style={{ ...styles.header, whiteSpace: 'nowrap' }}>300</div>
              <div style={styles.content}>Doświadczonych doradców</div>
            </div>
            <div style={styles.row}>
              <div style={{ ...styles.header, whiteSpace: 'nowrap' }}>3+1</div>
              <div style={styles.content}>Przedstawiamy 3 najlepsze oferty z 1 rekomendowaną</div>
            </div>
          </th>

          <th style={{ ...styles.middle, position: 'relative', boxSizing: 'border-box' }}>
            <div style={{ ...styles.circle, position: 'absolute' }}></div>
            <div style={{ ...styles.image, position: 'absolute' }}>
              <img src={`data:image/png;base64,${image()}`} alt="" style={{ width: '300px' }} />
            </div>
            <div style={{ ...styles.textArea, position: 'absolute', boxSizing: 'border-box' }}>
              Eksperci w obszarze ubezpieczeń, kredytów i oszczędzania
            </div>
          </th>

          <th style={styles.column}>
            <div style={styles.row}>
              <div style={{ ...styles.header, whiteSpace: 'nowrap' }}>40 000+</div>
              <div style={styles.content}>Klientów rocznie korzysta z naszego wsparcia</div>
            </div>
            <div style={styles.row}>
              <div style={{ ...styles.header, whiteSpace: 'nowrap' }}>10+</div>
              <div style={styles.content}>lat doświadczenia nazych ekspertów</div>
            </div>
          </th>
        </table>
      </div>
      <BottomNavbar />
    </div>
  );
};

export default FourthPage;
