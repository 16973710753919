import { AssessmentDto, AssessmentPayload } from 'types/assessment';
import http from 'utils/axios';

type Payload = {
  agentId: number;
  payload?: AssessmentPayload;
  assessmentType: 'SIMPLE' | 'FULL';
};

export const createAssessment = async ({ payload, agentId, assessmentType }: Payload) => {
  const { data } = await http.post<AssessmentDto>(
    `/agents/${agentId}/assessments?assessmentType=${assessmentType}`,
    payload
  );

  return data;
};

export const cancelAssessment = async (assessmentId: number, agentId: number) => {
  const { data } = await http.post(`/agents/${agentId}/assessments/${assessmentId}/cancel`);

  return data;
};

export const getAssessment = async (assessmentId: number, agentId: number) => {
  const { data } = await http.get(`/agents/${agentId}/assessments/${assessmentId}`);

  return data;
};

export const validateAssessmentStep = async (
  agentId: number,
  assessmentId: number,
  stepType?: string
) => {
  const { data } = await http.get(`/agents/${agentId}/assessments/${assessmentId}/validate`, {
    params: {
      stepType,
    },
  });

  return data;
};

export const redoAssessment = async (assessmentId: number, agentId: number) => {
  const { data } = await http.get(`/agents/${agentId}/assessments/${assessmentId}/redo`);

  return data;
};
