const columns: { [key: string]: string[] } = {
  CHILD_CHILD_3_a: ['leczenie', 'choroba'],
  CHILD_CHILD_3_b: ['leczenie', 'wypadek'],
  ACCIDENT_ACCIDENT_3_a_1: ['leczenie', 'choroba'],
  ACCIDENT_ACCIDENT_3_a_2: ['pokrycie', 'choroba'],
  ACCIDENT_ACCIDENT_3_b_1: ['leczenie', 'wypadek'],
  ACCIDENT_ACCIDENT_3_b_2: ['pokrycie', 'wypadek'],
};

const headers = (code: string) => {
  const pair = columns[code];
  if (!pair) return ['', ''];
  const labelHeader =
    pair[0] == 'leczenie' ? 'Leczenie i powrót do zdrowia' : 'Pokrycie utraconych dochodów';
  const valueHeader = pair[1] == 'choroba' ? 'Poważna choroba' : 'Wypadek';

  return [labelHeader, valueHeader];
};

const initialData = (code: string) => {
  const pair = columns[code];
  if (!pair) return [];
  const type = pair[0];
  if (type == 'pokrycie') {
    return [
      {
        label: 'wartość miesięcznych dochodów do pokrycia',
      },
      {
        label: 'na ile miesięcy chcesz zabezpieczyć dochód?',
      }
    ];
  }
  
  return [
    {
      label: 'diagnostyka',
    },
    {
      label: 'lekarze specjaliści',
    },
    {
      label: 'nierefundowane leki',
    },
    {
      label: 'rehabilitacja / protezy',
    },
    {
      label: 'koszty opieki i dojazdów',
    },
  ];
};

export { columns, initialData, headers };
