export enum ClientType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

type CommonClient = {
  crmClientId?: string;
  statements?: ClientStatements[];
};

export type CommonPersonalData = {
  city: string | null;
  clientType: ClientType;
  email: string;
  firstName: string;
  flatNo: string | null;
  houseNo: string | null;
  lastName: string;
  name?: string;
  nip?: string;
  phone: string;
  regon?: string;
  street: string | null;
  zipCode: string | null;
  id: number;
  pesel?: string;
  isSpBusiness?: boolean;
};

export type IndividualClient = CommonClient & {
  clientType: 'INDIVIDUAL';
  personalData: CommonPersonalData & {
    id: number;
    pesel?: string;
  };
};
export type CompanyClient = CommonClient & {
  clientType: 'COMPANY';
  personalData: CommonPersonalData & {
    name: string;
    id?: number;
  };
};

export type Client = {
  clientType: ClientType;
  crmClientId: string;
  personalData: CommonPersonalData;
  statements: ClientStatements[];
};

export type Clients = {
  crmClientId: string;
  name: string;
  clientType: string;
}[];

export type ClientInfo = {
  clientId: number;
  crmClientId: string;
  clientType: string;
};

export type MarketingConsentsPayload = {
  data: {
    clientData: {
      personalData: Omit<CommonPersonalData, 'id' | 'name'> & { id?: number };
      statements?: ClientStatements[];
    };
    verificationCode: string;
  };
  agentId: number;
};

export type ClientStatements = {
  statementId: number;
  consent: boolean;
  state?: 'CONFIRMED' | 'NOT_CONFIRMED';
  astatementId?: number;
};

export type ClientMarketingConsents = {
  personalData: Omit<CommonPersonalData, 'regon' | 'nip' | 'name'>;
  statements: string[];
  verificationCode: string;
};

export type IndividualClientFormValues = Omit<IndividualClient, 'clientType' | 'statements'> & {
  statements: string[];
  verificationCode: string;
};
