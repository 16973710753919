import React, { CSSProperties } from 'react';

const dotStyle: CSSProperties = {
  width: '12px',
  height: '12px',
  backgroundColor: '#E1E1E1',
  borderRadius: '50%',
  display: 'inline-block',
  position: 'relative',
};
const lineStyle: CSSProperties = {
  width: '40px',
  height: '3px',
  backgroundColor: '#E1E1E1',
  display: 'inline-block',
  position: 'relative',
  bottom: 4,
};
const levelStyle: CSSProperties = {
  fontFamily: 'Sora',
  fontSize: '7px',
  fontWeight: 600,
  textTransform: 'uppercase',
  position: 'absolute',
  textAlign: 'center',
  top: 20,
  left: -10,
  right: -10,
};

const fillStyle: CSSProperties = {
  backgroundColor: '#011983',
};

type Props = {
  priority: number;
  priorityLevels?: string[];
};

const defaultPriorityLevels = ['nieważne', 'mniej ważne', 'ważne', 'bardzo ważne'];

const PriorityDots = ({ priority, priorityLevels = defaultPriorityLevels }: Props) => {
  return (
    <span style={{ float: 'right' }}>
      <div>
        {priorityLevels.map((level, index) => (
          <React.Fragment key={index}>
            <span style={{ ...dotStyle, ...(priority >= index && fillStyle) }}>
              <span style={levelStyle}>{level}</span>
            </span>
            {!(index === priorityLevels.length - 1) && (
              <span style={{ ...lineStyle, ...(priority > index && fillStyle) }}></span>
            )}
          </React.Fragment>
        ))}
      </div>
    </span>
  );
};

export default PriorityDots;
