import { Box, Header, Loader } from '@profitowi/component-library';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import { PRODUCTS } from 'constants/queries/products';
import { deleteDocumentAttachment, getDocumentsIda } from 'services/documents';
import { DocumentsIda } from 'types/documents';

import ConfirmationModal from '../../../../components/ConfimMutationModal/ConfimMutationModal';
import Table from '../../../../components/Table/table';
import Documents from '../Documents/DocumentsTable';
import FileForm from '../FileForm/FileForm';
import { columns } from './columns';

type CustomRow = {
  row: Row<DocumentsIda>;
};

const ProductIda = () => {
  const [selectedRow, setSelectedRow] = useState<number>();
  const navigate = useNavigate();

  const {
    data: productsIda,
    isFetching: productsIdaIsLoading,
    isError: productsIdaIsError,
    error: productsIdaError,
    refetch,
  } = useQuery([PRODUCTS.PRODUCT_IDA], () => getDocumentsIda(), {
    keepPreviousData: true,
  });

  const { mutate: mutateDeleteProductAttachment, isLoading } = useMutation(
    () => deleteDocumentAttachment(selectedRow),
    {
      onSuccess: () => {
        refetch();
        navigate(-1);
      },
    }
  );

  const onDeleteDocument = (documentId: number) => {
    setSelectedRow(documentId);
    navigate('confirmIda');
  };

  return (
    <>
      <Box className="space-y-6">
        <Header as="h4" size="lg" weight="semibold">
          IDA
        </Header>
        <Box.FullWidth>
          {productsIda && (
            <Table
              data={productsIda}
              columns={columns}
              isLoading={productsIdaIsLoading || isLoading}
              isExpandable
              renderRowSubComponent={({
                row: {
                  original: { attachmentDataList, productId },
                },
              }: CustomRow) => {
                if (!attachmentDataList.length) {
                  return undefined;
                }

                return (
                  <div className="pl-20">
                    <Documents
                      attachmentDataList={attachmentDataList}
                      productId={productId}
                      isProductIda
                      onDeleteDocument={onDeleteDocument}
                    />
                  </div>
                );
              }}
            />
          )}

          {productsIdaIsError && <ErrorMessages error={productsIdaError} />}
          {(productsIdaIsLoading || isLoading) && <Loader className="h-12 w-12" />}
        </Box.FullWidth>
      </Box>

      <Routes>
        <Route path="addIda" element={<FileForm refetchProducts={refetch} isProductIda />} />
        <Route
          path="confirmIda"
          element={
            <ConfirmationModal
              confirmMessage="Czy na pewno chcesz usunąć wybrane elementy ?"
              mutate={() => mutateDeleteProductAttachment()}
            />
          }
        />
      </Routes>
    </>
  );
};

export default ProductIda;
