import { AgentBase } from 'types/agent';
import http from 'utils/axios';

export const authorize = async () => {
  const { data } = await http.get<string>(`/authorize/link`);

  return data;
};

export const getAuthToken = async (code: string | null) => {
  const { data } = await http.post<string>(`/token/${code}`);

  return data;
};

export const getAuthorizedUser = async () => {
  const { data } = await http.get<AgentBase>('/authorize/user');

  return data;
};
