import { Tab } from '@headlessui/react';

import ClientAreasForms from 'components/ClientAreasForms';
import ClientNavigation from 'components/ClientNavigation';
import PageContent from 'components/PageContent';
import { useAssessmentStore } from 'hooks/useStore';

export default function FormsMain() {
  const clients = useAssessmentStore((state) => state.assessment.clients || []);

  return (
    <PageContent title="Twoje potrzeby">
      <div>
        <ClientNavigation clients={clients}>
          {clients.map((client) => (
            <Tab.Panel key={client.aclientId}>
              <ClientAreasForms client={client} />
            </Tab.Panel>
          ))}
        </ClientNavigation>
      </div>
    </PageContent>
  );
}
