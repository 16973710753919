import { Alert, Button, Text } from '@profitowi/component-library';
import React, { ReactNode } from 'react';
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore } from 'hooks/useStore';

const FallbackComponent = ({ error, resetErrorBoundary }: FallbackProps) => {
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId);
  const agentId = useAgentStore((state) => state.agent.agentId);

  return (
    <Alert className="space-y-6" type="error">
      <Text size="xl" weight="bold">
        Wystąpił nieoczekiwany wyjątek. Skontaktuj się z administratorem.
      </Text>

      <div className="space-y-2">
        <Text>
          <strong>Id analizy:</strong> {assessmentId}
        </Text>
        <Text>
          <strong>Id agenta:</strong> {agentId}
        </Text>
        <Text>
          <strong>Wyjątek:</strong> {error.message}
        </Text>
        <Text>
          <strong>Stacktrace:</strong>
          <pre>{error.stack}</pre>
        </Text>
      </div>

      <Button onPress={resetErrorBoundary} variant="outline-primary">
        Spróbuj ponownie
      </Button>
    </Alert>
  );
};

type Props = {
  children: ReactNode;
};

const ErrorBoundary = ({ children }: Props) => (
  <ReactErrorBoundary FallbackComponent={FallbackComponent}>{children}</ReactErrorBoundary>
);

export default ErrorBoundary;
