import * as yup from 'yup';

const alphabeticRegex = /^[\p{L}]+$/u;
const phoneRegex = /^((?:48\d{9})|((?!48\d)\d{9,16}))$/;

const spBusinessData = yup.object().shape({
  name: yup.string().isValidAlphabeticNumberSpecialSigns().default(''),
  nip: yup.string().isValidNip().default(''),
  regon: yup.string().isValidRegon().default(''),
});

export const personalData = yup.object().shape(
  {
    id: yup.number().default(-1),
    name: yup
      .string()
      .isValidAlphabeticNumberSpecialSigns()
      .required('Nazwa firmy jest wymagane')
      .default(''),
    nip: yup
      .string()
      .when('regon', {
        is: (value: string) => !value,
        then: (schema) => schema.required('Jedno z pól NIP lub REGON jest wymagane'),
      })
      .isValidNip()
      .default(''),
    regon: yup
      .string()
      .when('nip', {
        is: (value: string) => !value,
        then: (schema) => schema.required('Jedno z pól NIP lub REGON jest wymagane'),
      })
      .isValidRegon()
      .default(''),
    firstName: yup
      .string()
      .isValidAlphabeticSpecialSigns()
      .required('Imię jest wymagane')
      .max(255, 'Przekroczono dozwoloną ilość znaków')
      .default(''),
    lastName: yup
      .string()
      .isValidAlphabeticSpecialSigns()
      .required('Nazwisko  jest wymagane')
      .default(''),
    phone: yup
      .string()
      .matches(phoneRegex, 'Pole powinno zawierać same cyfry')
      .required('Numer kontaktowy jest wymagany')
      .default(''),
    email: yup
      .string()
      .email('Niepoprawny format adresu e-mail')
      .required('E-mail jest wymagany')
      .default(''),
    street: yup.string().isValidAlphabeticNumberSpecialSigns().default(''),
    houseNo: yup.string().isValidAlphabeticNumberSpecialSigns().default(''),
    flatNo: yup.string().isValidAlphabeticNumberSpecialSigns().default(''),
    zipCode: yup.string().default(''),
    city: yup.string().isValidAlphabeticNumberSpecialSigns().default(''),
    clientType: yup
      .string()
      .matches(alphabeticRegex, 'Pole powinno zawierać tylko znaki alfabetyczne')
      .required('Imię jest wymagane')
      .max(255, 'Przekroczono dozwoloną ilość znaków')
      .default(''),
  },
  [['regon', 'nip']]
);

export const validationSchema = (isSpBusiness: boolean = false) =>
  isSpBusiness
    ? yup.object({
        //Do not remove "as any". It serves the purpose of muting an error occurring from TS being unable to determine the correct type
        personalData: personalData.shape(spBusinessData as any),
      })
    : yup.object({
        personalData: personalData,
      });
