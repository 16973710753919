import { Tab } from '@headlessui/react';
import { Alert, Box, Button, InputField, Loader, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import ClientNavigation from 'components/ClientNavigation';
import PageContent from 'components/PageContent';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore } from 'hooks/useStore';
import { getRecommendations, postRecommendations } from 'services/recommendations';
import { BasicClientData } from 'types/assessment';
import { RecommendationList } from 'types/recommendation';

import Tile from './components/Tile';

type VerificationParams = {
  agentId: number;
  assessmentId: number;
  recommendationId: number;
  isSelected: boolean;
  aClientId: number;
};

function RecommendedProducts() {
  const clients = useAssessmentStore((state) => state.assessment.clients || []);

  return (
    <PageContent title="Rekomendowane produkty">
      <ClientNavigation clients={clients}>
        {clients.map((client) => (
          <Tab.Panel key={client.clientId}>
            <ClientRecommendedProducts client={client} />
          </Tab.Panel>
        ))}
      </ClientNavigation>
    </PageContent>
  );
}

type ClientRecommendedProductsProps = {
  client: BasicClientData;
};

const ClientRecommendedProducts = ({ client }: ClientRecommendedProductsProps) => {
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId || NaN);
  const agentId = useAgentStore((state) => state.agent.agentId);
  const [searchParam, setSearchParam] = useState<string | undefined>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const {
    data: recommendationsList,
    error,
    isError,
    isLoading,
    isFetched,
    refetch,
  } = useQuery<RecommendationList[], AxiosError>(
    ['recommendations', assessmentId, client.aclientId, agentId, searchParam],
    () => getRecommendations(assessmentId, client.aclientId, agentId, searchParam),
    {
      select: (recommendations) => [...recommendations].sort((a, z) => a.orderNo - z.orderNo),
      onSuccess: () => {
        setIsLoadingData(false);
      },
    }
  );

  const { mutate } = useMutation<AxiosResponse, AxiosError, VerificationParams>(
    ({ assessmentId, agentId, recommendationId, isSelected, aClientId }) =>
      postRecommendations(assessmentId, recommendationId, isSelected, aClientId, agentId),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const onChange = (recommendationId: number, isSelected: boolean) => {
    mutate({
      aClientId: client.aclientId,
      agentId: agentId,
      assessmentId: assessmentId,
      isSelected: isSelected,
      recommendationId: recommendationId,
    });
  };

  function onSubmit(values: { searchParam: string }) {
    const { searchParam } = values;
    setSearchParam(searchParam);
  }

  useEffect(() => {
    setIsLoadingData(true);
  }, [searchParam]);

  return (
    <>
      {isError && <Alert type="error">{error?.message}</Alert>}
      <Formik initialValues={{ searchParam: '' }} onSubmit={onSubmit}>
        <Form className="flex gap-4 mb-4 w-full">
          <InputField
            label="Wprowadź nazwę"
            className="bg-white border-gray !w-96"
            name="searchParam"
            value={searchParam}
          />

          <Button className="" size="sm" type="submit">
            Szukaj
          </Button>
          <Button
            className=""
            size="sm"
            type="reset"
            onPress={() => {
              setSearchParam(undefined);
            }}>
            Wyczyść
          </Button>
        </Form>
      </Formik>

      {(isLoading || isLoadingData) && (
        <div className="flex justify-center w-full">
          <Loader className="h-12 w-12 m-3" />
        </div>
      )}

      {isFetched &&
        !isLoadingData &&
        (recommendationsList?.length ? (
          recommendationsList
            ?.sort((a, b) => a.orderNo - b.orderNo)
            .map((form) => (
              <div className="mb-8" key={form.orderNo}>
                <Box>
                  <Text weight="bold" size="lg">
                    {form.formName}
                  </Text>
                </Box>
                {form.recommendations
                  ?.sort((a, b) => a.orderNo - b.orderNo)
                  .map((product, index) => (
                    <Tile
                      key={product.name + '_' + index}
                      selected={product.selected}
                      text={product.name}
                      onChange={onChange}
                      id={product.arecommendationId ? product.arecommendationId : index}></Tile>
                  ))}
              </div>
            ))
        ) : (
          <Alert type="info">Brak rekomendowanych produktów</Alert>
        ))}
    </>
  );
};

export default RecommendedProducts;
