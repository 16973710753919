import calendar from 'assets/summaryPdfIcons/calendar.png';

import { SummaryClient } from 'types/summary';

import Header from '../components/Header';
import SectionTitle from '../components/SectionTitle';

const style = {
  page: {
    backgroundColor: '#FFFFFF',
    color: '#19103B',
    height: '100%',
    fontFamily: 'Sora',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'initial',
  },
  content: {
    main: { width: '70%', margin: 'auto' },
  },
  needs: {
    piority: {
      color: '#FFF',
      fontSize: '8px',
      backgroundColor: '#707070',
      padding: '2px 5px',
      borderRadius: '2px',
      width: '10%',
      marginBottom: '10px',
    },
    detail: { fontSize: '12px', marginRight: '15px', display: 'block' },
    date: { fontSize: '12px' },
    calendar: { display: 'inline-block', width: '11px', margin: '0px 11px 3px 11px' },
  },
  product: {
    fontWeight: 600,
  },
  dots: { right: -35, bottom: 120, height: '60px' },
};

type Props = {
  clients: SummaryClient[];
};

const FormSummaries = ({ clients }: Props) => {
  const filterFormsByAreaType = (forms: any[], areaName: string) =>
    forms.filter(({ areaType }) => areaType.toUpperCase().includes(areaName.toUpperCase()));

  const getPriority = (priority: string) => {
    switch (priority) {
      case 'LOWEST':
        return 'Nieważne';
      case 'LOW':
        return 'Mniej ważne';
      case 'HIGH':
        return 'Ważne';
      case 'HIGHEST':
        return 'Bardzo ważne';
      default:
        return 0;
    }
  };

  return (
    <div style={{ ...style.page, position: 'relative', pageBreakAfter: 'always' }}>
      <div style={style.content.main}>
        {clients.length > 0 &&
          clients.map((client) => (
            <>
              {client?.areas.length > 0 && (
                <>
                  <Header text="Podsumowanie">
                    <SectionTitle
                      text={`Twoje potrzeby ${
                        client?.fullName.length !== 0 ? client.fullName : ''
                      }`}
                    />
                  </Header>
                  <div style={{ pageBreakAfter: 'always' }}>
                    {client?.areas.map(({ areaType, meeting, name, priority }) => (
                      <div style={{ marginBottom: '45px', pageBreakInside: 'avoid' }}>
                        <div style={style.needs.piority}>{getPriority(priority)}</div>
                        <div style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 600 }}>{name}</span>
                          {meeting && (
                            <span style={{ ...style.needs.date, float: 'right' }}>
                              Termin spotkania:
                              <img style={style.needs.calendar} src={calendar} alt="" />{' '}
                              {meeting && meeting.slice(0, 10)}
                            </span>
                          )}
                        </div>
                        {filterFormsByAreaType(client.forms, areaType).map(
                          (form) =>
                            form?.details &&
                            form.details.map(
                              ({ summaries, subjectName }: any) =>
                                summaries &&
                                summaries.map(({ summaryName, value }: any) => (
                                  <span style={style.needs.detail}>
                                    {summaryName}: {value} <b></b> {subjectName}
                                  </span>
                                ))
                            )
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default FormSummaries;
