import { AriaTextFieldOptions } from '@react-aria/textfield';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import PhonePrefixInput from '../PhonePrefixInput/PhonePrefixInput';

export type PhonePrefixInputFieldProps = AriaTextFieldOptions<'input'> & {
  name: string;
  className?: string;
};

const PhonePrefixInputField = (props: PhonePrefixInputFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const { name } = props;
  const [field, meta, utils] = useField(name);

  const { error, touched } = meta;
  const { onChange } = field;

  return (
    <PhonePrefixInput
      {...props}
      {...field}
      onInput={onChange}
      errorMessage={error}
      onBlur={() => utils.setTouched(true)}
      validationState={error && touched ? 'invalid' : 'valid'}
      phoneValue={field.value}
      setPhoneValue={(value: string) => setFieldValue(field.name, value)}
    />
  );
};

export default PhonePrefixInputField;
