import { Alert, Box, CheckboxField, InputField, Loader } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import MarketingConsents from 'components/MarketingConsents';
import ModalActions from 'components/ModalActions';
import { STATEMENT } from 'constants/queries/statements';
import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import { ClientVerificationData, updateClient } from 'services/client';
import { getStatements } from 'services/statements';
import { ASSESSMENT_TYPE, StateType } from 'types/assessment';
import { ClientType, IndividualClient, IndividualClientFormValues } from 'types/client';
import { Statements } from 'types/statements';

import PhonePrefixInputField from '../../../../components/PhonePrefixInputField/PhonePrefixInputField';
import AutoSave from '../AutoSave';
import { personalDataSchema, validationSchema } from './validation';

type Props = {
  aclientId: number;
  clientId: number;
  client: IndividualClient;
};

const IndividualClientForm = ({ aclientId, clientId, client }: Props) => {
  const agentId = useAgentStore((state) => state.agent.agentId);
  const [isDisabled, setIsDisabled] = useState(true);
  const [fetching, setFetching] = useState(false);
  const { validateSteps } = useStepValidation();

  const startingClientType = useAssessmentStore((state) => state.assessment.startingClientType);

  const { addNotification, removeNotification } = useNotificationStore(
    ({ addNotification, removeNotification }) => ({
      addNotification,
      removeNotification,
    })
  );

  const { assessmentId, assessmentState } = useAssessmentStore((state) => ({
    assessmentId: state.assessment.assessmentId || NaN,
    assessmentState: state.assessment.state,
  }));

  const {
    data: statements = [],
    error,
    isError,
    isLoading,
    isSuccess,
  } = useQuery<Statements[], AxiosError>([STATEMENT.STATEMENTS], () => getStatements());

  const { mutate } = useMutation<AxiosResponse, AxiosError, ClientVerificationData>(
    (payload) => updateClient(agentId, assessmentId, payload),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Próba zapisania danych nie powiodła się',
          type: 'error',
        });
      },
      onSuccess: () => {
        addNotification({
          id: 'postArea-autosave',
          title: 'Zapisywanie danych',
          type: 'info',
        });
        removeNotification('postArea-error');
        setIsDisabled(true);
        validateSteps(ASSESSMENT_TYPE.YOUR_DATA);
      },
    }
  );

  const initialValues: IndividualClientFormValues = useMemo(
    () => ({
      personalData: {
        ...personalDataSchema.cast({}),
        ...{
          ...client.personalData,
          phone: (client.personalData.phone.length === 9 ? '48' : '') + client.personalData.phone,
        },
      },
      statements:
        client?.statements?.map(({ statementId, consent }) =>
          consent ? statementId.toString() : ''
        ) || [],
      verificationCode: '',
    }),
    [client]
  );

  const createStatementsPayload = (selectedStatements: string[]) =>
    statements.map(({ statementId }) => ({
      consent: selectedStatements.includes(statementId.toString()),
      statementId,
    }));

  const handleSubmit = (values: IndividualClientFormValues, isAutoSave = false) => {
    const { statements, personalData, verificationCode } = values;
    setIsDisabled(true);
    mutate(
      {
        aclientId,
        clientData: {
          personalData: {
            ...personalData,
            phone: personalData.phone.startsWith('48')
              ? personalData.phone.slice(2, personalData.phone.length)
              : personalData.phone,
            id: clientId,
          },
          statements: createStatementsPayload(statements),
        },
        verificationCode,
      },
      {
        onSuccess: () => {
          if (!isAutoSave) {
            validateSteps(ASSESSMENT_TYPE.YOUR_DATA);
            addNotification({
              id: 'postArea-success',
              title: 'Zgody zostały zebrane prawidłowo',
              type: 'success',
            });
          }
        },
      }
    );
  };
  const isReadOnly = assessmentState === StateType.FINISHED || !!client?.crmClientId || fetching;
  return (
    <>
      {isError && <Alert type="error">{error?.message}</Alert>}
      {isLoading && <Loader className="h-12 w-12" />}
      {isSuccess && aclientId && (
        <Box className="p-6">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={validationSchema}
            validateOnMount={true}>
            {({ isValid }) => (
              <Form className="space-y-4 w-2/3">
                <AutoSave handleSubmit={handleSubmit} omitKeys={['verificationCode']} />
                <InputField
                  isReadOnly={isReadOnly}
                  name="personalData.firstName"
                  label="Imię"
                  isRequired
                />
                <InputField
                  isReadOnly={isReadOnly}
                  name="personalData.lastName"
                  label="Nazwisko"
                  isRequired
                />
                <PhonePrefixInputField name="personalData.phone" label="Telefon" isRequired />
                <InputField name="personalData.email" label="E-mail" isRequired />
                <InputField isReadOnly={isReadOnly} name="personalData.pesel" label="PESEL" />
                <InputField isReadOnly={isReadOnly} name="personalData.street" label="Ulica" />
                <InputField
                  isReadOnly={isReadOnly}
                  name="personalData.houseNo"
                  label="Numer domu"
                />
                <InputField
                  isReadOnly={isReadOnly}
                  name="personalData.flatNo"
                  label="Numer mieszkania"
                />
                <InputField
                  isReadOnly={isReadOnly}
                  name="personalData.zipCode"
                  label="Kod pocztowy"
                />
                <InputField isReadOnly={isReadOnly} name="personalData.city" label="Miejscowość" />
                {startingClientType === ClientType.INDIVIDUAL && (
                  <CheckboxField name="personalData.isSpBusiness">Prowadzę JDG</CheckboxField>
                )}
                <MarketingConsents
                  aclientId={aclientId}
                  originalStatements={client?.statements}
                  statements={statements}
                  setIsDisabled={setIsDisabled}
                  setFetching={setFetching}
                />
                <ModalActions isConfirmDisabled={isDisabled || !isValid} />
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </>
  );
};

export default IndividualClientForm;
