import { Box, Button, Icon, Text } from '@profitowi/component-library';
import dotsImg from 'assets/dots.svg';
import { useNavigate } from 'react-router-dom';

function FinishAnalysis() {
  let navigate = useNavigate();

  const onPress = () => {
    navigate('/');
  };
  return (
    <Box className="w-screen h-screen absolute left-0 top-0 flex flex-col justify-center">
      <div className="flex w-full justify-center items-center mb-32">
        <img src={dotsImg} alt="" />
        <Text weight="bold" size="2xl" className="mx-8 text-primary">
          Zakończyłeś analizę!
        </Text>
        <img src={dotsImg} alt="" />
      </div>
      <div className="flex flex-col items-center justify-center">
        <Text weight="bold" size="xl" className=" text-primary">
          Podsumowanie zostało wysłane
        </Text>
        <Text weight="bold" size="base" className="mt-2 mb-12">
          Do Klienta został wysłany PDF z podsumowaniem i rekomendacjami produktów
        </Text>
        <Button variant="primary" onPress={onPress}>
          Zakończ <Icon name="arrow-right" className="ml-2" />
        </Button>
      </div>
    </Box>
  );
}

export default FinishAnalysis;
