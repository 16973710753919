import { Alert, Loader } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { CLIENT } from 'constants/queries/client';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore } from 'hooks/useStore';
import { getClientData } from 'services/client';
import { BasicClientData } from 'types/assessment';
import { CompanyClient, IndividualClient } from 'types/client';

import CompanyClientForm from './CompanyClientForm';
import IndividualClientForm from './IndividualClientForm';

type Props = {
  client: BasicClientData;
};

const ClientForm = ({ client }: Props) => {
  const agentId = useAgentStore((state) => state.agent.agentId);

  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId || NaN);

  const { data, error, isError, isLoading, isRefetching, isSuccess } = useQuery<
    IndividualClient | CompanyClient,
    AxiosError
  >(
    [CLIENT.INDIVIDUAL_CLIENT, agentId, assessmentId, client.aclientId, client.clientType],
    () => getClientData(agentId, assessmentId, client.aclientId, client.clientType),
    {
      select: removeNullableValues,
    }
  );

  return (
    <>
      {isError && <Alert type="error">{error?.message}</Alert>}
      {(isLoading || isRefetching) && <Loader className="h-12 w-12" />}
      {isSuccess &&
        !isRefetching &&
        ((data?.clientType === 'INDIVIDUAL' && (
          <IndividualClientForm
            clientId={client.clientId}
            aclientId={client.aclientId}
            client={data}
          />
        )) ||
          (data?.clientType === 'COMPANY' && (
            <CompanyClientForm
              clientId={client.clientId}
              aclientId={client.aclientId}
              client={data}
            />
          )))}
    </>
  );
};

const removeNullableValues = (data: IndividualClient | CompanyClient) => {
  return {
    ...data,
    personalData: JSON.parse(JSON.stringify(data?.personalData, (_, value) => value ?? '')),
  };
};

export default ClientForm;
