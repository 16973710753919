import { renderToStaticMarkup } from 'react-dom/server';

import { SummaryPdfData } from 'types/summary';

import BottomNavbar from './components/BottomNavbar';
import AgentInfo from './pages/AgentInfo';
import Areas from './pages/Areas';
import FirstPage from './pages/FirstPage';
import FormSummaries from './pages/FormSummaries';
import FourthPage from './pages/FourthPage';
import Recommendation from './pages/Recommendation';
import SecondPage from './pages/SecondPage';
import SummaryAnswers from './pages/SummaryAnswers';
import ThirdPage from './pages/ThirdPage';

const SummaryPdf = (data: SummaryPdfData) => {
  const templates = renderToStaticMarkup(
    <>
      <FirstPage assessmentDate={data.assessmentDate} clientName={data.clients[0].fullName} />
      <SecondPage />
      <ThirdPage />
      <FourthPage />
      <AgentInfo agent={data.agent} assessmentDate={data.assessmentDate} />
      <FormSummaries clients={data.clients} />
      <Recommendation clients={data.clients} />
      <Areas clients={data.clients} />
      <SummaryAnswers clients={data.clients} />
    </>
  );

  const html = `
    <!DOCTYPE html>
    <html lang="en">

      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="APK app" />

        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.4.1/paper.css">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;749;800&display=swap" rel="stylesheet">

        <style>
          @page {
            size: A4 landscape;
            padding-top: 200px;
            margin-bottom: 170px;
      
             @bottom-left {
                 content: element(navbar);
              }

              @top-center {
                content: element(pageHeader);
              }
          }

          @page sheet {
              padding-top: 0px; 
              margin-bottom: 0px;
          }

          .sheet {
            page: sheet
          }

          #pageHeader {    
            width: 100%;
            position: running(pageHeader);
          }

          #navbar{
            position: running(navbar);
          }

 
        </style>
      </head>

      <body class="A4 landscape">
        ${renderToStaticMarkup(<BottomNavbar />)}
        ${templates}
      </body>

    </html>

`;
  return html.replace(/\n/g, '');
};

export default SummaryPdf;
