import { Button, Icon, Text } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { Product } from '../../types/products';

export const columns = (onDeactivateProduct: (productId: number) => void): Column<Product>[] => [
  {
    id: 'expander',
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, toggleAllRowsExpanded }) => {
      return (
        <span
          {...getToggleAllRowsExpandedProps()}
          onClick={() => {
            if (isAllRowsExpanded) {
              toggleAllRowsExpanded(false);
            } else {
              toggleAllRowsExpanded(true);
            }
          }}>
          {isAllRowsExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      );
    },
    Cell: ({ row }: Cell<Product>) =>
      row.original.recConditions?.filter((item) => item !== null).length ? (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      ) : null,
  },
  {
    Header: 'Nazwa produktu',
    accessor: 'name',
    sortType: 'string',
  },
  {
    Header: 'Skrót',
    accessor: 'shortcut',
    sortType: 'string',
  },
  {
    id: 'ida',
    Header: 'IDA',
    Cell: ({
      row: {
        original: { idaProduct },
      },
    }: Cell<Product>) => {
      return <Text>{idaProduct?.name}</Text>;
    },
  },
  {
    id: 'operations',
    Cell: ({
      row: {
        original: { productId, name, shortcut, recConditions, idaProduct },
      },
    }: Cell<Product>) => {
      const navigate = useNavigate();

      return (
        <div className="flex w-min max-w-min min-w-min justify-end whitespace-nowrap">
          <Button
            variant="light"
            className="w-32 whitespace-pre-wrap"
            onPress={() =>
              navigate('editProduct', {
                state: {
                  productId,
                  productName: name,
                  formCode: shortcut,
                  idaProductId: idaProduct?.productId,
                  idaProductShortcut: idaProduct?.shortcut,
                },
              })
            }>
            Edytuj produkt
          </Button>
          <Button
            variant="light"
            className="w-32 whitespace-pre-wrap"
            onPress={() => navigate('addRecommendation', { state: { productId } })}>
            Dodaj rekomendację
          </Button>
          <Button
            variant="light"
            className="w-32 whitespace-pre-wrap"
            isDisabled={!recConditions?.length}
            onPress={() =>
              navigate('copyRecommendation', {
                state: {
                  productId,
                  sourceRecommendationName: name,
                  sourceRecommendationCode: shortcut,
                },
              })
            }>
            Kopiuj rekomendacje
          </Button>
          <Button
            variant="light"
            className="w-32 whitespace-pre-wrap"
            onPress={() => onDeactivateProduct(productId)}>
            Dezaktywuj
          </Button>
        </div>
      );
    },
  },
];
