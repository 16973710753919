export enum FIELD_TYPE {
  FILE = 'FILE',
  CALENDAR = 'CALENDAR',
  RADIOBUTTON = 'RADIOBUTTON',
  CHECKBOX = 'CHECKBOX',
  SLIDER = 'SLIDER',
  TEXT_AREA = 'TEXT_AREA',
  TEXT_FIELD = 'TEXT_FIELD',
  PHONE_INPUT_FIELD = 'PHONE_INPUT_FIELD',
}

export enum ANSWER_GROUP {
  ENUM_G = 'ENUM_G',
  NUMBER_G = 'NUMBER_G',
  TEXT_G = 'TEXT_G',
}

export enum ANSWER_SUBGROUP {
  // ENUM_G group
  ENUM = 'ENUM',
  BOOLEAN_YES_NO_ENUM = 'BOOLEAN_YES_NO_ENUM',
  MULTI_ENUM = 'MULTI_ENUM',
  SLIDE_ENUM = 'SLIDE_ENUM',
  PRIORITY_3_SLIDE_ENUM = 'PRIORITY_3_SLIDE_ENUM',
  // null "group"
  NO_ANSWER = 'NO_ANSWER',
  // NUMBER_G group
  NUMBER = 'NUMBER',
  AMOUNT = 'AMOUNT',
  AMOUNT_CURRENCY = 'AMOUNT_CURRENCY',
  NUMBER_OF_YEAR = 'NUMBER_OF_YEAR',
  YEAR = 'YEAR',
  MONTHS = 'MONTHS',
  DAYS = 'DAYS',
  KILOMETER = 'KILOMETER',
  M_2 = 'M_2',
  PERCENT = 'PERCENT ',
  // TEXT_G group
  PESEL = 'PESEL',
  PHONE = 'PHONE',
  NIP = 'NIP',
  REGON = 'REGON',
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  EMAIL = 'EMAIL',
  ADDRESS = 'ADDRESS',
  ATTACHMENT = 'ATTACHMENT',
  DATE = 'DATE',
}

export enum ASSESSMENT_SUBJECT_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum SUBJECT_TYPE {
  CHILD = 'CHILD',
  CLIENT = 'CLIENT',
  ESTATE = 'ESTATE',
  VEHICLE = 'VEHICLE',
}

export enum AREA_TYPE {
  AUTO = 'AUTO',
  HOME = 'HOME',
  KIDS = 'KIDS',
  ACCIDENT = 'ACCIDENT',
  FINANCE = 'FINANCE',
  RETIREMENT = 'RETIREMENT',
  SAVINGS = 'SAVINGS',
  TRAVEL = 'TRAVEL',
  LIABILITY = 'LIABILITY',
  LOANS = 'LOANS',
  EMPLOYEES = 'EMPLOYEES',
  PROPERTY = 'PROPERTY',
  CONTINUITY = 'CONTINUITY',
  INDUSTRY = 'INDUSTRY',
  INVESTMENT = 'INVESTMENT',
  SHORTEN_COMPANY = 'SHORTEN_COMPANY',
  SHORTEN_PRIVATE = 'SHORTEN_PRIVATE',
}

export enum SLIDE_SUBGROUPS {
  PRIORITY_3_SLIDE_ENUM = 'PRIORITY_3_SLIDE_ENUM',
  SLIDE_ENUM = 'SLIDE_ENUM',
}

export enum CONDITION_TYPE {
  EQUAL = 'EQUAL',
  GREATER = 'GREATER',
  LESS = 'LESS',
  NOT_EQUAL = 'NOT_EQUAL',
}

export type AnswerType = {
  subGroup: string;
  group: string | null;
  fieldType: string | null;
  unit: string | null;
  placeholder: string | null;
};

export type Answer = {
  answerId: number;
  value: string;
  orderNo: number;
};

export type Question = {
  questionId: number;
  content: string;
  info: string[] | null;
  hint: string[] | null;
  answerType: AnswerType;
  conditionQuestionCode: string | null;
  conditionType: string | null;
  conditionValue: string | null;
  required: boolean;
  orderNo: number;
  code: string;
  parentCode: string | null;
  answers: Answer[];
};

export type FormData = {
  formId: number;
  areaType: string;
  version: string;
  name: string;
  code: string;
  comment: string;
  multiSubject: boolean;
  subjectType: string;
  subjects: [
    {
      subjectType: string;
      subjectDescription: string;
      aanswers: AAnswer[];
      aformSubjectId: number;
    }
  ];
  questions: Question[];
};

export type ClientsAFormsInfo = {
  aclientId: number;
  aformId: number;
  expired: boolean;
  formCode: string;
  state: string;
};

export type AFormData = {
  expired: boolean;
  formId: number;
  subjects: Subject[];
  state: string;
  aformId: number;
};

export type AFormSubjectData = {
  subjectType: string;
  subjectDescription: string;
  aanswers: AAnswer[];
  aformSubjectId: number;
};

export type AAnswer = {
  value: string;
  questionCode: string;
  aanswerId: number;
};

export type Subject = {
  subjectType: string;
  subjectDescription: string;
  state: string;
  aformSubjectId: number;
};

export type FileUploadContext = {
  agentId: string | number | undefined;
  assessmentId: string | number | undefined;
  aClientId: string | number | undefined;
  id: string | number | undefined;
  aId: string | number | undefined;
  aSubjectId: string | number | undefined;
};
