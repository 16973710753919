import { AxiosError } from 'axios';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ConfirmationModal from '../../components/ConfimMutationModal/ConfimMutationModal';
import AddRecommendation from './FormModals/AddRecommendation';
import ChangeProductsIda from './FormModals/ChangeProductsIda';
import CopyRecommendation from './FormModals/CopyRecommendation';
import ProductFormModal, { ProductFormModalType } from './FormModals/ProductFormModal';

interface Props {
  refetch: () => void;
  mutateDeleteProductCondition: () => void;
  deletionIsError: boolean;
  deletionError: AxiosError | null;
  mutateDeactivateProductAttachments: () => void;
  deactivationIsError: boolean;
  deactivationError: AxiosError | null;
}

function ProductPageRoutes({
  refetch,
  mutateDeleteProductCondition,
  deletionIsError,
  deletionError,
  mutateDeactivateProductAttachments,
  deactivationIsError,
  deactivationError,
}: Props) {
  return (
    <Routes>
      <Route
        path="addProduct"
        element={
          <ProductFormModal modalType={ProductFormModalType.ADD} refetchProducts={refetch} />
        }
      />
      <Route
        path="editProduct"
        element={
          <ProductFormModal modalType={ProductFormModalType.EDIT} refetchProducts={refetch} />
        }
      />
      <Route path="changeProductsIda" element={<ChangeProductsIda refetchProducts={refetch} />} />
      <Route path="addRecommendation" element={<AddRecommendation refetchProducts={refetch} />} />
      <Route path="copyRecommendation" element={<CopyRecommendation refetchProducts={refetch} />} />
      <Route
        path="deleteRecommendation"
        element={
          <ConfirmationModal
            confirmMessage="Czy na pewno chcesz usunąć tę rekomendację ?"
            mutate={mutateDeleteProductCondition}
            isError={deletionIsError}
            error={deletionError}
          />
        }
      />
      <Route
        path="deactivateProduct"
        element={
          <ConfirmationModal
            confirmMessage="Czy na pewno chcesz dezaktywować produkt ?"
            mutate={mutateDeactivateProductAttachments}
            isError={deactivationIsError}
            error={deactivationError}
          />
        }
      />
    </Routes>
  );
}

export default ProductPageRoutes;
