import '@fontsource/sora';
import 'bootstrap-icons/font/bootstrap-icons.css';
import pl from 'date-fns/locale/pl';
import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'utils/yupMethods';

import App from './App';
import './index.css';
import '@profitowi/component-library/dist/index.css';

registerLocale('pl', pl);
setDefaultLocale('pl');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
