import { Box, InputField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation } from 'react-query';

import FieldRow from 'components/FieldRow';
import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import { ClientVerificationData, updateClient } from 'services/client';
import { ASSESSMENT_TYPE, StateType } from 'types/assessment';
import { CompanyClient } from 'types/client';

import PhonePrefixInputField from '../../../../components/PhonePrefixInputField/PhonePrefixInputField';
import AutoSave from '../AutoSave';
import { personalData, validationSchema } from './validation';

type FormValues = Omit<CompanyClient, 'clientType'>;

type Props = {
  aclientId: number;
  clientId: number;
  client: CompanyClient;
};

const CompanyClientForm = ({ clientId, aclientId, client }: Props) => {
  const agentId = useAgentStore((state) => state.agent.agentId);
  const { validateSteps } = useStepValidation();

  const { assessmentId, assessmentState } = useAssessmentStore((state) => ({
    assessmentId: state.assessment.assessmentId || NaN,
    assessmentState: state.assessment.state,
  }));

  const { addNotification, removeNotification } = useNotificationStore(
    ({ addNotification, removeNotification }) => ({
      addNotification,
      removeNotification,
    })
  );

  const { mutate } = useMutation<AxiosResponse, AxiosError, ClientVerificationData>(
    (payload) => updateClient(agentId, assessmentId, payload),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Wystąpił błąd podczas zapisywania danych',
          type: 'error',
        });
      },
      onSuccess: () => {
        addNotification({
          id: 'postArea-autosave',
          title: 'Zapisywanie danych',
          type: 'info',
        });
        removeNotification('postArea-error');
        validateSteps(ASSESSMENT_TYPE.YOUR_DATA);
      },
    }
  );

  const initialValues = useMemo(
    () => ({
      personalData: {
        ...personalData.cast({}),
        ...({
          ...client.personalData,
          phone: (client.personalData.phone.length === 9 ? '48' : '') + client.personalData.phone,
        } || {}),
      },
    }),
    [client]
  );

  const handleSubmit = (values: FormValues) => {
    const { personalData } = values;

    mutate({
      aclientId,
      clientData: {
        personalData: {
          ...personalData,
          phone: personalData.phone.startsWith('48')
            ? personalData.phone.slice(2, personalData.phone.length)
            : personalData.phone,
          id: clientId,
        },
        statements: [],
      },
    });
  };
  const isReadOnly = assessmentState === StateType.FINISHED || !!client?.crmClientId;

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(client.personalData.isSpBusiness)}>
        <Form className="space-y-8 w-2/3">
          {!isReadOnly && <AutoSave handleSubmit={handleSubmit} />}
          <FieldRow description="Dane firmy:">
            <div className="space-y-4">
              <InputField name="personalData.name" label="Nazwa firmy" isDisabled={isReadOnly} />
              <InputField name="personalData.nip" label="Nip firmy" isDisabled={isReadOnly} />
              <InputField name="personalData.regon" label="Regon firmy" isDisabled={isReadOnly} />
            </div>
          </FieldRow>

          <FieldRow description="Osoba kontaktowa:">
            <div className="space-y-4">
              <InputField name="personalData.firstName" label="Imię" isDisabled={isReadOnly} />
              <InputField name="personalData.lastName" label="Nazwisko" isDisabled={isReadOnly} />
              <PhonePrefixInputField name="personalData.phone" label="Telefon" />
              <InputField name="personalData.email" label="E-mail" />
            </div>
          </FieldRow>

          <FieldRow description="Adres działalności (opcjonalny):">
            <div className="space-y-4">
              <InputField name="personalData.street" label="Ulica" isDisabled={isReadOnly} />
              <InputField name="personalData.houseNo" label="Numer domu" isDisabled={isReadOnly} />
              <InputField
                name="personalData.flatNo"
                label="Numer mieszkania"
                isDisabled={isReadOnly}
              />
              <InputField
                name="personalData.zipCode"
                label="Kod pocztowy"
                isDisabled={isReadOnly}
              />
              <InputField name="personalData.city" label="Miejscowość" isDisabled={isReadOnly} />
            </div>
          </FieldRow>
        </Form>
      </Formik>
    </Box>
  );
};

export default CompanyClientForm;
