import { Alert, Button, Icon, Text } from '@profitowi/component-library';
import avatar from 'assets/avatar.svg';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ModalActions from 'components/ModalActions';
import { useAgentStore } from 'hooks/useAgentStore';
import { getPdfAgentPreview } from 'services/agent';
import { changePhoto } from 'services/file';
import { Agent } from 'types/agent';

type Props = {
  agent: Agent;
};

type Payload = {
  photoName: string | Blob;
  agentId: number;
  payload: FormData;
};

const AgentDetails = ({ agent }: Props) => {
  const navigate = useNavigate();
  const agentId = useAgentStore((state) => state.agent.agentId);
  const [selectedFile, setSelectedFile] = useState<string | Blob>('');
  const [fileName, setFileName] = useState<string>('');
  const [src, setSrc] = useState(`data:image/png;base64, ${agent.photoBase64}`);

  const { refetch: getPdf } = useQuery<any, AxiosError>(
    'pdf',
    () => getPdfAgentPreview(agent.agentId),
    {
      enabled: false,
      onSuccess: (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = window.URL.createObjectURL(blob);
        window.open(link, '_blank');
      },
    }
  );

  const { error, isError, mutate } = useMutation<AxiosResponse, AxiosError, Payload>(
    ({ photoName, agentId, payload }) => changePhoto(agentId, photoName, payload),
    {
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  useEffect(() => {
    if (selectedFile && agentId) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      return mutate({
        photoName: fileName,
        payload: formData,
        agentId: agentId,
      });
    }
  }, [selectedFile]);

  const changeHandler = (event: any) => {
    setFileName(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };

  const submitNewPhoto = () => {
    const plus: HTMLElement | null = document.querySelector('.plusButton');
    const input: HTMLElement | null = document.querySelector('#fileButton');
    plus?.addEventListener('click', () => input?.click());
  };

  return (
    <div className="space-y-8 py-4 px-20">
      {isError && (
        <Alert className="mt-4" type="error">
          {error?.message}
        </Alert>
      )}
      <div className="flex">
        <div className="w-1/5 flex items-center relative">
          <img
            className="w-full h-fit"
            src={src}
            alt="Brak zdjęcia"
            onError={() => {
              setSrc(avatar);
            }}
          />
          <input
            type="file"
            id="fileButton"
            name="file"
            className="hidden"
            onChange={changeHandler}
          />
          <div onClick={submitNewPhoto}>
            <Icon
              name="plus"
              className="plusButton absolute hover:bg-white hover:text-primary hover:border-primary border-2 border-transparent duration-500 right-0 bottom-0 bg-primary text-white text-2xl w-10 h-10 flex items-center justify-center"
            />
          </div>
        </div>
        <div className="flex flex-col ml-10 space-y-4 justify-center">
          <div className="flex space-x-8">
            <div className="space-y-1">
              <Text weight="bold" size="sm" className="text-secondary">
                Imię
              </Text>
              <Text weight="bold" size="base" className="min-h-[1.4rem]">
                {agent.firstName}
              </Text>
            </div>
            <div className="space-y-1">
              <Text weight="bold" size="sm" className="text-secondary">
                Nazwisko
              </Text>
              <Text weight="bold" size="base" className="min-h-[1.4rem]">
                {agent.lastName}
              </Text>
            </div>
          </div>
          <div className="flex space-x-8">
            <div className="flex flex-col space-y-4">
              <div className="space-y-1">
                <Text weight="bold" size="sm" className="text-secondary">
                  Telefon
                </Text>
                <Text weight="bold" size="base" className="min-h-[1.5rem]">
                  {agent.phone}
                </Text>
              </div>
              <div className="space-y-1">
                <Text weight="bold" size="sm" className="text-secondary">
                  Link do KNF
                </Text>
                <a href={agent.knfLink} target="_blank" rel="noopener noreferrer">
                  <Text className="text-blue-800 min-h-[1.4rem]" weight="bold" size="base">
                    {agent.knfLink}
                  </Text>
                </a>
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <div className="space-y-1">
                <Text weight="bold" size="sm" className="text-secondary">
                  E-mail
                </Text>
                <Text weight="bold" size="base" className="min-h-[1.4rem]">
                  {agent.email}
                </Text>
              </div>
              <div className="space-y-1">
                <Text weight="bold" size="sm" className="text-secondary">
                  Link do LinkedIn
                </Text>
                <a href={agent.linkedinLink} target="_blank" rel="noopener noreferrer">
                  <Text className="text-blue-800 min-h-[1.4rem]" weight="bold" size="base">
                    {agent.linkedinLink}
                  </Text>
                </a>
              </div>
            </div>
          </div>
          <div className="flex space-x-8"></div>
        </div>
      </div>

      <div className="flex flex-col space-y-4 !mt-12">
        <Text size="lg" weight="bold">
          Cześć, jestem {agent.name}
        </Text>
        <Text size="base">
          {agent.description?.split('<br>').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Text>
      </div>

      <Button className="" onPress={() => getPdf()}>
        Podgląd PDF
      </Button>

      <div className="flex flex-col space-y-4 !mt-12">
        <Text size="lg" weight="bold">
          Szablon wiadomości z podsumowaniem
        </Text>
        <Text size="base">
          {agent.emailSchema?.split('<br>').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Text>
      </div>

      <ModalActions
        confirmLabel="Edytuj"
        onCancel={() => navigate('/')}
        onConfirm={() => navigate('edit')}
      />
    </div>
  );
};

export default AgentDetails;
