export interface Product {
  name: string;
  orderNo: number;
  productId: number;
  shortcut: string;
  type: string;
  recConditions: any[];
  idaProduct: Product | null;
}

export interface ProductConditions {
  recConditionId: number;
  formCode: string;
  versionMajor: string;
  question: string;
  conditionType: ConditionType;
  conditionValue: string;
  productId: number;
  areaTypeDisplayName?: string;
  questionCode?: string;
}

export enum ConditionTypeEnum {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  NON_EMPTY = 'NON_EMPTY',
  GREATER = 'GREATER',
}

export enum ConditionType {
  EQUAL = 'Równe',
  NOT_EQUAL = 'Nierówne',
  NON_EMPTY = 'Niepuste',
  GREATER = 'Większe',
  LOWER = 'Mniejsze',
}
