import { Modal } from '@profitowi/component-library';
import { useState } from 'react';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';

import { useCrmClient } from 'hooks/useStore';

import CustomerDetails from './CustomerDetails';
import CustomerSearchForm from './CustomerSearchForm';

const NewAnalysisModal = () => {
  const [selectedCrmClientId, setSelectedCrmClientId] = useState<string>('');
  const crmClientId = useCrmClient((state) => state.crmClientId);
  let { type } = useParams();

  return (
    <Modal
      description="Wybierz formę Klienta lub wyszukaj go w bazie"
      title="Nowa Analiza Potrzeb"
      visible>
      <Routes>
        <Route
          index
          element={
            crmClientId || selectedCrmClientId ? (
              <CustomerDetails type={type} selectedCrmClientId={selectedCrmClientId} />
            ) : (
              <CustomerSearchForm type={type} setSelectedCrmClientId={setSelectedCrmClientId} />
            )
          }
        />
      </Routes>

      <Outlet />
    </Modal>
  );
};

export default NewAnalysisModal;
