import { Avatar, Box, Icon, Text } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';

import { useAssessmentStore } from 'hooks/useStore';
import { StateType } from 'types/assessment';

interface Props {
  expired: boolean;
  state: string;
  to: string;
  text: string;
  icon: string;
  custom: boolean;
  colspan?: boolean;
  backgroundColor?: string;
}

const Tile = ({
  expired,
  state,
  to,
  text,
  icon,
  custom = false,
  colspan = false,
  backgroundColor = 'bg-green-600',
}: Props) => {
  const navigate = useNavigate();
  const assessmentState = useAssessmentStore((state) => state.assessment.state);

  return (
    <div
      onClick={() => {
        if (assessmentState === StateType.FINISHED) return;
        return navigate(to);
      }}
      className={`cursor-pointer ${colspan ? 'col-span-2' : ''}`}>
      <Box className="!p-8">
        <div className="flex flex-row items-center space-x-3">
          <Avatar bg={backgroundColor} className="!h-20 !w-20">
            <Icon custom={custom} name={icon} className="w-16" />
          </Avatar>

          <Text className="space-x-3" size="lg" weight="bold">
            <span>{text}</span>
            {(expired || state === 'NOT_COMPLETED') && (
              <Icon name="exclamation-square-fill" className="text-xl text-red-600" />
            )}
            {state === 'COMPLETED' && (
              <Icon name="check-square-fill" className="text-xl text-green-600" />
            )}
          </Text>
        </div>
      </Box>
    </div>
  );
};

export default Tile;
