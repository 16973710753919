import additionalDeals from './pop-up-additional-deals.jpg';
import comparisonHospitalStay from './pop-up-comparison-hospital-stay.png';
import comparisonTreatmentTransport from './pop-up-comparison-treatment-transport.png';
import examinationRequirements from './pop-up-examination-requirements.jpg';
import exampleCosts from './pop-up-example-costs.jpg';
import illnessesPl from './pop-up-illnesses-pl.jpg';

export default {
  additionalDeals,
  comparisonHospitalStay,
  comparisonTreatmentTransport,
  examinationRequirements,
  exampleCosts,
  illnessesPl,
};
