import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import MainSidebar from 'components/MainSidebar';
import ToolSidebar from 'components/ToolSidebar';
import { useAssessmentStore } from 'hooks/useStore';

const PageLayout = () => {
  const navigate = useNavigate();
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId);

  useEffect(() => {
    if (!assessmentId) {
      navigate('/');
    }
  }, [assessmentId, navigate]);

  return (
    <div className="flex space-x-10">
      <MainSidebar />
      <div className="flex-grow z-10">
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </div>
      <ToolSidebar />
    </div>
  );
};

export default PageLayout;
