import create from 'zustand';

type UsePageLayout = {
  displayedNotCompleted: boolean;
  setDisplayedNotCompleted: (displayedNotCompleted: boolean) => void;
};

export const usePageLayout = create<UsePageLayout>((set) => ({
  displayedNotCompleted: false,
  setDisplayedNotCompleted: (displayedNotCompleted) => set({ displayedNotCompleted }),
}));
