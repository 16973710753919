import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  subject: yup
    .string()
    .required('Tytuł jest wymangany')
    .max(255, 'Przekroczono dozwoloną ilość znaków')
    .default(''),
  content: yup.string().required('Treść wiadomoci jest wymagana').default(''),
});
