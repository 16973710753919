import { CheckboxGroup, CheckboxGroupField, Header, Text } from '@profitowi/component-library';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

import { ClientMarketingConsents, ClientStatements } from 'types/client';
import { Statements } from 'types/statements';

import VerificationCode from './VerificationCode/VerificationCode';

const styles = {
  disabled: 'pointer-events-none opacity-10',
};

type Props = {
  statements: Statements[];
  originalStatements?: ClientStatements[];
  setIsDisabled: (isDisabled: boolean) => void;
  setFetching?: (fetching: boolean) => void;
  aclientId?: number;
};

const MarketingConsents = ({
  statements,
  aclientId,
  originalStatements,
  setIsDisabled,
  setFetching,
}: Props) => {
  const { isValid } = useFormikContext<ClientMarketingConsents>();
  return (
    <div className="pt-8">
      <Header as="h3" weight="bold" size="lg">
        Zgody marketingowe
      </Header>

      <div className="space-y-8 py-8">
        <div className={clsx({ [styles.disabled]: !isValid })}>
          <CheckboxGroupField name="statements">
            <div className="space-y-4">
              <Text>
                Wyrażam zgodę na otrzymywanie od Profitowi S.A. ofert i informacji na temat
                produktów oferowanych przez partnerów Profitowi S.A. za pośrednictwem:
              </Text>

              {statements?.map(({ statementId, content, required }) => (
                <CheckboxGroup.Checkbox
                  isDisabled={originalStatements?.some(
                    ({ statementId: id, consent, state }) =>
                      id === statementId && state === 'CONFIRMED' && !!consent
                  )}
                  key={statementId}
                  isRequired={required}
                  value={statementId.toString()}>
                  <div className="flex space-x-1">
                    {<Text className="text-red-600">{required ? '* ' : ''}</Text>}
                    <Text>{content}</Text>
                  </div>
                </CheckboxGroup.Checkbox>
              ))}
            </div>
          </CheckboxGroupField>
        </div>

        <VerificationCode
          statements={statements}
          setIsDisabled={setIsDisabled}
          setFetching={setFetching}
          aclientId={aclientId}
        />
      </div>
    </div>
  );
};

export default MarketingConsents;
