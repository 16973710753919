import { FormData, Question } from '../types/forms';
import { Product, ProductConditions } from '../types/products';
import http from '../utils/axios';

export const getProducts = async (
  nameSearch: string | undefined,
  shortcutSearch: string | undefined
): Promise<Product[]> => {
  const params = {
    nameSearch,
    shortcutSearch,
  };
  const { data } = await http.get<Product[]>(`/config/products`, {
    params,
  });
  return data;
};

export const getProductAreas = async (): Promise<FormData[]> => {
  const { data } = await http.get<FormData[]>(`/config/forms`);
  return data;
};

export const getProductAreaQuestions = async (formCode: string): Promise<Question[]> => {
  const { data } = await http.get<Question[]>(`/config/forms/${formCode}/questions`);
  return data;
};

export const getProductConditions = async (productId?: number): Promise<ProductConditions[]> => {
  const { data } = await http.get<ProductConditions[]>(`/config/products/${productId}/conditions`);
  return data;
};

export const deleteProductCondition = async (recConditionId?: number) => {
  const { data } = await http.delete<string>(`/config/products/conditions/${recConditionId}`);
  return data;
};

export const deactivateProduct = async (productId?: number) => {
  const { data } = await http.put<string>(`/config/products/${productId}/deactivate`);
  return data;
};

export const addProduct = async (idaProductId: number, name: string, shortcut: string) => {
  const { data } = await http.post(`/config/products`, {
    name,
    shortcut,
    idaProductId,
  });
  return data;
};

export const editProduct = async (
  idaProductId: number,
  productId?: number,
  name?: string,
  shortcut?: string
) => {
  if (!productId && !name && !shortcut) {
    return;
  }

  const { data } = await http.put(`/config/products/${productId}`, {
    name,
    shortcut,
    idaProductId,
  });
  return data;
};

export const addRecommendations = async (
  productId: number,
  recommendations: {
    formCode: string;
    questionCode: string;
    conditionType: string;
    conditionValue?: string;
  }[]
) => {
  const { data } = await http.post(`/config/products/${productId}/conditions`, recommendations);
  return data;
};

export const copyRecommendations = async (productId: number, copyOf: number) => {
  const { data } = await http.post(`/config/products/${productId}/conditions?copyOf=${copyOf}`);
  return data;
};

export const getSimplifiedProductsList = async (
  nameSearch?: string,
  shortcutSearch?: string
): Promise<Omit<Product, 'recConditions'>[]> => {
  const { data } = await http.get<Omit<Product, 'recConditions'>[]>(`/config/products/simple`, {
    params: {
      nameSearch,
      shortcutSearch,
    },
  });
  return data;
};

export const changeProductsIda = async (newIdaId: number, productNumbers: number[]) => {
  const { data } = await http.post(`/products/changeIda/${newIdaId}`, productNumbers);
  return data;
};
