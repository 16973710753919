import { Button, Modal, Table, Text } from '@profitowi/component-library';
import { Field, Formik, FormikValues, useFormikContext } from 'formik';
import { useState } from 'react';

import CalculationSum from './components/CalculationSum';
import { columns, headers, initialData } from './components/calculatorData';

type Props = { code: string };

const Calculator = ({ code }: Props) => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [modalOpen, setModalOpen] = useState(false);
  const [labelHeader, valueHeader] = headers(code);
  if (!columns[code]) return null;

  const data: { label: string; name: string }[] = initialData(code).map(({ label }) => ({
    label: label,
    name: label,
  }));
  const initialValues: { [label: string]: number | undefined } = {};
  initialData(code).map(({ label }) => {
    initialValues[label] = undefined;
  });

  return (
    <div className="m-3">
      <Button size="sm" variant="outline-primary" onPress={() => setModalOpen(true)}>
        <Text className="-my-1 -mx-2">Otwórz kalkulator</Text>
      </Button>

      <Modal hasCloseButton visible={modalOpen} title="" setVisible={setModalOpen}>
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          <div>
            <Table
              columns={[
                {
                  Header: labelHeader,
                  accessor: 'label',
                  Cell: ({ cell }) =>
                    cell.value == 'Suma' ? <Text weight="bold">{cell.value}</Text> : cell.value,
                },
                {
                  Header: valueHeader,
                  accessor: 'name',
                  Cell: ({ row, cell }) =>
                    cell.value == 'Suma' ? null : (
                      <div className="flex flex-row flex-nowrap items-center justify-end">
                        <Field
                          key={row.index}
                          id={cell.value}
                          className="text-right grow"
                          name={cell.value}
                          type="number"
                          min={0}
                          placeholder="1"
                        />
                        <Text weight="semibold" className="w-14 pl-2">
                          {cell.value === 'na ile miesięcy chcesz zabezpieczyć dochód?'
                            ? 'miesięcy'
                            : 'zł'}
                        </Text>
                      </div>
                    ),
                },
              ]}
              data={[...data]}
            />
            <CalculationSum
              code={code}
              setModalOpen={setModalOpen}
              setSum={(value) => setFieldValue(code, value)}
            />
          </div>
        </Formik>
      </Modal>
    </div>
  );
};

export default Calculator;
