import { Box, Button, Header, InputField, Loader, Text } from '@profitowi/component-library';
import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import Table from 'components/Table/table';
import { PRODUCTS } from 'constants/queries/products';
import { deleteDocumentAttachments, getDocuments } from 'services/documents';
import { Documents } from 'types/documents';

import ConfimMutationModal from '../../components/ConfimMutationModal/ConfimMutationModal';
import { columns } from './columns';
import DocumentsTable from './components/Documents/DocumentsTable';
import DocumentsIda from './components/DocumentsIda/DocumentsIda';
import FileForm from './components/FileForm/FileForm';

type CustomRow = {
  row: Row<Documents>;
};

const DocumentsPageView = () => {
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: number[] }>({});
  const [selectedRow, setSelectedRow] = useState<number>();
  const [nameSearch, setNameSearch] = useState<string>('');
  const [shortcutSearch, setShortcutSearch] = useState<string>('');
  const navigate = useNavigate();

  const {
    data: productsList,
    isFetching: productsIsLoading,
    isError: productsIsError,
    error: productsError,
    refetch,
  } = useQuery(
    [PRODUCTS.PRODUCTS, nameSearch, shortcutSearch],
    () =>
      getDocuments(
        nameSearch.length ? nameSearch : undefined,
        shortcutSearch.length ? shortcutSearch : undefined
      ),
    {
      keepPreviousData: true,
    }
  );

  const handleSelectionChange = (rows: Row[], rowKey?: number) => {
    const selectedIds = rows.map((item) => item.id);

    if (rowKey) {
      const newState = {
        ...selectedRows,
        [rowKey]: selectedIds,
      };

      setSelectedRows(newState);
    }
  };

  const { mutate: mutateDeleteProductAttachments, isLoading } = useMutation(
    () => {
      if (selectedRow) {
        return deleteDocumentAttachments(selectedRow);
      }

      const array: any = [];
      Object.keys(selectedRows).map((key) =>
        selectedRows[key].map((item: any) => array.push(item))
      );

      return deleteDocumentAttachments(array);
    },
    {
      onSuccess: () => {
        refetch();
        setSelectedRows({});
        setSelectedRow(undefined);
        navigate(-1);
      },
    }
  );

  const onDeleteDocument = (documentId: number) => {
    setSelectedRow(documentId);
    navigate('confirm');
  };

  const isDeleteButtonDisable = useMemo(() => {
    const array: any = [];
    Object.keys(selectedRows).map((key) => selectedRows[key].map((item: any) => array.push(item)));

    return array.length <= 1;
  }, [selectedRows]);

  function onSubmit({
    nameSearch,
    shortcutSearch,
  }: {
    nameSearch: string;
    shortcutSearch: string;
  }) {
    setNameSearch(nameSearch);
    setShortcutSearch(shortcutSearch);
  }

  function clearForm() {
    setNameSearch('');
    setShortcutSearch('');
    refetch();
  }

  return (
    <div>
      <DocumentsIda />

      <Box className="space-y-6 mt-8">
        <Header as="h4" size="lg" weight="semibold">
          Dokumenty
        </Header>

        <div className="flex justify-between">
          <Formik
            initialValues={{ nameSearch: '', shortcutSearch: '' }}
            onSubmit={onSubmit}
            onReset={clearForm}>
            <Form className="flex gap-4">
              <InputField label="Nazwa produktu" name="nameSearch" placeholder="Nazwa produktu" />
              <InputField label="Kod produktu" name="shortcutSearch" placeholder="Kod produktu" />

              <Button className="" size="sm" type="submit">
                Szukaj
              </Button>
              <Button className="" size="sm" type="reset">
                Wyczyść
              </Button>
            </Form>
          </Formik>

          <Button
            variant="outline-primary"
            onPress={() => navigate('confirm')}
            isDisabled={isDeleteButtonDisable}>
            <Text weight="semibold">
              Usuń wybrane
              <i className="bi bi-plus-lg ml-2" />
            </Text>
          </Button>
        </div>

        <Box.FullWidth>
          {productsIsLoading && <Loader className="h-12 w-12" />}
          {!productsIsLoading && !!productsList?.length && (
            <>
              <Table
                data={productsList}
                columns={columns}
                isLoading={productsIsLoading || isLoading}
                isExpandable
                renderRowSubComponent={({
                  row: {
                    original: { attachmentDataList, productId },
                  },
                }: CustomRow) => {
                  if (!attachmentDataList.length) {
                    return undefined;
                  }

                  return (
                    <div className="pl-20">
                      <DocumentsTable
                        attachmentDataList={attachmentDataList}
                        onDeleteDocument={onDeleteDocument}
                        setSelectedRows={handleSelectionChange}
                        productId={productId}
                      />
                    </div>
                  );
                }}
              />

              {productsIsError && <ErrorMessages error={productsError} />}
              {isLoading && <Loader className="h-12 w-12" />}
            </>
          )}

          {!productsIsLoading && !productsList?.length && (
            <div className="flex justify-start align-center mt-8 mb-4 px-6">
              <Text className="text-slate-600" size="lg">
                Nie udało się znaleźć dokumentów odpowiadających Twojemu zapytaniu.
              </Text>
            </div>
          )}
        </Box.FullWidth>
      </Box>

      <Routes>
        <Route path="add" element={<FileForm refetchProducts={refetch} />} />
        <Route
          path="confirm"
          element={
            <ConfimMutationModal
              confirmMessage="Czy na pewno chcesz usunąć wybrane elementy ?"
              mutate={() => mutateDeleteProductAttachments()}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default DocumentsPageView;
