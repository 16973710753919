import { Box, Text } from '@profitowi/component-library';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  text: string;
}

function Tile({ children, text }: Props) {
  return (
    <Box className="flex justify-start items-center px-4 py-5 grow">
      <div className="mr-3">{children}</div>
      <Text size="lg" weight="bold" className="m-0">
        {text}
      </Text>
    </Box>
  );
}

export default Tile;
