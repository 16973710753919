import { Table } from '@profitowi/component-library';

import * as assets from './assets';

type InfoEntry = {
  element?: JSX.Element;
  linkText?: string;
  content?: JSX.Element;
  sources?: string[];
};

const images = assets.default;
const imgStyle = '';

const subgroups: { [group: string]: InfoEntry[] } = {
  resqueEkuz: [
    {
      linkText: 'Zasady finansowania akcji ratowniczych w górach',
      content: (
        <>
          <p className="font-bold">
            Zasady finansowania akcji ratowniczych w górach (wybrane kraje Europy)
          </p>
          <Table
            columns={[
              {
                Header: 'Nazwa kraju',
                accessor: 'country',
              },
              {
                Header: 'Zasady odpłatności za akcję ratowniczą w górach',
                accessor: 'rules',
              },
            ]}
            data={[
              {
                country: 'Austria',
                rules:
                  'Ratownictwo górskie w Austrii jest odpłatne. Koszt szybkiej akcji oscyluje na poziomie np. 2000 euro. Skomplikowana akcja bywa cztery - pięć razy droższa.',
              },
              {
                country: 'Czechy',
                rules: 'Ratownictwo górskie na terenie Czech jest stuprocentowo odpłatne.',
              },
              {
                country: 'Francja',
                rules:
                  'Ratowana osoba musi pokrył koszty interwencji lekarza. Czasem odpłatna Jest również sama akcja ratownicza.',
              },
              {
                country: 'Niemcy',
                rules: 'Ratownictwo górskie na terenie Niemiec jest odpłatne.',
              },
              {
                country: 'Słowacja',
                rules: 'Ratownictwo górskie na terenie Słowacji jest stuprocentowo odpłatne.',
              },
              {
                country: 'Szwajcaria',
                rules: 'Ratownictwo górskie na terenie Szwajcarii jest odpłatne w 50%.',
              },
              {
                country: 'Włochy',
                rules:
                  'W miejscach najczęściej odwiedzanych przez turystów, akcje ratownicze zwykle są odpłatne. Ich koszt wynosi np. 150 euro za minutą pracy śmigłowca.',
              },
            ]}
          />
        </>
      ),
    },
    {
      linkText: 'EKUZ - Europejska Karta Ubezpieczenia Zdrowotnego',
      content: (
        <>
          <p className="font-bold">EKUZ - EUROPEJSKA KARTA UBEZPIECZENIA ZDROWOTNEGO</p>
          <p>
            EKUZ może zostać wydana wyłącznie osobom zgłoszonym do ubezpieczenia zdrowotnego lub
            osobom, które mają prawo do świadczeń zdrowotnych finansowych ze środków publiczych
            zgodnie z przepisami krajowymi. Karta EKUZ potwierdza prawo do leczenia w czasie
            wyjazdów turystycznych lub zawodowych do większości krajów europejskich. EKUZ jest
            darmowa i ważna z dowodem tożsamości. Otrzymują ją osoby, które obejmuje ubezpieczenie
            zdrowotne w Polsce. EKUZ dla osób niewidomych jest oznakowana naklejką z tekstem w
            alfabecie Braille'a.
          </p>
          <p>
            Korzystając z EKUZ za granicą pamiętaj, że podlegasz obowiązkom i korzystasz z praw
            wynikających z przepisów państwa pobytu na tych samych warunkach, co ubezpieczni w tym
            pańswtwie. Jeżeli w danym kraju istnieje obowiązek współpłacenia również poniesiesz
            takie koszty.
          </p>
          <a href="https://www.nfz.gov.pl/dla-pacjenta/nasze-zdrowie-w-ue/leczenie-w-krajach-unii-europejskiej-i-efta/wyjezdzam-do/">
            https://www.nfz.gov.pl/dla-pacjenta/nasze-zdrowie-w-ue/leczenie-w-krajach-unii-europejskiej-i-efta/wyjezdzam-do/
          </a>
        </>
      ),
    },
  ],
  comparisons: [
    {
      element: (
        <p className="font-bold">
          Czy wiesz, że koszty leczenia i pobytu w szpitalu różnią się w zależności od kraju? Koszt
          pobytu w szpitalu za granicą może wynosić nawet kilkanaście tysięcy złotych za jeden
          dzień. Suma Ubezpieczenia powinna uwzględniać poziom kosztów leczenia w kraju pobytu.
        </p>
      ),
    },
    {
      linkText: 'Porównanie różnic w koszcie pobytu w szpitalu za dzień',
      content: (
        <img
          className={imgStyle}
          src={images.comparisonHospitalStay}
          alt="comparisonHospitalStay"
        />
      ),
    },
    {
      linkText: 'Porównanie różnic w koszcie leczenia i transportu',
      content: (
        <img
          className={imgStyle}
          src={images.comparisonTreatmentTransport}
          alt="comparisonTreatmentTransport"
        />
      ),
    },
  ],
  med1: [
    {
      linkText:
        'Informacja o braku LUB konieczności oceny medycznej Klienta dla poszczególnych umów dodatkowych ',
      content: <img className={imgStyle} src={images.additionalDeals} alt="additionalDeals" />,
    },
    {
      linkText: 'Limity badań medycznych',
      content: (
        <>
          <p>
            W zależności od wysokości sumy ubezpieczenia i wieku Ubezpieczonego konieczne jest
            wykonanie badań medycznych w zakresie przedstawionym poniżej. Wyjątek stanowi umowa WOX,
            gdzie podstawą do określenia zakresu badań limitowych jest wysokość urocznionej składki
            za umowę WOX, zliczanej ze wszystkich aktywnych umów WOX posiadanych przez
            Ubezpieczonego i jego wiek.
          </p>
          <img
            className={imgStyle}
            src={images.examinationRequirements}
            alt="examinationRequirements"
          />
        </>
      ),
    },
  ],
  med2: [
    {
      linkText: 'Na co chorują Polacy? ',
      content: <img className={imgStyle} src={images.illnessesPl} alt="illnessesPl" />,
      sources: [
        'https://punkta.pl/akademia/ubezpieczenia-zdrowotne/na-co-choruja-polacy-choroby-serca-alergia-depresja-cukrzyca/',
        'https://pacjent.gov.pl/artykul/cukrzyca-w-liczbach',
      ],
    },
    {
      linkText: 'Przykładowe koszty leczenia różnych schorzeń i urazów ',
      content: <img className={imgStyle} src={images.exampleCosts} alt="exampleCosts" />,
      sources: ['https://www.ebroker.pl/wiedza/ile-moze-kosztowac-leczenie-bez-ubezpieczenia/5289'],
    },
  ],
};

const { resqueEkuz, comparisons, med1, med2 } = subgroups;

const infoGroups: { [key: string]: InfoEntry[][] } = {
  ACCIDENT_ACCIDENT_3: [med1, med2],
  SAVINGS_SAVINGS_1_e: [med1, med2],
  SAVINGS_SAVINGS_2_c: [med1],
  SAVINGS_SAVINGS_3_d: [med1],
  TRAVEL_TRAVEL_1: [resqueEkuz],
  TRAVEL_TRAVEL_3: [comparisons, resqueEkuz],
};

export { infoGroups };
export type { InfoEntry };
