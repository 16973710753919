import { Tab } from '@headlessui/react';
import { Alert, Loader } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import ClientNavigation from 'components/ClientNavigation';
import PageContent from 'components/PageContent';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore } from 'hooks/useStore';
import { getAllAreas, getAreas } from 'services/areas';
import { AreaField, AreaNames } from 'types/area';

import Areas from './components/Areas';

export default function HowCanWeHelp() {
  const agentId = useAgentStore((state) => state.agent.agentId);

  const { assessmentId, clients } = useAssessmentStore((state) => ({
    assessmentId: state.assessment.assessmentId || NaN,
    clients: state.assessment.clients || [],
  }));

  const areaNamesQuery = useQuery<AreaNames, AxiosError>('getAreaNames', () => getAllAreas());
  const areaDataQuery = useQuery<AreaField[], AxiosError>('area', () =>
    getAreas(assessmentId, agentId)
  );

  return (
    <PageContent title="W czym pomagamy">
      {(areaNamesQuery.isError || areaDataQuery.isError) && (
        <Alert type="error">{areaNamesQuery.error?.message || areaDataQuery.error?.message}</Alert>
      )}
      {(areaNamesQuery.isLoading || areaDataQuery.isLoading) && <Loader className="h-12 w-12" />}
      {areaNamesQuery.isSuccess && areaDataQuery.isSuccess && (
        <ClientNavigation clients={clients}>
          {clients.map((client, index) => (
            <Tab.Panel key={client.clientId}>
              <Areas
                areaData={areaDataQuery.data?.[index]}
                areaNames={areaNamesQuery.data?.[client.clientType]}
                client={client}
                areaDataQuery={areaDataQuery}
              />
            </Tab.Panel>
          ))}
        </ClientNavigation>
      )}
    </PageContent>
  );
}
