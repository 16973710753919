import { Form, useFormikContext } from 'formik';
import { orderBy } from 'lodash';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import ConfirmationModal from 'components/ConfirmationModal';
import ModalActions from 'components/ModalActions';
import useNotificationStore from 'hooks/useNotificationStore';
import { usePageLayout } from 'hooks/usePageLayout';
import AutoSave from 'pages/CustomerData/components/AutoSave';

import { FileUploadContext, Question } from '../../types/forms';
import QuestionFieldRow from './QuestionFieldRow';
import { isConditionFulfilled } from './helpers';

const renderQuestionFieldRows = (
  questions: Question[],
  formikValues: unknown,
  context: FileUploadContext
) => {
  if (!questions) return null;

  const values = formikValues as { [char: string]: any };

  return orderBy(questions, ['orderNo'], ['asc']).map((question: Question) => {
    return (
      <QuestionFieldRow
        key={question.questionId}
        question={question}
        isVisible={isQuestionVisible(question, values)}
        context={context}
      />
    );
  });
};

export const isQuestionVisible = (
  question: Question | undefined,
  values: { [char: string]: any }
) => {
  if (!question) return false;

  let isVisible = true;
  const { conditionQuestionCode, conditionType, conditionValue } = question;

  if (conditionQuestionCode && conditionType && conditionValue) {
    const conditionFulfilled = isConditionFulfilled(
      values[conditionQuestionCode],
      conditionType,
      conditionValue
    );
    isVisible = conditionFulfilled;
  }

  return isVisible;
};

type Props = {
  hackySubmit: (formikValues: any, errors?: any, questions?: any) => void;
  questions: Question[];
  onCancel: () => void;
  context: FileUploadContext;
};

const FormBody = ({ hackySubmit, questions, onCancel, context }: Props) => {
  const { values, errors } = useFormikContext();
  const { displayedNotCompleted, setDisplayedNotCompleted } = usePageLayout();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const { addNotification, removeNotification } = useNotificationStore();

  useEffect(() => {
    removeNotification('postArea-error');
    return () => {
      setDisplayedNotCompleted(false);
    };
  }, []);

  const onConfirm = () => {
    document.querySelector('.react-datepicker-popper')?.remove();
    removeNotification('postArea-autosave');
    removeNotification('postArea-error');
    if (displayedNotCompleted || !isEmpty(errors))
      return addNotification({
        id: 'postArea-error',
        title: 'Zapis formularza nie powiódł się',
        message: 'Proszę sprawdzić poprawność uzupełnienia wszystkich pól',
        type: 'error',
      });
    addNotification({
      id: 'postArea-save',
      title: 'Formularz został zapisany pomyślnie',
      type: 'success',
    });
  };

  return (
    <Form className="space-y-10">
      <AutoSave
        handleSubmit={(values) => {
          hackySubmit(values, errors, questions);
        }}
      />
      <div className="-my-6">{renderQuestionFieldRows(questions, values, context)}</div>
      <ConfirmationModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        action={() => onCancel()}
        message="Czy na pewno chcesz anulować formularz?"
        confirmText="Nie"
      />
      <ModalActions
        onConfirm={onConfirm}
        onCancel={() => setCancelModalVisible(true)}
        className="!justify-start"
      />
    </Form>
  );
};

export default FormBody;
