import React from 'react';

import Tile from 'components/ClientAreasForms/Tile';
import SURVEYS from 'components/SurveyIcons/surveyIcons';
import { BasicClientData } from 'types/assessment';
import { SurveyData } from 'types/surveys';

export type SurveyBasicInfo = Pick<SurveyData, 'surveyId' | 'code' | 'name' | 'version'>;

type Props = {
  survey: SurveyBasicInfo;
  expired: boolean;
  state: string;
  client: BasicClientData;
};

const SurveyTile = ({ survey, expired, state, client }: Props) => {
  const { surveyId, name, code } = survey;
  const { icon, custom, bgColor } = SURVEYS[code as keyof typeof SURVEYS];
  return (
    <Tile
      key={surveyId}
      expired={expired}
      state={state}
      to={`/surveys/survey/${client.aclientId}/${surveyId}`}
      text={name}
      icon={icon}
      custom={custom}
      backgroundColor={bgColor}
    />
  );
};

export default SurveyTile;
