import { EmailResponse } from 'types/mail';
import http from 'utils/axios';

export const getEmailMessage = async (assessmentId: number, aClientId: number, agentId: number) => {
  const { data } = await http.get<EmailResponse>(
    `/agents/${agentId}/assessments/${assessmentId}/mail/SUMMARY`,
    {
      params: {
        aClientId,
      },
    }
  );

  return data;
};

export const sendEmail = async (
  data: any,
  assessmentId: number,
  aClientId: number,
  agentId?: string
) =>
  http.post(
    `/agents/${agentId}/assessments/${assessmentId}/mail/SUMMARY?aClientId=${aClientId}`,
    data
  );
