import facebook from 'assets/summaryPdfIcons/facebook.png';
import instagram from 'assets/summaryPdfIcons/instagram.png';
import linkedin from 'assets/summaryPdfIcons/linkedin.png';

const styles = {
  text: {
    fontFamily: 'Sora',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '9px',
    lineHeight: '15px',
    color: '#707070',
    verticalAlign: 'center',
  },
  icon: {
    width: '24px',
    heigth: '24px',
  },
  link: {
    fontFamily: 'Sora',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '30px',
    color: '#00E5C9',
  },
};

const BottomNavbar = () => {
  return (
    <div
      id="navbar"
      style={{ display: 'table', height: '20%', alignItems: 'center', padding: '0px 20px' }}>
      <div style={{ width: '65%', display: 'table-cell' }}>
        <span style={styles.text}>
          PROFITOWI S.A. Siedziba: ul. Giełdowa 7/9, 01-211 Warszawa. Sąd rejestrowy i nr KRS: Sąd
          Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru
          Sądowego, nr 0000693553. Kapitał zakładowy: 15.000.000zł. Informacje o akcjonariuszu: 100%
          Aegon Towarzystwo Ubezpieczeń na Życie S.A. Aegon jest jednym z wiodących światowych
          dostawców produktów ubezpieczeniowych, inwestycyjnych oraz emerytalnych. Obsługuje prawie
          30 milionów klientów w 20 krajach.
        </span>
      </div>
      <div
        style={{
          width: '30%',
          display: 'table-cell',
          textAlign: 'center',
          verticalAlign: 'middle',
        }}>
        <div
          style={{
            float: 'right',
          }}>
          <div style={{ textAlign: 'start' }}>
            <a
              href="https://profitowi.pl"
              style={{
                textDecoration: 'none',
                whiteSpace: 'nowrap',
              }}>
              <span style={{ ...styles.link }}>Więcej na: www.profitowi.pl</span>
            </a>
          </div>
          <div
            style={{
              marginTop: '8px',
              whiteSpace: 'nowrap',
              textAlign: 'start',
              position: 'relative',
            }}>
            <a
              style={{
                marginRight: '24px',
                textDecoration: 'none',
                marginLeft: '6px',
                color: '#fff',
              }}
              href="https://www.facebook.com/Profitowi-100871888384798">
              link
              <img style={{ ...styles.icon, position: 'absolute' }} src={facebook} alt="facebook" />
            </a>
            <a
              style={{ marginRight: '24px', textDecoration: 'none', color: '#fff' }}
              href="https://www.instagram.com/profitowi/">
              link
              <img
                style={{ ...styles.icon, position: 'absolute', left: '48px' }}
                src={instagram}
                alt="instagram"
              />
            </a>
            <a
              style={{ textDecoration: 'none', color: '#fff' }}
              href="https://www.linkedin.com/company/70860448/admin/">
              link
              <img
                style={{ ...styles.icon, position: 'absolute', left: '96px' }}
                src={linkedin}
                alt="linkedin"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '5%',
          display: 'table-cell',
        }}></div>
    </div>
  );
};

export default BottomNavbar;
