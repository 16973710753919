import { InputField, Loader, Modal } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import MarketingConsents from 'components/MarketingConsents';
import ModalActions from 'components/ModalActions';
import { CLIENT } from 'constants/queries/client';
import { STATEMENT } from 'constants/queries/statements';
import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { useCrmClient } from 'hooks/useStore';
import { createClient, getClient, saveClientMarketingConsents } from 'services/client';
import { getStatements } from 'services/statements';
import {
  Client,
  ClientInfo,
  ClientMarketingConsents,
  ClientType,
  MarketingConsentsPayload,
} from 'types/client';
import { CommonPersonalData } from 'types/client';
import { Statements } from 'types/statements';

import PhonePrefixInputField from '../../../../components/PhonePrefixInputField/PhonePrefixInputField';
import { personalDataSchema, validationSchema } from './validation';

const MarketingConsentsForm = () => {
  const crmClientId = useCrmClient((state) => state.crmClientId);
  const agentId = useAgentStore((state) => state.agent.agentId);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

  const { removeNotification } = useNotificationStore(({ removeNotification }) => ({
    removeNotification,
  }));

  useEffect(() => {
    if (!crmClientId && agentId) mutateCreateClient(agentId);
  }, []);

  const {
    data: client,
    isLoading: isGetUserLoading,
    isSuccess: isGetUserSuccess,
  } = useQuery<Client, AxiosError>(
    [CLIENT.CLIENT],
    () => getClient(crmClientId, ClientType.INDIVIDUAL, agentId),
    { enabled: crmClientId.length > 0 }
  );

  const { data: statements = [] } = useQuery<Statements[], AxiosError>([STATEMENT.STATEMENTS], () =>
    getStatements()
  );

  const {
    data: newClient,
    mutate: mutateCreateClient,
    isLoading: isClientCreateLoading,
    isSuccess: isClientCreated,
  } = useMutation<ClientInfo, AxiosError, number>((agentId) => createClient(agentId), {});

  const { mutate } = useMutation<AxiosResponse, AxiosError, MarketingConsentsPayload>(
    (payload) => saveClientMarketingConsents(payload),
    {
      onSuccess: () => {
        navigate('/');
        setIsDisabled(false);
      },
    }
  );

  const createStatementsPayload = (selectedStatements: string[]) =>
    statements.map(({ statementId }) => {
      return { statementId, consent: selectedStatements.includes(statementId.toString()) };
    });

  const handleSubmit = (client: ClientMarketingConsents) => {
    const { statements, verificationCode, personalData } = client;
    setIsDisabled(true);

    const modifiedPersonalData: CommonPersonalData = {
      ...personalData,
      phone: personalData.phone.startsWith('48')
        ? personalData.phone.slice(2, personalData.phone.length)
        : personalData.phone,
    };

    const payload = {
      data: {
        clientData: {
          personalData: modifiedPersonalData,
          statements: createStatementsPayload(statements),
        },
        verificationCode,
      },
      agentId: agentId,
    };

    mutate(payload);
    removeNotification('postArea-success');
  };

  const initialValues: ClientMarketingConsents = useMemo(() => {
    return {
      personalData: client
        ? {
            ...client.personalData,
            phone: (client.personalData.phone.length === 9 ? '48' : '') + client.personalData.phone,
          }
        : { ...personalDataSchema.cast({}), id: newClient?.clientId || -1 },
      statements: client ? client.statements.map(({ statementId }) => statementId.toString()) : [],
      verificationCode: '',
    };
  }, [client, newClient]);

  document
    .querySelector('.fixed.inset-0.z-50.overflow-y-auto')
    ?.setAttribute('style', 'overflow: overlay!important');

  return (
    <Modal className="max-w-3xl p-6" visible title="Zgody marketingowe">
      {isGetUserLoading || isClientCreateLoading ? <Loader className="h-12 w-12" /> : null}

      {isGetUserSuccess || isClientCreated ? (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}>
          {({ isValid = false }) => (
            <div className="flex flex-col items-center">
              <Form className="w-2/3 space-y-4">
                <InputField name="personalData.firstName" label="Imię" isRequired />
                <InputField name="personalData.lastName" label="Nazwisko" isRequired />
                <PhonePrefixInputField name="personalData.phone" label="Telefon" isRequired />
                <InputField name="personalData.email" label="E-mail" isRequired />
                <InputField name="personalData.pesel" label="PESEL" />
                <InputField name="personalData.street" label="Ulica" />
                <InputField name="personalData.houseNo" label="Numer domu" />
                <InputField name="personalData.flatNo" label="Numer mieszkania" />
                <InputField name="personalData.zipCode" label="Kod pocztowy" />
                <InputField name="personalData.city" label="Miejscowość" />

                <MarketingConsents
                  originalStatements={client?.statements}
                  statements={statements}
                  setIsDisabled={setIsDisabled}
                />
                <ModalActions
                  isConfirmDisabled={isDisabled || !isValid}
                  onCancel={() => navigate('/#')}
                />
              </Form>
            </div>
          )}
        </Formik>
      ) : null}
    </Modal>
  );
};

export default MarketingConsentsForm;
