import { Alert, InputField, Text, TextareaField } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ModalActions from 'components/ModalActions';
import { updateAgent } from 'services/agent';
import { Agent, AgentPayload } from 'types/agent';

import PhonePrefixInputField from '../../../components/PhonePrefixInputField/PhonePrefixInputField';
import Examples from '../components/Examples';
import { validationSchema } from './validation';

type Props = {
  agent: Agent;
  refetch: () => void;
};

const AgentForm = ({ agent, refetch }: Props) => {
  const navigate = useNavigate();
  const maxCharactersLength = 820;
  const maxEmailSchemaCharactersLength = 500;
  const { error, isError, mutate } = useMutation<AxiosResponse, AxiosError, AgentPayload>(
    (payload) => updateAgent(payload),
    {
      onSuccess: () => {
        refetch();
        return navigate(`/agent/${agent.agentId}`);
      },
    }
  );

  const handleSubmit = (agent: Agent) => {
    const { agentId, knfLink, linkedinLink, calendarEmail, description, emailSchema } = agent;

    const payload = {
      payload: {
        knfLink,
        linkedinLink,
        calendarEmail,
        description: description?.replace(/\n/g, '<br>') || '',
        emailSchema: emailSchema?.replace(/\n/g, '<br>') || '',
      },
      agentId,
    };

    mutate(payload);
  };

  const handleReset = () => navigate(`/agent/${agent.agentId}`);

  const { knfLink, linkedinLink, calendarEmail, description, emailSchema } = agent;

  const initialValues = useMemo(
    () => ({
      ...agent,
      knfLink: knfLink ? knfLink : '',
      phone: (agent.phone?.length === 9 ? '48' : '') + agent.phone,
      linkedinLink: linkedinLink ? linkedinLink : '',
      calendarEmail: calendarEmail ? calendarEmail : '',
      description: description ? description.replaceAll('<br>', '\n') : '',
      emailSchema: emailSchema ? emailSchema.replaceAll('<br>', '\n') : '',
    }),
    [agent, description, linkedinLink, calendarEmail, knfLink, emailSchema]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onReset={handleReset}
      onSubmit={handleSubmit}>
      {({ values }) => (
        <Form className="space-y-8 px-32">
          <InputField label="Imię" name="firstName" isDisabled />
          <InputField label="Nazwisko" name="lastName" isDisabled />
          <PhonePrefixInputField label="Telefon" name="phone" isDisabled />
          <InputField label="Email" name="email" isDisabled />
          <InputField label="Link do KNF" name="knfLink" />
          <InputField label="Link do LinkedIn" name="linkedinLink" />
          <div>
            <Examples />
            <TextareaField
              label="Opis"
              maxLength={maxCharactersLength}
              name="description"
              rows={5}
            />
            <Text className="w-fit ml-auto">
              Pozostało: {maxCharactersLength - values.description?.length} znaków
            </Text>
          </div>
          <p>Ustawienia</p>
          <hr className="!mt-0" />
          <InputField label="Email do kalendarza" name="calendarEmail" />
          <div>
            <TextareaField
              label="Własny szablon wiadomości e-mail"
              maxLength={maxEmailSchemaCharactersLength}
              name="emailSchema"
              rows={5}
            />
            <Text className="w-fit ml-auto">
              Pozostało: {maxEmailSchemaCharactersLength - values.emailSchema?.length} znaków
            </Text>
          </div>
          <ModalActions />

          {isError && <Alert type="error">{error?.message}</Alert>}
        </Form>
      )}
    </Formik>
  );
};

export default AgentForm;
