import { Icon, Loader, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { addDays, format } from 'date-fns';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useMutation, useQuery } from 'react-query';

import useNotificationStore from 'hooks/useNotificationStore';
import { deleteMeetingDate, getMeetingDate, postMeetingDate } from 'services/summary';

import SidebarButton from './SidebarButton';

type Props = {
  assessmentId: number;
  agentId: number;
};

function Appointment({ assessmentId, agentId }: Props) {
  const [date, setDate] = useState<Date | null>();
  const [showDateInput, setShowDateInput] = useState(false);
  const { addNotification, removeNotification } = useNotificationStore();

  const { refetch } = useQuery<Date, AxiosError>(
    ['meetingDate', assessmentId, agentId],
    () => getMeetingDate(assessmentId, agentId),
    {
      onSuccess: (date) => {
        setDate(date ? new Date(date) : null);
        setShowDateInput(!!date);
      },
    }
  );

  const { isLoading, mutate: postMeeting } = useMutation<AxiosResponse, AxiosError, string>(
    (date) => postMeetingDate(assessmentId, agentId, date),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Wystąpił błąd podczas wybierania daty spotkania',
          type: 'error',
        });
      },
      onSuccess: () => {
        removeNotification('postArea-error');
        addNotification({
          id: 'postArea-autosave',
          title: 'Spotkanie zostało pomyslnie dodane',
          type: 'success',
        });
      },
    }
  );

  const { mutate: deleteMeeting } = useMutation<AxiosResponse, AxiosError>(
    () => deleteMeetingDate(assessmentId, agentId),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const saveMeeting = () => {
    if (!date) return;
    postMeeting(format(new Date(date), 'yyyy-MM-dd HH:mm:ss'));
  };

  return (
    <div className="relative my-8">
      {isLoading && (
        <div className="bg-white opacity-80 absolute inset-0 flex align-middle justify-center z-10">
          <Loader className="h-10 w-10" />
        </div>
      )}

      {showDateInput ? (
        <div className="p-4 border-2">
          <DatePicker
            className="w-full"
            dateFormat="dd-MM-yyyy HH:mm"
            placeholderText="Wybierz datę"
            timeIntervals={15}
            minDate={addDays(new Date(), 0)}
            timeFormat="HH:mm"
            showTimeSelect
            onChange={setDate}
            portalId="root-portal"
            selected={date}
          />
          <SidebarButton className="my-2" onPress={saveMeeting} isDisabled={!date}>
            <Icon name="check" custom={false} className="mr-3 w-4" />
            <Text>Zatwierdź</Text>
          </SidebarButton>

          <SidebarButton onPress={() => deleteMeeting()}>
            <Icon name="x-octagon" custom={false} className="mr-3 w-4" />
            <Text>Anuluj spotkanie</Text>
          </SidebarButton>
        </div>
      ) : (
        <SidebarButton variant="outline-primary" onPress={() => setShowDateInput(true)}>
          <Text weight="bold" className="mx-auto">
            Umów spotkanie +
          </Text>
        </SidebarButton>
      )}
    </div>
  );
}

export default Appointment;
