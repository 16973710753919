import { SliderProps } from '@profitowi/component-library/dist/types/Slider/Slider';
import { useField } from 'formik';
import React from 'react';

import Slider from 'components/Slider';

type Props = SliderProps & { name: string };

const SliderStringField = (props: Props) => {
  const [field, , helpers] = useField<string>(props.name);

  const handleChange = ([value]: number[]) =>
    helpers.setValue(value < 0 ? '' : props.options[value]);

  return (
    <Slider
      {...props}
      onChangeEnd={handleChange}
      defaultValue={[field.value ? props.options.findIndex((v: string) => v === field.value) : -1]}
    />
  ); // initialize with the index of answer or -1 if there is none
};

export default SliderStringField;
