import { Tab } from '@headlessui/react';
import { Alert, Loader, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import InfoHint from 'components/InfoHint';
import PageContent from 'components/PageContent';
import SubjectNavigation from 'components/SubjectNavigation';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore } from 'hooks/useStore';
import {
  createEmptyAForm,
  createEmptyAFormSubject,
  getAFormData,
  getClientsAFormsInfo,
  getFormData,
} from 'services/forms';
import { getClientName } from 'utils/common';

import { AFormData, ClientsAFormsInfo, FormData } from '../../types/forms';
import AForm from './AForm';

const AssessmentForm = () => {
  const [goodAForm, setGoodAForm] = useState<any>();
  const agent = useAgentStore((state) => state.agent);
  const assessment = useAssessmentStore((state) => state.assessment);
  const { aClientId: paramAClientId = '', formId } = useParams();
  const aClientId = useMemo(() => parseInt(paramAClientId, 10), [paramAClientId]);
  const client = useMemo(
    () => assessment.clients?.find((client) => client?.aclientId === aClientId),
    [aClientId, assessment.clients]
  );

  const formData = useQuery<FormData, AxiosError>(['formInfo', formId], () => getFormData(formId));

  const validClientAForm = useCallback(
    (formData: FormData, clientsAForms: ClientsAFormsInfo[]) =>
      clientsAForms.find(
        (aForm) =>
          aForm.aclientId === client?.aclientId &&
          !aForm.expired &&
          aForm.formCode === formData.code
      ),
    [client?.aclientId]
  );

  const clientsAForms = useQuery<ClientsAFormsInfo[], AxiosError>(
    ['clientsAForms', agent.agentId, assessment.assessmentId],
    () => getClientsAFormsInfo(agent.agentId, assessment.assessmentId),
    {
      enabled: formData.isSuccess,
      onSuccess: (data) => {
        const aClientAForms = data.filter((aForm) => aForm.aclientId === client?.aclientId);
        const validAForm = validClientAForm(formData.data!, aClientAForms);

        if (validAForm) {
          setGoodAForm(validAForm);
        } else {
          createAForm.mutate();
        }
      },
    }
  );

  const createAForm = useMutation<AxiosResponse, AxiosError>(
    () => createEmptyAForm(agent.agentId, assessment.assessmentId, client?.aclientId, formId),
    {
      mutationKey: 'createEmptyAForm',
      onSuccess: () => {
        clientsAForms.refetch();
      },
    }
  );

  const aFormData = useQuery<AFormData, AxiosError>(
    [
      'aFormData',
      agent.agentId,
      assessment.assessmentId,
      client?.aclientId,
      formId,
      goodAForm?.aformId,
    ],
    () =>
      getAFormData(
        agent.agentId,
        assessment.assessmentId,
        client?.aclientId,
        formId,
        goodAForm?.aformId
      ),
    {
      enabled: !!goodAForm,
    }
  );

  const createAFormSubject = useMutation<AxiosResponse, AxiosError>(
    () =>
      createEmptyAFormSubject(
        agent.agentId,
        assessment.assessmentId,
        client?.aclientId,
        formId,
        aFormData.data?.aformId
      ),
    {
      mutationKey: 'createEmptyAFormSubject',
      onSuccess: () => {
        aFormData.refetch();
      },
    }
  );

  const cancelEffect = () => {
    aFormData.refetch();
  };

  if (formData.isError || clientsAForms.isError || aFormData.isError)
    return (
      <Alert type="error">
        {formData.error?.message || clientsAForms.error?.message || aFormData.error?.message}
      </Alert>
    );

  if (formData.isLoading || clientsAForms.isLoading || clientsAForms.isRefetching) {
    return <Loader className="h-12 w-12 m-6" />;
  }

  if (formData.isSuccess && clientsAForms.isSuccess) {
    return (
      <PageContent
        title={
          <div className="flex space-x-2">
            {formData.data.comment && <InfoHint type="info" contents={formData.data.comment} />}
            <span>{formData.data.name}</span>
          </div>
        }>
        <Text weight="semibold" className="text-light">
          Wersja dokumentu {formData.data.version}
        </Text>

        {aFormData.isLoading && <Loader className="h-12 w-12 m-6" />}
        {aFormData.isSuccess && (
          <SubjectNavigation
            subjects={aFormData.data.subjects}
            multiSubject={formData.data.multiSubject}
            clientName={getClientName(client!)}
            createNewSubject={createAFormSubject}>
            {aFormData.data.subjects.map(({ aformSubjectId }, index, array) => (
              <Tab.Panel key={index}>
                <AForm
                  aClientId={client?.aclientId}
                  aformId={aFormData.data.aformId}
                  aformSubjectId={aformSubjectId}
                  formData={formData.data}
                  cancelEffect={cancelEffect}
                  subjectCount={array.length}
                  updateDescriptions={() => {
                    aFormData.refetch();
                  }}
                />
              </Tab.Panel>
            ))}
          </SubjectNavigation>
        )}
      </PageContent>
    );
  }

  return null;
};

export default AssessmentForm;
