import { Box, Loader } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { usePageLayout } from 'hooks/usePageLayout';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import {
  cancelAFormForAFormSubject,
  getAFormSubjectData,
  getClientsAFormsInfo,
  saveAAnswers,
} from 'services/forms';
import { ASSESSMENT_TYPE, FORM_STATE } from 'types/assessment';
import { AFormSubjectData, ClientsAFormsInfo, FileUploadContext, FormData } from 'types/forms';
import { createValidationSchema } from 'utils/yupHelpers';

import FormBody from './FormBody';
import { aanswersToInitialValues, valuesToValidAAnswers } from './helpers';

type AFormProps = {
  aClientId?: number;
  aformId: number;
  aformSubjectId: number;
  formData: FormData;
  cancelEffect: () => void;
  subjectCount: number;
  updateDescriptions: () => void;
};

const AForm = ({
  aClientId,
  aformId,
  aformSubjectId,
  cancelEffect,
  formData,
  subjectCount,
  updateDescriptions,
}: AFormProps) => {
  const navigate = useNavigate();
  const { formId } = useParams();
  const { setDisplayedNotCompleted } = usePageLayout();
  const { validateSteps } = useStepValidation();
  const [currentState, setCurrentState] = useState<FORM_STATE>();

  const { addNotification, removeNotification } = useNotificationStore();

  const agentId = useAgentStore((state) => state.agent.agentId);
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId);

  const validationSchema = useMemo(
    () => createValidationSchema(formData.questions),
    [formData.questions]
  );

  const aFormSubjectData = useQuery<AFormSubjectData, AxiosError>(
    ['aFormSubjectData', agentId, assessmentId, aClientId, formId, aformId, aformSubjectId],
    () => getAFormSubjectData(agentId, assessmentId, aClientId, formId, aformId, aformSubjectId)
  );

  const clientsAForms = useQuery<ClientsAFormsInfo[], AxiosError>(
    ['cafi', agentId, assessmentId],
    () => getClientsAFormsInfo(agentId, assessmentId),
    {
      onSuccess: () => {
        validateSteps(ASSESSMENT_TYPE.FORM);
      },
    }
  );

  const save = useMutation<AxiosResponse, AxiosError, any>(
    (payload) =>
      saveAAnswers(payload, agentId, assessmentId, aClientId, formId, aformId, aformSubjectId),
    {
      onError: (error) => {
        addNotification({
          id: 'postArea-error',
          message: error.message,
          title: 'Wystąpił błąd podczas zapisywania danych',
          type: 'error',
        });
      },
      onSuccess: () => {
        addNotification({
          id: 'postArea-autosave',
          title: 'Automatyczne zapisywanie',
          type: 'info',
        });
        clientsAForms.refetch();
        updateDescriptions();
        removeNotification('postArea-error');
      },
    }
  );

  const cancel = useMutation<AxiosResponse, AxiosError, any>(
    () =>
      cancelAFormForAFormSubject(agentId, assessmentId, aClientId, formId, aformId, aformSubjectId),
    {
      onSuccess: () => {
        if (subjectCount <= 1) navigate('/yourNeeds');
        else cancelEffect();
      },
    }
  );

  const getCurrentState = (hasValue: boolean, errors: {}) => {
    if (!hasValue) return FORM_STATE.NEW;
    if (isEmpty(errors) && hasValue) return FORM_STATE.COMPLETED;
    return FORM_STATE.NOT_COMPLETED;
  };

  const customHandleSubmit = (formikValues: any, errors?: any, questions?: any) => {
    const values = formikValues as { [char: string]: any };
    const hasValue = !!Object.values(values).find((val) => val.length);

    if (isEmpty(errors) || !currentState) setCurrentState(getCurrentState(hasValue, errors));

    const payload = {
      state: getCurrentState(hasValue, errors),
      aAnswers: valuesToValidAAnswers(values, errors, questions, aFormSubjectData.data?.aanswers),
    };
    save.mutate(payload);
    setDisplayedNotCompleted(hasValue && currentState !== FORM_STATE.NEW && !isEmpty(errors));
  };

  const onCancel = () => {
    cancel.mutate({});
  };

  if (aFormSubjectData.isLoading || aFormSubjectData.isRefetching)
    return (
      <Box className="!px-5 !py-12">
        <Loader className="h-12 w-12" />
      </Box>
    );

  const context: FileUploadContext = {
    agentId: agentId,
    assessmentId: assessmentId,
    aClientId: aClientId,
    id: formId,
    aId: aformId,
    aSubjectId: aformSubjectId,
  };

  return (
    <Box className="!px-5 !py-12">
      <Formik
        initialValues={aanswersToInitialValues(
          formData.questions ?? [],
          aFormSubjectData.data?.aanswers
        )}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        validateOnMount>
        <FormBody
          hackySubmit={customHandleSubmit}
          questions={formData.questions}
          onCancel={onCancel}
          context={context}
        />
      </Formik>
    </Box>
  );
};

export default AForm;
