import { Modal, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import ModalActions from 'components/ModalActions';
import { requiredFieldMessage } from 'constants/requiredFieldMessage';
import { addDocumentsAttachment, addDocumentsIda } from 'services/documents';

const validationSchema = yup.object({
  file: yup.mixed().required(requiredFieldMessage),
});

type FormValues = yup.InferType<typeof validationSchema>;
type Props = {
  isProductIda?: boolean;
  refetchProducts: () => void;
};

const FileForm = ({ isProductIda, refetchProducts }: Props) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const productId = state?.productId;

  const {
    mutate: mutateDocument,
    isLoading: documentIsLoading,
    isError: documentIsError,
    error: documentError,
  } = useMutation<AxiosResponse<any>, AxiosError, any>(
    ({ file, fileName }) => {
      if (isProductIda) return addDocumentsIda(file, fileName, productId);
      return addDocumentsAttachment(file, productId, fileName);
    },
    {
      onSuccess: () => {
        navigate(-1);
        refetchProducts();
      },
    }
  );

  const onChange = (form: any, e: any) => {
    form.setFieldValue('file', e.target.files);
  };

  const onSubmit = (values: FormValues) => {
    const { file } = values;
    const formData = new FormData();

    Object.keys(file).map((key) => formData.append('file', file[key]));

    const payload = {
      file: formData,
    };

    mutateDocument(payload);
  };

  return (
    <Modal title="Dodaj dokument" visible>
      <Formik
        validateOnMount
        initialValues={{
          file: null,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ errors, touched }) => (
          <Form className="space-y-8">
            <div className="mt-12">
              <Field name="file">
                {({ form }: any) => (
                  <input multiple type="file" name="file" onChange={(e) => onChange(form, e)} />
                )}
              </Field>

              {errors.file && touched.file ? (
                <Text size="sm" className="text-secondary-red">
                  {errors.file}
                </Text>
              ) : null}
            </div>

            <ModalActions
              className="mt-8"
              isLoading={documentIsLoading}
              confirmLabel="Załaduj Plik"
              isConfirmDisabled={!!Object.values(errors).length}
              onCancel={() => navigate(-1)}
            />

            {documentIsError && <ErrorMessages error={documentError} />}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default FileForm;
