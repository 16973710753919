import { Button, Icon } from '@profitowi/component-library';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { DocumentsIda } from 'types/documents';

export const columns: Column<DocumentsIda>[] = [
  {
    id: 'expander',
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, toggleAllRowsExpanded }) => {
      return (
        <span
          {...getToggleAllRowsExpandedProps()}
          onClick={() => {
            if (isAllRowsExpanded) {
              toggleAllRowsExpanded(false);
            } else {
              toggleAllRowsExpanded(true);
            }
          }}>
          {isAllRowsExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      );
    },
    Cell: ({ row }: Cell<DocumentsIda>) => {
      return row.original.attachmentDataList?.filter((item) => item !== null).length ? (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <Icon name="caret-down-fill" /> : <Icon name="caret-right-fill" />}
        </span>
      ) : null;
    },
  },
  {
    Header: 'Nazwa produktu',
    accessor: 'name',
    sortType: 'string',
  },
  {
    Header: 'Skrót',
    accessor: 'shortcut',
    sortType: 'string',
  },
  {
    id: 'operations',
    Cell: ({
      row: {
        original: { attachmentDataList, productId },
      },
    }: Cell<DocumentsIda>) => {
      const isAttachmentList = !!attachmentDataList.length;
      const navigate = useNavigate();

      return (
        <div className="flex space-x-6 justify-end">
          <Button
            variant="light"
            onPress={() =>
              navigate('addIda', {
                state: {
                  productId,
                },
              })
            }
            isDisabled={isAttachmentList}>
            Dodaj dokument
          </Button>
        </div>
      );
    },
  },
];
