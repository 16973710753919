import { Modal } from '@profitowi/component-library';
import React, { useState } from 'react';

import { InfoEntry, infoGroups } from './infoGroups';

type InfoHintContentsProps = {
  contents: string;
};

type EntryProps = {
  infoEntry: InfoEntry;
};

const linkStyle = 'cursor-pointer underline text-blue-800';

const Entry = ({ infoEntry }: EntryProps) => {
  const [showEntry, setShowEntry] = useState(false);
  const { element, content, linkText } = infoEntry;

  return (
    <>
      {element}
      {linkText && (
        <p>
          <a
            className={linkStyle}
            onClick={(e) => {
              setShowEntry((prev) => !prev);
            }}>
            {linkText}
          </a>
          <Modal visible={showEntry} setVisible={setShowEntry} hasCloseButton title="">
            {content}
          </Modal>
        </p>
      )}
    </>
  );
};

const InfoHintContents = ({ contents }: InfoHintContentsProps) => {
  return (
    <>
      {contents.match(/^group_.*$/) ? (
        infoGroups[contents.replace('group_', '')].map((subgroup) =>
          subgroup.map((entry) => <Entry infoEntry={entry} />)
        )
      ) : (
        <div dangerouslySetInnerHTML={{ __html: contents }}></div>
      )}
    </>
  );
};

export default InfoHintContents;
