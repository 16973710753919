import { ASurveyData, ASurveySubjectData, SurveysStates } from 'types/surveys';
import http from 'utils/axios';

type StrNumUndef = string | number | undefined;

export const getSurveysAndStates = async (agentId: StrNumUndef, assessmentId: StrNumUndef) => {
  const { data } = await http.get<SurveysStates>(
    `/agents/${agentId}/assessments/${assessmentId}/surveys`
  );
  return data;
};

export const getSurveyData = async (surveyId: StrNumUndef) => {
  const { data } = await http.get(`/surveys/${surveyId}`);
  return data;
};

export const getASurveyData = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  surveyId: StrNumUndef,
  aSurveyId: StrNumUndef
) => {
  const { data } = await http.get<ASurveyData>(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/surveys/${surveyId}/aSurveys/${aSurveyId}/baseData`
  );
  return data;
};

export const getASurveySubjectData = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  surveyId: StrNumUndef,
  aSurveyId: StrNumUndef,
  aSurveySubjectId: StrNumUndef
) => {
  const { data } = await http.get<ASurveySubjectData>(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/surveys/${surveyId}/aSurveys/${aSurveyId}/aSurveySubjects/${aSurveySubjectId}/answers`
  );
  return data;
};

export const createEmptyASurvey = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  surveyId: StrNumUndef
) => {
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/surveys/${surveyId}/aSurveys/create`
  );
};

export const createEmptyASurveySubject = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  surveyId: StrNumUndef,
  aSurveyId: StrNumUndef
) => {
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/surveys/${surveyId}/aSurveys/${aSurveyId}/aSurveySubjects/create`
  );
};

export const saveAAnswers = async (
  payload: { state: string; aAnswers: { value: string; aAnswerId: number }[] },
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  surveyId: StrNumUndef,
  aSurveyId: StrNumUndef,
  aSurveySubjectId: StrNumUndef
) => {
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/surveys/${surveyId}/aSurveys/${aSurveyId}/aSurveySubjects/${aSurveySubjectId}/answers`,
    payload
  );
};

export const cancelASurveyForASurveySubject = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  surveyId: StrNumUndef,
  aSurveyId: StrNumUndef,
  aSurveySubjectId: StrNumUndef
) => {
  return http.delete(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/surveys/${surveyId}/aSurveys/${aSurveyId}/aSurveySubjects/${aSurveySubjectId}`
  );
};

export const uploadAttachment = async (
  payload: FormData,
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  surveyId: StrNumUndef,
  aSurveyId: StrNumUndef,
  aSurveySubjectId: StrNumUndef,
  questionCode: StrNumUndef,
  fileName?: StrNumUndef,
  attachmentType?: StrNumUndef
) => {
  return http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/surveys/${surveyId}/aSurveys/${aSurveyId}/aSurveySubjects/${aSurveySubjectId}/attachment?questionCode=${questionCode}${
      fileName ? `&fileName=${fileName}` : ''
    }${attachmentType ? `&attachmentType=${attachmentType}` : ''}`,
    payload
  );
};

export const deleteFileById = async (
  agentId: StrNumUndef,
  assessmentId: StrNumUndef,
  aClientId: StrNumUndef,
  surveyId: StrNumUndef,
  aSurveyId: StrNumUndef,
  aSurveySubjectId: StrNumUndef,
  fileId: StrNumUndef
) => {
  return http.delete(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/surveys/${surveyId}/aSurveys/${aSurveyId}/aSurveySubjects/${aSurveySubjectId}/files/${fileId}`
  );
};
