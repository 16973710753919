import axios from 'axios';

axios.defaults.withCredentials = true;

const { REACT_APP_API_URL, NODE_ENV } = process.env;

const http = axios.create({
  baseURL: NODE_ENV === 'development' ? '/apk' : REACT_APP_API_URL,
});

http.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('__token');

    config.headers = {
      'content-type': 'application/json',
      'Access-Token': `${token}`,
      ...(config.headers || {}),
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      alert('Twoja sesja wygasła. Zaloguj się ponownie');
      sessionStorage.clear();
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

export default http;
