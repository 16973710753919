import { Box, Icon, Text } from '@profitowi/component-library';

import useNotificationStore from 'hooks/useNotificationStore';

const Notifications = () => {
  const { notifications } = useNotificationStore();

  return (
    <div className="space-y-4">
      {notifications.map(({ id, message, title, type }) => (
        <Box className="flex flex-col items-center space-y-2" shadow="md" key={id}>
          {type === 'error' && (
            <Icon name="exclamation-octagon" className="text-rose-600 text-2xl" />
          )}
          {type === 'warning' && (
            <Icon name="exclamation-triangle" className="text-rose-600 text-2xl" />
          )}
          {type === 'info' && <Icon name="arrow-clockwise" className="text-primary text-2xl" />}
          {type === 'success' && <Icon name="check-circle-fill" className="text-light text-2xl" />}
          <Text className="text-center" size="md" weight="bold">
            {title}
          </Text>
          {message && <Text>{message}</Text>}
        </Box>
      ))}
    </div>
  );
};

export default Notifications;
