import { orderBy } from 'lodash';

import { AAnswer, Answer, CONDITION_TYPE, FIELD_TYPE, Question } from '../../types/forms';
import { isQuestionVisible } from './FormBody';

const NUMBER_NOT_ANSWERED = -1;

export const arrayToString = (array: string[]) =>
  array.reduce((acc, current, index) => `${acc}${index ? '|' : ''}${current}`, '');

export const stringToArray = (str: string) => {
  return str.split('|').filter((s) => s);
};

export const stringToFieldValue = (str: string, fieldType: string) => {
  switch (fieldType) {
    case FIELD_TYPE.CHECKBOX:
      return stringToArray(str);
  }
  return str;
};

export const fieldValueToString = (value: any, question: Question) => {
  if (Array.isArray(value)) {
    return arrayToString(
      orderBy(question.answers, ['orderNo'], ['asc'])
        .map((answer: Answer) => value.find((value) => value === answer.value))
        .filter((value) => value)
    );
  }
  if (typeof value === 'number') return value === NUMBER_NOT_ANSWERED ? '' : String(value);
  return value;
};

export const isConditionFulfilled = (value: any, conditionType: string, conditionValue: string) => {
  if (!value) {
    return false;
  }

  switch (conditionType) {
    case CONDITION_TYPE.EQUAL:
      if (Array.isArray(value)) {
        return value.includes(conditionValue);
      }

      return value === conditionValue;

    case CONDITION_TYPE.NOT_EQUAL:
      if (Array.isArray(value)) {
        return !value.includes(conditionValue);
      }

      return value !== conditionValue;

    case CONDITION_TYPE.GREATER:
      return Number(value) > Number(conditionValue);

    case CONDITION_TYPE.LESS:
      return Number(value) < Number(conditionValue);

    default:
      return false;
  }
};

export const answerIsValid = (errors: any, key: any) => !errors.hasOwnProperty(key);

export const valuesToValidAAnswers = (
  values: { [field: string]: any },
  errors: { [field: string]: any },
  questions: Question[],
  aanswers: any
) => {
  return Object.keys(values).map((key) => {
    const question = questions?.find((q) => q.code === key);
    if (!question) return null;
    const allowedValue =
      answerIsValid(errors, key) && isQuestionVisible(question, values) ? values[key] : '';
    return {
      value: fieldValueToString(allowedValue, question),
      aAnswerId: aanswers.find((aanswer: AAnswer | undefined) => aanswer?.questionCode === key)
        ?.aanswerId,
    };
  });
};

export const aanswersToInitialValues = (questions: Question[], aanswers: AAnswer[] | undefined) => {
  const init: { [char: string]: any } = {};

  questions?.forEach((question) => {
    const originalValue = aanswers?.find(
      (aanswer) => question.code === aanswer.questionCode
    )?.value;
    const adjustedValue = originalValue ? originalValue : '';
    return Object.assign(init, {
      [question.code]: stringToFieldValue(adjustedValue, question.answerType.fieldType ?? ''),
    });
  });

  return init;
};
