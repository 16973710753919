import messageIcon from 'assets/summaryPdfIcons/messageIcon.png';
import offers from 'assets/summaryPdfIcons/offers.png';
import options from 'assets/summaryPdfIcons/options.png';

import Header from '../components/Header';

const styles = {
  page: {
    backgroundColor: '#FFFFFF',
    height: '450px',
  },
  body: {
    height: '100%',
  },
  content: {
    container: {
      display: 'table',
      height: '60%',
      paddingLeft: '25px',
      margin: 'auto',
    },
    section: {
      width: '100%',
      display: 'table',
      paddingTop: '60px',
    },
    imageContainer: {
      display: 'table-cell',
      verticalAlign: 'middle',
      paddingRight: '20px',
    },
    text: {
      fontFamily: 'Sora',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '32px',
      display: 'table-cell',
    },
  },
};

const ThirdPage = () => {
  return (
    <div style={styles.page}>
      <div style={styles.body}>
        <Header text="Czego możesz się spodziewać?" />

        <div style={styles.content.container}>
          <div style={styles.content.section}>
            <div style={{ ...styles.content.imageContainer, width: '30%' }}>
              <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                <div style={styles.content.imageContainer}>
                  <img src={messageIcon} alt="" />
                </div>
                <span style={{ ...styles.content.text, wordWrap: 'break-word' }}>
                  Rozmawiamy i słuchamy Twoich potrzeb
                </span>
              </div>
            </div>

            <div style={{ ...styles.content.imageContainer, width: '30%' }}>
              <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                <div style={styles.content.imageContainer}>
                  <img src={offers} alt="" />
                </div>
                <span style={styles.content.text}>Porównujemy wszystkie oferty na rynku</span>
              </div>
            </div>
            <div style={{ ...styles.content.imageContainer, width: '30%' }}>
              <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                <div style={styles.content.imageContainer}>
                  <img src={options} alt="" />
                </div>
                <span style={styles.content.text}>
                  Przedstawiamy 3 najlepsze opcje oraz jasną rekomendację
                </span>
              </div>
            </div>
          </div>

          <div style={styles.content.section}>
            <div style={{ ...styles.content.imageContainer, width: '30%' }}>
              <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                <div style={styles.content.imageContainer}>
                  <img src={messageIcon} alt="" />
                </div>
                <span style={styles.content.text}>Nie namawiamy, zawsze to Ty decydujesz</span>
              </div>
            </div>
            <div style={{ ...styles.content.imageContainer, width: '30%' }}>
              <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                <div style={styles.content.imageContainer}>
                  <img src={messageIcon} alt="" />
                </div>
                <span style={styles.content.text}>Z Twoimi znajomymi pracujemy tak jak z Tobą</span>
              </div>
            </div>
            <div style={{ ...styles.content.imageContainer, width: '30%' }}>
              <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                <div style={styles.content.imageContainer}>
                  <img src={messageIcon} alt="" />
                </div>
                <span style={styles.content.text}>Mamy Ekspertów we wszystkich dziedzinach</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdPage;
