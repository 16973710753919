import { Tab } from '@headlessui/react';
import { Alert, Button, Loader, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import InfoHint from 'components/InfoHint';
import PageContent from 'components/PageContent';
import SubjectNavigation from 'components/SubjectNavigation';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore } from 'hooks/useStore';
import {
  createEmptyASurvey,
  createEmptyASurveySubject,
  getASurveyData,
  getSurveyData,
  getSurveysAndStates,
} from 'services/surveys';
import { ASurveyData, SurveyData, SurveyState, SurveysStates } from 'types/surveys';
import { getClientName } from 'utils/common';

import ASurvey from './ASurvey';

const Survey = () => {
  const [goodASurvey, setGoodASurvey] = useState<SurveyState>();
  const agent = useAgentStore((state) => state.agent);
  const assessment = useAssessmentStore((state) => state.assessment);
  const { aClientId: paramAClientId = '', surveyId } = useParams();
  const aClientId = useMemo(() => parseInt(paramAClientId, 10), [paramAClientId]);
  const client = useMemo(
    () => assessment.clients?.find((client) => client?.aclientId === aClientId),
    [aClientId, assessment.clients]
  );

  const surveyData = useQuery<SurveyData, AxiosError>(['surveyInfo', surveyId], () =>
    getSurveyData(surveyId)
  );

  const validClientASurvey = useCallback(
    (surveyData: SurveyData, surveyStates: SurveyState[]) =>
      surveyStates
        .filter((aSurvey) => aSurvey.aclientId === client?.aclientId)
        .find(
          (aSurvey) =>
            aSurvey.aclientId === client?.aclientId &&
            !aSurvey.expired &&
            aSurvey.surveyCode === surveyData.code
        ),
    [client?.aclientId]
  );

  const surveysAndStates = useQuery<SurveysStates, AxiosError>(
    ['surveysAndStates', agent.agentId, assessment.assessmentId],
    () => getSurveysAndStates(agent.agentId, assessment.assessmentId),
    {
      onSuccess: (data) => {
        const validASurvey = validClientASurvey(surveyData.data!, data.surveyStates);

        if (validASurvey) {
          setGoodASurvey(validASurvey);
        } else {
          createASurvey.mutate();
        }
      },
    }
  );

  const createASurvey = useMutation<AxiosResponse, AxiosError>(
    () => createEmptyASurvey(agent.agentId, assessment.assessmentId, client?.aclientId, surveyId),
    {
      mutationKey: 'createEmptyASurvey',
      onSuccess: () => {
        surveysAndStates.refetch();
      },
    }
  );

  const aSurveyData = useQuery<ASurveyData, AxiosError>(
    [
      'aSurveyData',
      agent.agentId,
      assessment.assessmentId,
      client?.aclientId,
      surveyId,
      goodASurvey?.asurveyId,
    ],
    () =>
      getASurveyData(
        agent.agentId,
        assessment.assessmentId,
        client?.aclientId,
        surveyId,
        goodASurvey?.asurveyId
      ),
    {
      enabled: !!goodASurvey,
    }
  );

  const createASurveySubject = useMutation<AxiosResponse, AxiosError>(
    () =>
      createEmptyASurveySubject(
        agent.agentId,
        assessment.assessmentId,
        client?.aclientId,
        surveyId,
        aSurveyData.data?.asurveyId
      ),
    {
      mutationKey: 'createASurveySubject',
      onSuccess: () => {
        aSurveyData.refetch();
      },
    }
  );

  const cancelEffect = () => {
    aSurveyData.refetch();
  };

  if (surveyData.isError || surveysAndStates.isError || aSurveyData.isError)
    return (
      <Alert type="error">
        {surveyData.error?.message || surveysAndStates.error?.message || aSurveyData.error?.message}
      </Alert>
    );

  if (surveyData.isLoading || surveysAndStates.isLoading || surveysAndStates.isRefetching) {
    return <Loader className="h-12 w-12 m-6" />;
  }

  if (surveyData.isSuccess && surveysAndStates.isSuccess) {
    return (
      <PageContent
        title={
          <div className="flex space-x-2">
            {surveyData.data.comment && <InfoHint type="info" contents={surveyData.data.comment} />}
            <span>{surveyData.data.name}</span>
          </div>
        }>
        <Text weight="semibold" className="text-light">
          Wersja dokumentu {surveyData.data.version}
        </Text>

        {(aSurveyData.isLoading || aSurveyData.isRefetching) && (
          <Loader className="h-12 w-12 m-6" />
        )}
        {aSurveyData.isSuccess && (
          <div>
            {surveyData.data.multiSubject && (
              <div className="flex justify-end">
                <Button
                  className="flex space-x-2"
                  isDisabled={createASurveySubject.isLoading}
                  size="sm"
                  onPress={() => createASurveySubject.mutate()}>
                  {createASurveySubject.isLoading && <Loader className="h-4 w-4 !border-white" />}
                  <span>Dodaj podmiot</span>
                </Button>
              </div>
            )}

            <SubjectNavigation
              subjects={aSurveyData.data.subjects}
              multiSubject={surveyData.data.multiSubject}
              createNewSubject={createASurveySubject}
              clientName={getClientName(client!)}>
              {aSurveyData.data.subjects.map(({ asurveySubjectId }, index, subjects) => (
                <Tab.Panel key={index}>
                  <ASurvey
                    aClientId={client?.aclientId}
                    asurveyId={aSurveyData.data.asurveyId}
                    asurveySubjectId={asurveySubjectId}
                    surveyData={surveyData}
                    cancelEffect={cancelEffect}
                    subjectCount={subjects.length}
                  />
                </Tab.Panel>
              ))}
            </SubjectNavigation>
          </div>
        )}
      </PageContent>
    );
  }

  return null;
};

export default Survey;
