import dots from 'assets/summaryPdfImages/dots.png';
import groupDots from 'assets/summaryPdfImages/groupDots.png';
import logo from 'assets/summaryPdfImages/logo.png';

import { months } from '../constants/moths';

const styles = {
  container: {
    maxHeight: '100%',
    height: '87%',
  },
  page: {
    width: '100%',
    height: '100%',
    display: 'table',
    background: '#011983',
  },
  content: {
    container: {
      display: 'table-cell',
      width: '90% ',
      height: '100%',
      verticalAlign: 'middle',
    },
    font: {
      fontSize: '22px',
      fontFamily: 'Sora',
      fontStyle: 'normal',
      fontWeight: '400',
      color: '#FFFFFF',
      display: 'inline-block',
      padding: '0px 30px',
      verticalAlign: 'middle',
    },
    userInfoContainer: {
      display: 'table',
      height: '30%',
      width: '100%',
      margin: '50px',
    },
    userText: {
      fontSize: '22px',
      fontFamily: 'Sora',
      fontStyle: 'normal',
      fontWeight: '400',
      color: '#FFFFFF',
      display: 'table-cell',
      verticalAlign: 'bottom',
    },
    asideRight: {
      background: '#19103B',
      width: '10%',
      height: '86%',
    },
    center: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    timestamp: {
      fontFamily: 'Sora',
      fontSize: '19px',
      fontWeight: '600',
      lineHeight: '24px',
      color: '#00E5C9',
    },
    footerSection: {
      width: '90%',
      height: '13%',
      padding: '0px 40px',
      display: 'table',
    },
    footerAside: {
      background: '#00E5C9',
      width: '10%',
      height: '13%',
    },
  },
  footerSection: {
    width: '100%',
    height: '15%',
    display: 'table',
  },
  footerRightBlock: {
    background: '#00E5C9',
    width: '10%',
    height: '15%',
    bottom: 0,
    right: 0,
  },
};

type Props = {
  assessmentDate: string;
  clientName: string;
};

const FirstPage = ({ assessmentDate, clientName }: Props) => {
  return (
    <section className="sheet">
      <div style={styles.container}>
        <div style={styles.page}>
          <div style={styles.content.container}>
            <div
              style={{
                display: 'table-cell',
                textAlign: 'center',
                verticalAlign: 'middle',
                width: '80%',
              }}>
              <img style={{ display: 'inline-block', textAlign: 'center' }} src={dots} alt="" />

              <span style={{ ...styles.content.font, textAlign: 'center' }}>
                {'Podsumowanie'}
                <br />
                {'naszego spotkania'}
              </span>
              <img
                style={{ display: 'inline-block', textAlign: 'center', padding: '0px 30px' }}
                src={groupDots}
                alt=""
              />
            </div>
            <div style={{ ...styles.content.userInfoContainer, textAlign: 'center' }}>
              <span style={styles.content.userText}>
                {clientName
                  .toLowerCase()
                  .split(' ')[0]
                  .charAt(clientName.toLowerCase().split(' ')[0].length - 1) === 'a'
                  ? 'Pani'
                  : 'Pan'}{' '}
                {clientName}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'table-cell',
              width: '10%',
              height: '100%',
              background: '#19103B',
            }}></div>
        </div>

        <div style={{ ...styles.footerSection }}>
          <div
            style={{
              display: 'table',
              width: '100%',
              marginRight: '10px',
              verticalAlign: 'middle',
            }}>
            <div style={{ ...styles.content.center, width: '80%' }}>
              <img src={logo} alt="" style={{ transform: 'scale(0.6)' }} />
            </div>
            <div style={styles.content.center}>
              <span style={{ ...styles.content.timestamp, textAlign: 'right' }}>
                {assessmentDate.slice(8, 10)} {months[parseInt(assessmentDate.slice(5, 7)) - 1]}{' '}
                {assessmentDate.slice(0, 4)}
              </span>
            </div>
          </div>

          <div
            style={{
              display: 'table-cell',
              width: '10%',
              background: '#00E5C9',
              height: '100%',
            }}></div>
        </div>
      </div>
    </section>
  );
};

export default FirstPage;
