import { Area, AreaField, AreaNames } from 'types/area';
import http from 'utils/axios';

export const getAllAreas = async () => {
  const { data } = await http.get<AreaNames>(`/areas`);
  return data;
};

export const getAreas = async (assessmentId: number, agentId: number) => {
  const { data } = await http.get<AreaField[]>(
    `/agents/${agentId}/assessments/${assessmentId}/areas`
  );
  return data;
};

export const postArea = async (
  assessmentId: number,
  aClientId: number,
  payload: Area,
  agentId: number
) => {
  const { data } = await http.post(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/areas`,
    payload
  );
  return data;
};
