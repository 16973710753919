import logo from 'assets/new-logo.png';

const styles = {
  container: {
    width: '100%',
  },
  center: {
    verticalAlign: 'middle',
  },
  text: {
    color: '#011983',
    fontSize: '24px',
    fontFamily: 'Sora',
    fontStyle: 'normal',
    fontWeight: 700,
  },
  th: {
    overflow: 'hidden',
  },
};

type Props = {
  text: string;
  children?: any;
};

const Header = ({ text, children }: Props) => {
  return (
    <div
      id="pageHeader"
      style={{
        marginTop: '7.5%',
        marginBottom: '2.5%',
        overflow: 'hidden',
      }}>
      <table className="navbar" style={{ ...styles.container, tableLayout: 'fixed' }}>
        <th
          style={{
            ...styles.center,
            width: '47%',
            textAlign: 'start',
          }}>
          <img style={{ transform: 'scale(0.85)', marginLeft: '-30px' }} src={logo} alt="" />
        </th>
        <th style={{ ...styles.center, ...styles.text, width: '25%', textAlign: 'start' }}>
          {text}
        </th>
        {/* Ta komórka zbiera całą pozostałą szerokość, aby inne się nie rozjechały */}
        <th></th>
      </table>
      {children}
    </div>
  );
};

export default Header;
