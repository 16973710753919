const style = {
  text: {
    fontSize: '19px',
    fontWeight: 700,
    color: '#011983',
    fontFamily: 'Sora',
  },
  divider: {
    height: '2px',
    width: '100%',
    backgroundColor: '#E1E1E1',
    margin: '5px 0px 25px',
  },
};

const SectionTitle = ({ text }: { text: string }) => (
  <div style={{ width: '70%', margin: 'auto', paddingTop: '30px' }}>
    <div style={style.text}>{text}</div>
    <div style={style.divider}></div>
  </div>
);

export default SectionTitle;
