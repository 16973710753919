import { Tab } from '@headlessui/react';
import { Alert, Avatar, Box, Button, Icon, Loader, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import AREAS from 'components/AreaIcons/areaIcons';
import ClientNavigation from 'components/ClientNavigation';
import ConfirmationModal from 'components/ConfirmationModal';
import PageContent from 'components/PageContent';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import SummaryModal from 'pages/Summary/SummaryModal';
import { createSummaryPdf, getSummary, getSummaryDataPdf, getSummaryPdf } from 'services/summary';
import { BasicClientData, StateType } from 'types/assessment';
import { SummaryData } from 'types/form';
import { AREA_TYPE } from 'types/forms';

import { useAgentStore } from '../../hooks/useAgentStore';
import CollapsibleContainer from './CollapsibleContainer/CollapsibleContainer';
import InsuranceTile from './InsuranceTile/InsuranceTile';
import RecommendationTile from './RecommendationTile/RecommendationTile';
import SummaryPdf from './SummaryPdf/SummaryPdf';
import UserTile from './UserTile/UserTile';

export default function Summary() {
  const clients = useAssessmentStore((state) => state.assessment.clients || []);

  return (
    <PageContent title="Podsumowanie">
      <ClientNavigation clients={clients}>
        {clients.map((client) => (
          <Tab.Panel key={client.clientId}>
            <ClientSummary client={client} />
          </Tab.Panel>
        ))}
      </ClientNavigation>

      <Routes>
        <Route path="summaryModal/*" element={<SummaryModal />} />
      </Routes>

      <Outlet />
    </PageContent>
  );
}

type Props = {
  client: BasicClientData;
};

const ClientSummary = ({ client }: Props) => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const navigate = useNavigate();
  const { assessmentId = NaN, state } = useAssessmentStore((state) => state.assessment);
  const agentId = useAgentStore((state) => state.agent.agentId);
  const { assessment } = useAssessmentStore();
  const { validateSteps } = useStepValidation();

  const { data } = useQuery<any, AxiosError>(['summaryPdfData'], () =>
    getSummaryDataPdf(assessmentId, agentId)
  );

  const {
    mutate: creatPdfMutate,
    isSuccess: createdPdfIsSuccess,
    isLoading: isLoadingPdf,
  } = useMutation<AxiosResponse, AxiosError, string>((htmlTemplate) =>
    createSummaryPdf(assessmentId, agentId, htmlTemplate)
  );

  useQuery<any, AxiosError>(
    ['summaryPdf', assessmentId, agentId],
    () => getSummaryPdf(agentId, assessmentId),
    {
      onSuccess: (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = window.URL.createObjectURL(blob);

        window.open(link, '_blank');
      },
      enabled: createdPdfIsSuccess,
    }
  );

  const validateAllSteps = () => {
    validateSteps(undefined, {
      onSuccess: (stateSteps) => {
        stateSteps.some(({ valid }) => !valid)
          ? setConfirmModalVisible(true)
          : navigate('summaryModal', { state: { aclientId: client.aclientId } });
      },
    });
  };

  const {
    data: summary,
    error,
    isError,
    isLoading,
    isSuccess,
  } = useQuery<SummaryData, AxiosError>(
    ['summaryData', assessmentId, client.aclientId, agentId],
    () => getSummary(assessmentId, client.aclientId, agentId)
  );

  const [firstAreaType, areaTypes] = useMemo(() => {
    if (isSuccess && summary?.areas?.length) {
      return [
        summary.areas[0].areaType,
        summary.areas.map((area) => AREAS[area.areaType as AREA_TYPE]?.name),
      ] as [AREA_TYPE, AREA_TYPE[]];
    }

    return [];
  }, [isSuccess, summary?.areas]);

  return (
    <>
      {isError && <Alert type="error">{error?.message}</Alert>}
      {isLoading && <Loader className="h-12 w-12" />}
      {isSuccess && summary && (
        <div className="space-y-12">
          <div className="space-y-4">
            <Text weight="bold" size="lg">
              Twoje dane
            </Text>

            {summary.client && summary.client.personalData.firstName?.length ? (
              <Box>
                <CollapsibleContainer
                  bgColor="bg-orange-400"
                  icon="person"
                  fieldNames={[
                    summary.client.personalData.firstName,
                    summary.client.personalData.lastName,
                  ]
                    .filter(Boolean)
                    .join(' ')}>
                  <UserTile client={summary.client} statements={summary.statementContents} />
                </CollapsibleContainer>
              </Box>
            ) : (
              <Alert type="info">Brak danych</Alert>
            )}
          </div>

          {assessment.type === 'FULL' && (
            <>
              <div className="space-y-4">
                <Text weight="bold" size="lg">
                  Twoje potrzeby
                </Text>
                {summary.areas?.length && firstAreaType && areaTypes ? (
                  <>
                    <Box>
                      <CollapsibleContainer
                        bgColor={AREAS[firstAreaType].bgColor}
                        custom={AREAS[firstAreaType].custom}
                        fieldNames={areaTypes}
                        icon={AREAS[firstAreaType].icon}>
                        {summary.areas.map((area, index) => (
                          <InsuranceTile area={area} key={area.areaType + index} />
                        ))}
                      </CollapsibleContainer>
                    </Box>
                  </>
                ) : (
                  <Alert type="info">Brak danych</Alert>
                )}
              </div>

              <div className="space-y-4">
                <Text weight="bold" size="lg">
                  Rekomendowane produkty
                </Text>

                {summary.recommendations?.length ? (
                  <Box>
                    <CollapsibleContainer
                      icon="bookmark-check"
                      fieldNames={summary.recommendations.map((product) => product.productName)}>
                      <div className="flex">
                        <Avatar
                          bg="bg-green-600"
                          className="mr-3 !h-16 !w-16 flex items-center justify-center">
                          <Icon name="bookmark-check" className="text-[36px]" />
                        </Avatar>
                        <div>
                          {summary.recommendations.map((recommendation, index) => (
                            <RecommendationTile
                              recommendation={recommendation}
                              key={recommendation.productName + index}
                            />
                          ))}
                        </div>
                      </div>
                    </CollapsibleContainer>
                  </Box>
                ) : (
                  <Alert type="info">Brak danych</Alert>
                )}
              </div>
            </>
          )}

          {/* HIDDEN UNTIL CLIENT CONFIRMATION */}
          {/* {(summary.surveys.length || assessment.type === 'SIMPLE') && (
            <div className="space-y-4">
              <Text weight="bold" size="lg">
                Twoje ankiety
              </Text>
              {summary.surveys.length ? (
                <>
                  <Box>
                    <CollapsibleContainer
                      bgColor="bg-rose-600"
                      fieldNames={summary.surveys.map((survey) => survey.name)}
                      icon="justify-right">
                      {summary.surveys.map((survey, i) => (
                        <SurveyTile key={survey.code + i} name={survey.name}></SurveyTile>
                      ))}
                    </CollapsibleContainer>
                  </Box>
                </>
              ) : (
                <Alert type="info">Brak danych</Alert>
              )}
            </div>
          )} */}

          <Box>
            <div className="flex space-x-4">
              <Button
                className="flex items-center"
                variant="outline-primary"
                onPress={() => creatPdfMutate(SummaryPdf(data))}
                isDisabled={isLoadingPdf}>
                Pobierz podsumowanie
                {isLoadingPdf ? (
                  <Loader className="w-5 h-6 mx-2 mt-1" />
                ) : (
                  <Icon name="download" className="ml-2 text-lg mr-2" />
                )}
              </Button>
              <Button isDisabled={state === StateType.FINISHED} onPress={() => validateAllSteps()}>
                Zakończ i wyślij do Klienta
                <Icon name="paper-plane" className="ml-2 text-md" />
              </Button>
            </div>
          </Box>
        </div>
      )}

      <ConfirmationModal
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        message="Wypełnij wszystkie wymagane dane"
        confirmText="Ok"
      />

      <Outlet />
    </>
  );
};
