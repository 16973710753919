import http from 'utils/axios';

import { RecommendationList } from './../types/recommendation';

export const getRecommendations = async (
  assessmentId: number,
  aClientId: number,
  agentId: number,
  search?: string
) => {
  const { data } = await http.get<RecommendationList[]>(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/recommendations`,
    {
      params: {
        search,
      },
    }
  );
  return data;
};

export const postRecommendations = async (
  assessmentId: number,
  recommendationId: number,
  isSelected: boolean,
  aClientId: number,
  agentId: number
) =>
  http.put(
    `/agents/${agentId}/assessments/${assessmentId}/clients/${aClientId}/recommendations/${recommendationId}?selected=${isSelected}`
  );
