import {
  CheckboxGroup,
  CheckboxGroupField,
  InputField,
  RadioGroup,
  RadioGroupField,
  TextareaField,
} from '@profitowi/component-library';
import clsx from 'clsx';
import { FormikValues, useFormikContext } from 'formik';
import { orderBy, upperFirst } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import Calculator from 'components/Calculator';
import FieldRow from 'components/FieldRow';
import FileUploadField from 'components/FileUploadField';
import SliderStringField from 'components/SliderStringField';

import { FIELD_TYPE, FileUploadContext, Question, SLIDE_SUBGROUPS } from '../../types/forms';
import DatePickerField from '../DatePickerField/DatePickerField';
import PhonePrefixInputField from '../PhonePrefixInputField/PhonePrefixInputField';

const OPTIONS = {
  YES_NO: ['TAK', 'NIE'],
  IMPORTANCE: ['nieważne', 'ważne', 'bardzo ważne'],
};

const getField = (question: Question, context: FileUploadContext) => {
  const { code, answerType, answers } = question;
  const placeholder = answerType.placeholder ?? '';

  switch (answerType.fieldType) {
    case FIELD_TYPE.TEXT_FIELD:
      return (
        <div className="grid grid-cols-3 gap-3">
          <div className="col-span-1">
            <InputField label={placeholder} name={code} />
          </div>
          {answerType.unit ? (
            <div className="h-full pt-5">
              <span className="font-semibold">{answerType.unit}</span>
            </div>
          ) : null}
        </div>
      );

    case FIELD_TYPE.PHONE_INPUT_FIELD:
      return (
        <div className="grid grid-cols-3 gap-3">
          <div className="col-span-1">
            <PhonePrefixInputField label={placeholder} name={code} />
          </div>
          {answerType.unit ? (
            <div className="h-full pt-5">
              <span className="font-semibold">{answerType.unit}</span>
            </div>
          ) : null}
        </div>
      );

    case FIELD_TYPE.TEXT_AREA:
      return <TextareaField label={placeholder} name={code} />;

    case FIELD_TYPE.FILE:
      return <FileUploadField name={code} context={context} />;

    case FIELD_TYPE.CALENDAR:
      return <DatePickerField label={placeholder} name={code} placeholder="" />;

    case FIELD_TYPE.CHECKBOX:
      return (
        <CheckboxGroupField name={code} orientation="vertical">
          {orderBy(question.answers, ['orderNo'], ['asc']).map((answer, index) => (
            <CheckboxGroup.Checkbox key={index} value={answer.value}>
              {answer.value}
            </CheckboxGroup.Checkbox>
          ))}
        </CheckboxGroupField>
      );

    case FIELD_TYPE.RADIOBUTTON:
      return (
        <RadioGroupField name={code} orientation="vertical">
          {answerType.subGroup === 'BOOLEAN_YES_NO_ENUM'
            ? OPTIONS.YES_NO.map((option, index) => (
                <RadioGroup.Radio key={index} value={option}>
                  {option}
                </RadioGroup.Radio>
              ))
            : orderBy(question.answers, ['orderNo'], ['asc']).map((answer, index) => (
                <RadioGroup.Radio key={index} value={answer.value}>
                  {answer.value}
                </RadioGroup.Radio>
              ))}
        </RadioGroupField>
      );

    case FIELD_TYPE.SLIDER:
      switch (answerType.subGroup) {
        case SLIDE_SUBGROUPS.PRIORITY_3_SLIDE_ENUM:
          return (
            <SliderStringField
              label=""
              name={code}
              maxValue={OPTIONS.IMPORTANCE.length - 1}
              options={OPTIONS.IMPORTANCE}
              step={1}
              className="mt-7 mx-10"
            />
          );
        case SLIDE_SUBGROUPS.SLIDE_ENUM:
          return (
            <SliderStringField
              label=""
              name={code}
              maxValue={answers.length - 1}
              options={orderBy(question.answers, ['orderNo'], ['asc']).map(
                (answer) => answer.value
              )}
              step={1}
              className="mt-7 mx-10"
            />
          );
        default:
          return null;
      }

    default:
      return null;
  }
};

type Props = {
  isVisible: boolean;
  question: Question;
  context: FileUploadContext;
};

const QuestionFieldRow = ({ isVisible, question, context }: Props) => {
  const { setFieldValue } = useFormikContext<FormikValues>();

  const { code, parentCode, content, info, hint, required } = question;
  const description = useMemo(() => upperFirst(content), [content]);

  useEffect(() => {
    if (!isVisible) {
      setFieldValue(code, undefined, false);
    }
  }, [code, isVisible, setFieldValue]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={clsx(parentCode && 'border-l-8 pl-6 py-6', !parentCode && 'my-10')}>
      <FieldRow description={description} info={info} hint={hint} required={required}>
        {getField(question, context)}
      </FieldRow>

      <Calculator code={question.code} />
    </div>
  );
};

export default QuestionFieldRow;
