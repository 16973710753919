import { Header, Text } from '@profitowi/component-library';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  title: string | ReactNode;
};

const PageContent = ({ children, title }: Props) => (
  <div className="py-6 space-y-6">
    <div className="space-y-2">
      <Header className="text-primary-dark" size="2xl" weight="semibold">
        {title}
      </Header>

      {/* FIXME: make actual breadcrumbs */}
      <Text className="text-secondary" size="sm" weight="bold">
        Analiza potrzeb Klienta
      </Text>
    </div>

    {children}
  </div>
);

export default PageContent;
