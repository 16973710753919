import { Text } from '@profitowi/component-library';
import { AriaTextFieldOptions, useTextField } from '@react-aria/textfield';
import clsx, { ClassValue } from 'clsx';
import React, { useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export type PhonePrefixInputProps = AriaTextFieldOptions<'input'> & {
  className?: ClassValue;
  phoneValue: string | undefined;
  setPhoneValue: (value: string) => void;
};

const PhonePrefixInput = (props: PhonePrefixInputProps & { errorMessage: string | undefined }) => {
  const {
    errorMessage,
    isRequired,
    label,
    validationState,
    isDisabled,
    className,
    phoneValue,
    setPhoneValue,
  } = props;
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, labelProps } = useTextField(props, ref);

  const isInvalid = validationState === 'invalid';

  return (
    <div className="relative label-floating">
      <div className="px-4 flex flex-col gap-2">
        <span className="text-sm text-gray-500 font-semibold cursor-text pointer-events-none transition">
          {label}
        </span>
        <PhoneInput
          {...inputProps}
          inputClass={clsx(
            '!py-0 !w-64 border-transparent border-2 text-sm placeholder-gray-900 font-semibold focus:ring-transparent focus:ring-0 focus:bg-white focus:border-gray focus:outline-none',
            {
              '!border-red-700 focus:!border-red-700': isInvalid,
            },
            className
          )}
          buttonClass={clsx('!top-1/2 -translate-y-1/2 h-full', {
            '!border-red-700 focus:!border-red-700': isInvalid,
          })}
          inputProps={{
            required: isRequired,
          }}
          preferredCountries={['pl']}
          disabled={isDisabled}
          placeholder={`${label ?? ''}${isRequired ? '*' : ''}`}
          country={'pl'}
          value={phoneValue}
          onChange={(phone) => setPhoneValue(phone)}
        />

        {label && (
          <label
            className="absolute left-3.5 top-3.5 text-sm text-transparent font-semibold cursor-text pointer-events-none transition"
            {...labelProps}>
            {label}
            {isRequired && <>*</>}
          </label>
        )}

        {isInvalid && (
          <Text size="sm" className="text-red-700">
            {errorMessage}
          </Text>
        )}
      </div>
    </div>
  );
};

export default PhonePrefixInput;
