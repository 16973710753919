import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ModalActions from 'components/ModalActions';
import { CLIENT } from 'constants/queries/client';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore, useCrmClient } from 'hooks/useStore';
import { createAssessment } from 'services/assessment';
import { getClient } from 'services/client';
import { AssessmentDto, AssessmentPayload } from 'types/assessment';
import { Client, ClientType } from 'types/client';

import CompanyDetails from './components/CompanyDetails';
import IndividualDetails from './components/IndividualDetails';

type Payload = {
  agentId: number;
  payload: AssessmentPayload;
  assessmentType: 'SIMPLE' | 'FULL';
};

type Props = {
  selectedCrmClientId: string;
  type?: string;
};

const CustomerDetails = ({ selectedCrmClientId, type }: Props) => {
  const { crmClientId, setCrmClientId } = useCrmClient();
  const setAssessment = useAssessmentStore((state) => state.setAssessment);
  const agentId = useAgentStore((state) => state.agent.agentId);
  const navigate = useNavigate();

  const { data: client } = useQuery<Client, AxiosError>(
    [CLIENT.CLIENT],
    () => {
      if (crmClientId?.length) return getClient(crmClientId, ClientType.INDIVIDUAL, agentId);
      return getClient(selectedCrmClientId, ClientType.INDIVIDUAL, agentId);
    },
    { enabled: crmClientId.length > 0 || selectedCrmClientId.length > 0 }
  );

  const { mutate } = useMutation<AssessmentDto, AxiosError, Payload>(
    (payload) => createAssessment(payload),
    {
      onSuccess: (assessment) => {
        setAssessment(assessment);
        navigate('/aboutProfitowi');
      },
    }
  );

  const onConfirm = () => {
    if (client && agentId) {
      const { id, clientType } = client.personalData;

      const payload = {
        agentId,
        payload: {
          clientId: id,
          clientType,
        },
        assessmentType: type as 'SIMPLE' | 'FULL',
      };

      mutate(payload);
    }
  };

  const onCancel = () => {
    sessionStorage.removeItem('crmClientId');
    setCrmClientId('');
    navigate('/');
  };

  const generateAddressInfo = () => {
    if (client) {
      const { street, zipCode, city, flatNo, houseNo } = client.personalData;
      if (!street || !city) return '-';
      return `${street ? street + ' ' + houseNo + (flatNo ? '/' + flatNo : '') : ''}${
        city || zipCode ? ',' + zipCode + ' ' + city : ''
      }`;
    }
  };

  return (
    <div className="flex-col content-center space-y-8">
      {client?.personalData.clientType === 'INDIVIDUAL' && (
        <IndividualDetails generateAddressInfo={generateAddressInfo} client={client} />
      )}
      {client?.personalData.clientType === 'COMPANY' && (
        <CompanyDetails generateAddressInfo={generateAddressInfo} client={client} />
      )}
      <ModalActions
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmLabel="Rozpocznij analizę"
        rightButtonIcon="arrow"
      />
    </div>
  );
};

export default CustomerDetails;
