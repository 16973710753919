import { Tab } from '@headlessui/react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Header,
  Icon,
  Loader,
  Option,
  Select,
  Tabs,
} from '@profitowi/component-library';
import logo from 'assets/new-logo.png';
import { AxiosError } from 'axios';
import { isAfter, isSameDay, isSameMonth, parseISO, startOfDay, subDays } from 'date-fns';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { AGENT } from 'constants/queries/agent';
import { useAgentStore } from 'hooks/useAgentStore';
import { useAssessmentStore, useCrmClient } from 'hooks/useStore';
import { getAgentAssessments } from 'services/agent';
import { getClientByCrmClientIdOrCreate } from 'services/client';
import { AssessmentDto, StateType } from 'types/assessment';
import { ClientInfo } from 'types/client';

import UserModal from './AgentModal';
import NewAnalysisModal from './NewAnalysisModal';
import MarketingConsentsForm from './components/MarketingConsentsForm/MarketingConsentsForm';
import TabTile from './components/TabTile';
import Tile from './components/Tile';

const values = [
  { value: 'Dziś', key: 'Dziś' },
  { value: 'Wczoraj', key: 'Wczoraj' },
  { value: 'Ten tydzień', key: 'Ten tydzień' },
  { value: 'Ten miesiąc', key: 'Ten miesiąc' },
  { value: 'Wszystko', key: 'Wszystko' },
];

const Dashboard = () => {
  const [selected, setSelected] = useState<Option>(values[0]);

  const agent = useAgentStore((state) => state.agent);
  const setAssessment = useAssessmentStore((state) => state.setAssessment);
  const crmClientId = useCrmClient((state) => state.crmClientId);
  const { isAdmin } = agent;

  const navigate = useNavigate();

  const { data, isSuccess: isGetClientSuccess } = useQuery<ClientInfo, AxiosError>(
    'clientId',
    () => getClientByCrmClientIdOrCreate(crmClientId, agent.agentId),
    {
      enabled: !!crmClientId,
    }
  );

  const {
    data: assessments = [],
    error,
    isError,
    isLoading,
    isSuccess,
  } = useQuery<AssessmentDto[], AxiosError>(
    [AGENT.AGENT_ASSESSMENTS, agent.agentId],
    () => getAgentAssessments(agent.agentId, data?.clientId),
    {
      enabled: !crmClientId || (!!crmClientId && isGetClientSuccess),
      select: (assessments) =>
        assessments.map((assessment) => ({
          ...assessment,
          clients: [...assessment.clients].sort((a, z) => a.aclientId - z.aclientId),
        })),
    }
  );

  const lastAssessment = useMemo(
    () => assessments.find(({ state }) => state === StateType.IN_PROGRESS),
    [assessments]
  );
  const filteredAssessments = useMemo(
    () =>
      assessments.filter(({ lastModificationDate }) => {
        const date = parseISO(lastModificationDate);

        if (selected.key === values[0].key) {
          return isSameDay(new Date(), date);
        }

        if (selected.key === values[1].key) {
          const yesterday = subDays(new Date(), 1);
          return isSameDay(yesterday, date);
        }

        if (selected.key === values[2].key) {
          const weekAgo = startOfDay(subDays(new Date(), 7));
          return isAfter(date, weekAgo);
        }

        if (selected.key === values[3].key) {
          return isSameMonth(new Date(), date);
        }

        return true;
      }),
    [assessments, selected]
  );

  const inProgressAssessments = useMemo(
    () => filteredAssessments.filter(({ state }) => state === StateType.IN_PROGRESS),
    [filteredAssessments]
  );
  const finishedAssessments = useMemo(
    () => filteredAssessments.filter(({ state }) => state === StateType.FINISHED),
    [filteredAssessments]
  );

  const openAssessment = (assessment: AssessmentDto) => {
    setAssessment(assessment);

    assessment.state === StateType.IN_PROGRESS && navigate('/aboutProfitowi');
    assessment.state === StateType.FINISHED && navigate('/summary');
  };

  const handleChange = (option: Option) => setSelected(option);

  return (
    <div className="mx-32 my-14">
      <Link to="/">
        <img alt="" src={logo} />
      </Link>

      <Header as="h1" size="xl" weight="bold" className="mt-8 mb-6 text-primary-dark">
        {`Dzień dobry, ${agent?.name.length > 0 && agent.name}`}
      </Header>

      <div className="flex items-center">
        <Button
          onPress={() => window.open(process.env.REACT_APP_CRM_URL, '_self')}
          variant="outline-primary"
          className="mr-3 h-16">
          Zmień klienta
        </Button>
        <Button onPress={() => navigate(`/agent/${agent.agentId}`)} variant="outline-primary">
          Moje dane <Icon name="person" className="text-xl" />
        </Button>
      </div>

      <div className="grid lg:grid-cols-3 gap-4 mt-8 mb-12">
        <Link to="/searchCustomer/FULL" className="flex">
          <Tile text="Nowa Analiza Potrzeb">
            <Avatar bg="bg-[#004EA8]">
              <Icon custom name="todo-list" />
            </Avatar>
          </Tile>
        </Link>
        {lastAssessment && (
          <button className="text-left flex" onClick={() => openAssessment(lastAssessment)}>
            <Tile text="Kontynuacja ostatniej analizy">
              <Avatar bg="bg-amber-400">
                <Icon name="pencil-square" className="text-2xl" />
              </Avatar>
            </Tile>
          </button>
        )}
        <Link to="/marketingConsents" className="flex">
          <Tile text="Zgody Marketingowe">
            <Avatar bg="bg-cyan-600">
              <Icon custom name="file" />
            </Avatar>
          </Tile>
        </Link>
        {isAdmin && (
          <Link to="/products" className="flex">
            <Tile text="Produkty i dokumenty">
              <Avatar bg="bg-cyan-600">
                <Icon custom name="file" />
              </Avatar>
            </Tile>
          </Link>
        )}

        {/* HIDDEN UNTIL CLIENT CONFIRMATION */}
        {/* <Link to="/searchCustomer/SIMPLE" className="flex">
          <Tile text="Ankiety">
            <Avatar bg="bg-rose-600">
              <Icon custom name="todo-list" />
            </Avatar>
          </Tile>
        </Link> */}
      </div>

      <Header as="h1" size="xl" weight="bold" className="text-primary-dark">
        Twoje analizy
      </Header>

      <Box className="py-5 p-6 mt-4 relative">
        {isError && <Alert type="error">{error?.message}</Alert>}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && (
          <>
            <div className="absolute right-5 top-4">
              <Select
                setSelected={handleChange}
                options={values}
                selected={selected}
                className="bg-gray-light max-w-min"
              />
            </div>

            <Tabs
              categories={['Najnowsze', 'Zakończone', 'Niedokończone']}
              className="text-primary-dark font-bold"
              variant="light">
              <Tab.Panel>
                {inProgressAssessments.length ? (
                  <div className="grid grid-cols-3 gap-8 min-h-[8.3rem]">
                    {inProgressAssessments.map((assessment, index) => (
                      <TabTile
                        assessmentId={assessment.assessmentId}
                        date={assessment.lastModificationDate}
                        onClick={() => openAssessment(assessment)}
                        tags={assessment.filledSteps}
                        key={assessment.clients[0]?.aclientId ?? index}
                        client={assessment.clients[0]}
                      />
                    ))}
                  </div>
                ) : (
                  <Alert type="info">Brak analiz.</Alert>
                )}
              </Tab.Panel>

              <Tab.Panel>
                {finishedAssessments.length ? (
                  <div className="grid grid-cols-3 gap-8 min-h-[8.3rem]">
                    {finishedAssessments.map((assessment, index) => (
                      <TabTile
                        assessmentId={assessment.assessmentId}
                        date={assessment.lastModificationDate}
                        onClick={() => openAssessment(assessment)}
                        tags={assessment.filledSteps}
                        key={assessment.clients[0]?.aclientId ?? index}
                        client={assessment.clients[0]}
                      />
                    ))}
                  </div>
                ) : (
                  <Alert type="info">Brak analiz.</Alert>
                )}
              </Tab.Panel>

              <Tab.Panel>
                {inProgressAssessments.length ? (
                  <div className="grid grid-cols-3 gap-8 min-h-[8.3rem]">
                    {inProgressAssessments.map(
                      (assessment, index) =>
                        assessment.withStatements === false && (
                          <TabTile
                            assessmentId={assessment.assessmentId}
                            date={assessment.lastModificationDate}
                            onClick={() => openAssessment(assessment)}
                            tags={assessment.filledSteps}
                            key={assessment.clients[0]?.aclientId ?? index}
                            client={assessment.clients[0]}
                          />
                        )
                    )}
                  </div>
                ) : (
                  <Alert type="info">Brak analiz.</Alert>
                )}
              </Tab.Panel>
            </Tabs>
          </>
        )}
      </Box>

      <Routes>
        <Route path="agent/:id/*" element={<UserModal />} />
        <Route path="marketingConsents/*" element={<MarketingConsentsForm />} />
        <Route path="searchCustomer/:type/*" element={<NewAnalysisModal />} />
      </Routes>

      <Outlet />
    </div>
  );
};

export default Dashboard;
