import { Text } from '@profitowi/component-library';
import React, { ReactNode } from 'react';

import { Client } from 'types/client';

type Props = {
  client: Client;
  generateAddressInfo: () => ReactNode;
};

function IndividualDetails({ client, generateAddressInfo }: Props) {
  return (
    <div className="flex-col space-y-4 ">
      <div className="flex justify-center space-x-4">
        <Text weight="bold">Imię i Nazwisko:</Text>
        <Text>
          {client.personalData.firstName || client.personalData.lastName ? (
            <>
              {client?.personalData.firstName} {client?.personalData.lastName}
            </>
          ) : (
            <>-</>
          )}
        </Text>
      </div>
      <div className="flex justify-center space-x-4">
        <Text weight="bold">Adres:</Text>
        <Text>{generateAddressInfo()}</Text>
      </div>
      <div className="flex justify-center space-x-4">
        <Text weight="bold">PESEL:</Text>
        <Text>{client?.personalData.pesel ? client?.personalData.pesel : '-'}</Text>
      </div>
    </div>
  );
}

export default IndividualDetails;
