import { Avatar, CheckBox, Icon, Text } from '@profitowi/component-library';
import { useEffect, useState } from 'react';

import { Client } from 'types/client';
import { CombinedStatements, Statements } from 'types/statements';

interface Props {
  client: Client;
  statements: Statements[];
}

const UserTile = ({ client, statements }: Props) => {
  const [clientStatements, setClientStatements] = useState<CombinedStatements[]>();
  useEffect(() => {
    if (statements && client.statements) {
      let joinedStatements: CombinedStatements[] = [...client.statements];

      client.statements.forEach((statement) => {
        statements.forEach((statementContent, index) => {
          if (statementContent.statementId === statement.statementId) {
            joinedStatements[index] = { ...joinedStatements[index], ...statementContent };
          }
        });
      });

      setClientStatements(joinedStatements);
    }
  }, [client.statements, statements]);

  return (
    <div className="flex">
      <Avatar bg="bg-orange-400" className="mr-3 !h-16 !w-16 flex items-center justify-center">
        <Icon name="person" className="text-[36px]" />
      </Avatar>
      <div className="flex flex-col">
        <Text size="lg" weight="bold" className="mb-2">
          {client.personalData.firstName + ' ' + client.personalData.lastName}
        </Text>
        <div className="flex gap-8">
          <div className="flex flex-col">
            <Text weight="bold" className="mb-2">
              Telefon:
            </Text>
            <Text weight="bold" className="mb-2">
              E-mail:
            </Text>
            <Text weight="bold" className="mb-2">
              Adres:
            </Text>
            <Text weight="bold" className="mb-2">
              Miejscowość:
            </Text>
          </div>
          <div className="flex flex-col">
            <Text weight="light" className="mb-2 min-h-[1.25rem]">
              {client.personalData.phone ? client.personalData.phone : '-'}
            </Text>
            <Text weight="light" className="mb-2 min-h-[1.25rem]">
              {client.personalData.email ? client.personalData.email : '-'}
            </Text>
            <Text weight="light" className="mb-2 min-h-[1.25rem]">
              {client.personalData.street
                ? 'ul.' +
                  client.personalData.street +
                  ' ' +
                  client.personalData.houseNo +
                  (client.personalData.flatNo ? '/' + client.personalData.flatNo : '')
                : '-'}
            </Text>
            <Text weight="light" className="mb-2 min-h-[1.25rem]">
              {client.personalData.city || client.personalData.zipCode
                ? client.personalData.zipCode + ', ' + client.personalData.city
                : '-'}
            </Text>
          </div>
        </div>

        {clientStatements && clientStatements.find((statement) => statement.consent === true) && (
          <div>
            <Text weight="bold" size="md" className="my-2">
              Zgody marketingowe:
            </Text>

            {clientStatements?.map((statement, index) => {
              return (
                <div className="space-y-4" key={statement.statementId + '' + index}>
                  {statement.consent && statement.content && (
                    <div className="my-2">
                      <CheckBox
                        id={statement.astatementId + ''}
                        isSelected={statement.consent}
                        name={statement.content}>
                        {statement.content}
                      </CheckBox>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTile;
