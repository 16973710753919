// temporary survey icons
const SURVEYS = {
  MIFID: {
    secondaryIcon: undefined,
    icon: 'savings',
    custom: true,
    bgColor: 'bg-violet-800',
  },
  AML: {
    secondaryIcon: undefined,
    icon: 'weight',
    custom: true,
    bgColor: 'bg-blue-800',
  },
};
export default SURVEYS;
