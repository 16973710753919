import { Tabs } from '@profitowi/component-library';
import logo from 'assets/new-logo.png';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';

import { ProductsPages } from '../../types/productsPages';

enum ProductsTabCategories {
  PRODUCTS = 'Produkty',
  DOCUMENTS = 'Dokumenty',
}

const ProductsPageLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tabCategories = Object.values(ProductsTabCategories);
  const [initiallySelectedTab, setInitiallySelectedTab] = useState<string>(tabCategories[0]);

  function onChangeTab(value: number) {
    if (
      tabCategories[value].toLocaleLowerCase() ===
      ProductsTabCategories.DOCUMENTS.toLocaleLowerCase()
    ) {
      navigate(ProductsPages.DOCUMENTS);
    } else if (
      tabCategories[value].toLocaleLowerCase() ===
      ProductsTabCategories.PRODUCTS.toLocaleLowerCase()
    ) {
      navigate(ProductsPages.PRODUCTS);
    }
  }

  useEffect(() => {
    if (pathname.includes(ProductsPages.PRODUCTS)) {
      setInitiallySelectedTab(ProductsTabCategories.PRODUCTS);
    }
    if (pathname.includes(ProductsPages.DOCUMENTS)) {
      setInitiallySelectedTab(ProductsTabCategories.DOCUMENTS);
    }
  }, []);

  return (
    <div className="mx-16 my-14">
      <Link to="/">
        <img alt="" src={logo} />
      </Link>
      <Tabs
        className="mt-8 !text-[1.25rem] font-bold"
        categories={tabCategories}
        variant="light"
        onChange={onChangeTab}
        initiallySelectedTab={initiallySelectedTab}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Tabs>
    </div>
  );
};

export default ProductsPageLayout;
