import { Button } from '@profitowi/component-library';
import { Cell, Column } from 'react-table';

import { AttachmentDataList } from 'types/documents';

export const columns = (
  onDeleteDocument: (documentId: number) => void
): Column<AttachmentDataList>[] => [
  {
    Header: 'Nazwa dokumentu',
    accessor: 'name',
    sortType: 'string',
  },
  {
    id: 'operations',
    Cell: ({
      row: {
        original: { id },
      },
    }: Cell<AttachmentDataList>) => {
      return (
        <div className="flex space-x-6 justify-end">
          <Button variant="light" onPress={() => onDeleteDocument(id)}>
            <i className="bi bi-trash cursor-pointer" />
          </Button>
        </div>
      );
    },
  },
];
