import { SummaryClient } from 'types/summary';

import Header from '../components/Header';
import PriorityDots from '../components/PriorityDots';
import SectionTitle from '../components/SectionTitle';

const style = {
  page: {
    backgroundColor: '#FFFFFF',
    color: '#19103B',
    height: '100%',
    fontFamily: 'Sora',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'initial',
  },
  content: {
    main: { width: '70%', margin: 'auto' },
  },
};

type Props = {
  clients: SummaryClient[];
};

const Areas = ({ clients }: Props) => {
  const returnPriority = (priority: string) => {
    switch (priority) {
      case 'LOWEST':
        return 0;
      case 'LOW':
        return 1;
      case 'HIGH':
        return 2;
      case 'HIGHEST':
        return 3;
      default:
        return 0;
    }
  };

  return (
    <div style={{ ...style.page }}>
      <div style={style.content.main}>
        {clients.length > 0 &&
          clients.map((client: any) => (
            <>
              {client?.areas.length > 0 && (
                <>
                  <Header text="Podsumowanie">
                    <SectionTitle
                      text={`Twoje potrzeby ${
                        client?.fullName.length !== 0 ? client.fullName : ''
                      }`}
                    />
                  </Header>
                  <div style={{ pageBreakAfter: 'always' }}>
                    {client?.areas.map(({ priority, name }: any) => (
                      <div
                        style={{
                          marginBottom: '10px',
                          pageBreakInside: 'avoid',
                          minHeight: '35px',
                        }}>
                        <div>
                          <span style={{ fontWeight: 600 }}>{name}</span>
                          <PriorityDots priority={returnPriority(priority)} />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default Areas;
