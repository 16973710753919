import { keyBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { QueryObserverSuccessResult } from 'react-query';

import { Area, AreaField } from 'types/area';
import { BasicClientData } from 'types/assessment';

import { AREA_TYPE } from '../../../types/forms';
import Tile from './Tile';

type Props = {
  areaData: AreaField;
  areaNames: string[];
  client: BasicClientData;
  areaDataQuery: QueryObserverSuccessResult;
};

const repeatableAreas: string[] = [AREA_TYPE.AUTO, AREA_TYPE.HOME, AREA_TYPE.PROPERTY];

const Areas = ({ areaData, areaNames, client, areaDataQuery }: Props) => {
  const mapOfSavedAreas = useMemo(
    () => keyBy(areaData?.areas || {}, (area: Area) => area.areaType),
    [areaData]
  );

  const areThereMultipleAreasWithThisName = useCallback(
    (name: string): Area[] => {
      if (areaData?.areas?.length) {
        const existingAreas = areaData.areas.filter((area) => area.areaType === name);

        if (existingAreas.length > 1) {
          return existingAreas as Area[];
        }
      }

      return [];
    },
    [areaData?.areas]
  );

  const isAreaAdditionAvailable = useCallback(
    (name: string): boolean => {
      const existingAreas = areThereMultipleAreasWithThisName(name);
      if (
        (existingAreas.length < 10 || existingAreas.length === 0) &&
        repeatableAreas.includes(name)
      ) {
        return true;
      }
      return false;
    },
    [areThereMultipleAreasWithThisName]
  );

  return (
    <div className="space-y-4">
      {areaNames.map((name) =>
        areThereMultipleAreasWithThisName(name).length ? (
          areThereMultipleAreasWithThisName(name).map((area) => (
            <Tile
              key={name + '_' + area.id}
              client={client}
              data={area}
              name={name}
              areaDataQuery={areaDataQuery}
              isAddButtonAllowed={isAreaAdditionAvailable(name)}
            />
          ))
        ) : (
          <Tile
            key={name}
            client={client}
            data={mapOfSavedAreas[name] as Area | undefined}
            name={name}
            areaDataQuery={areaDataQuery}
            isAddButtonAllowed={isAreaAdditionAvailable(name)}
          />
        )
      )}
    </div>
  );
};

export default Areas;
