import { Header } from '@profitowi/component-library';
import React, { ReactNode } from 'react';

import InfoHint from 'components/InfoHint';

type Props = {
  children: ReactNode;
  description: string;
  info?: string[] | null;
  hint?: string[] | null;
  required?: boolean;
};

const FieldRow = ({ children, description, info, hint, required }: Props) => {
  return (
    <div>
      <div className="flex justify-start items-center gap-x-1">
        {!!info?.length && info.map((val: any) => <InfoHint type="info" contents={val} />)}
        {!!hint?.length && hint.map((val: any) => <InfoHint type="hint" contents={val} />)}

        <Header size="lg" weight="bold">
          {description}
          {required && <span className="text-red-300"> *</span>}
        </Header>
      </div>

      {children}
    </div>
  );
};

export default FieldRow;
