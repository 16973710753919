import { Avatar, Box, Button, Icon, Loader, Slider, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { addDays, format } from 'date-fns';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { QueryObserverSuccessResult, useMutation } from 'react-query';

import AREAS from 'components/AreaIcons/areaIcons';
import { useAgentStore } from 'hooks/useAgentStore';
import useNotificationStore from 'hooks/useNotificationStore';
import { useStepValidation } from 'hooks/useStepValidation';
import { useAssessmentStore } from 'hooks/useStore';
import { postArea } from 'services/areas';
import { Area } from 'types/area';
import { ASSESSMENT_TYPE, BasicClientData } from 'types/assessment';

import ModalDescription from './ModalDescription';
import useDidMountEffect from './useDidMountEffect';

const options = [
  {
    priority: 'LOWEST',
    text: 'Nieważne',
  },
  {
    priority: 'LOW',
    text: 'Mniej ważne',
  },
  {
    priority: 'HIGH',
    text: 'Ważne',
  },
  {
    priority: 'HIGHEST',
    text: 'Bardzo ważne',
  },
];

type Props = {
  client: BasicClientData;
  data?: Area;
  name: string;
  areaDataQuery: QueryObserverSuccessResult;
  isAddButtonAllowed: boolean;
};

const Tile = ({ client, data, name, areaDataQuery, isAddButtonAllowed }: Props) => {
  const agentId = useAgentStore((state) => state.agent.agentId);
  const assessmentId = useAssessmentStore((state) => state.assessment.assessmentId || NaN);
  const { addNotification, removeNotification } = useNotificationStore(
    ({ addNotification, removeNotification }) => ({
      addNotification,
      removeNotification,
    })
  );

  const areaInfo = AREAS[name as keyof typeof AREAS];
  const { validateSteps } = useStepValidation();
  const [image, setImage] = useState<string | null>(null);
  const [date, setDate] = useState<Date | null>(data?.meeting ? new Date(data?.meeting) : null);
  const [slider, setSlider] = useState<number>(() => {
    const index = options.findIndex((option) => option.priority === data?.priority);

    if (index !== -1) {
      return index;
    }

    return 0;
  });

  const { isLoading, mutate } = useMutation<AxiosResponse, AxiosError, any>(
    (payload) => postArea(assessmentId, client.aclientId, payload, agentId),
    {
      onSuccess: () => {
        validateSteps(ASSESSMENT_TYPE.AREA);
        areaDataQuery.refetch();
      },
    }
  );

  function updateAreas(vars: {
    id?: string;
    areaType: string;
    priority?: string;
    meeting?: string;
  }) {
    mutate(
      {
        ...vars,
      },
      {
        onError: (error) => {
          addNotification({
            id: 'postArea-error',
            message: error.message,
            title: 'Wystąpił błąd podczas zapisywania danych',
            type: 'error',
          });
        },
        onSuccess: () => {
          addNotification({
            id: 'postArea-autosave',
            title: 'Automatyczne zapisywanie',
            type: 'info',
          });
          removeNotification('postArea-error');
        },
      }
    );
  }

  function addNewArea() {
    updateAreas({ areaType: name });
  }

  useDidMountEffect(() => {
    if (date || slider.toString()) {
      updateAreas({
        id: data?.id.toString(),
        areaType: name,
        priority: options[slider].priority,
        meeting: date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : undefined,
      });
    }
  }, [date, slider]);

  return (
    <Box className="relative" key={name + '_' + data?.id}>
      {(isLoading || areaDataQuery.isFetching) && (
        <div className="bg-white opacity-80 absolute inset-0 flex align-middle justify-center z-10">
          <Loader className="h-10 w-10" />
        </div>
      )}

      <div className="flex justify-end space-x-2">
        {areaInfo?.icon && (
          <>
            <Button size="sm" variant="secondary">
              <div onClick={() => setImage(areaInfo?.icon)} className="-my-2 -mx-4">
                <Icon name="info" className="text-xl" />
              </div>
            </Button>

            {areaInfo?.secondaryIcon && (
              <Button size="sm" variant="secondary">
                <div onClick={() => setImage(areaInfo?.secondaryIcon)} className="-my-2 -mx-4">
                  <Icon name="info" className="text-xl" />
                </div>
              </Button>
            )}
            {image && <ModalDescription setImage={() => setImage(null)} name={image} />}
          </>
        )}

        {isAddButtonAllowed && (
          <Button size="sm" variant="secondary">
            <div onClick={addNewArea} className="-my-2 -mx-4">
              <Icon name="plus" className="text-xl" />
            </div>
          </Button>
        )}
      </div>

      <div className="flex items-center space-x-4">
        <Avatar bg={areaInfo?.bgColor} className="!h-20 !w-20">
          <Icon custom name={areaInfo?.icon} className="w-16" />
        </Avatar>

        <div className="flex-grow-0 w-2/12">
          <Text>{areaInfo?.name}</Text>
        </div>

        <div className="w-1/2">
          <Slider
            className="px-6"
            defaultValue={[slider]}
            key={name}
            maxValue={options.length - 1}
            onChangeEnd={([value]) => setSlider(value)}
            options={options.map((option) => option.text)}
            step={1}
          />
        </div>

        <div>
          <DatePicker
            className="w-full"
            dateFormat="dd-MM-yyyy"
            minDate={addDays(new Date(), 0)}
            onChange={setDate}
            placeholderText="Wybierz termin"
            selected={date}
          />
        </div>
      </div>
    </Box>
  );
};

export default Tile;
