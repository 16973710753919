import { BasicClientData } from 'types/assessment';

export const getClientName = ({ clientType, firstName, lastName, name }: BasicClientData) => {
  if (clientType === 'COMPANY') {
    if (name) {
      return name;
    }

    return 'Firma';
  }

  if (firstName || lastName) {
    return [firstName, lastName].filter(Boolean).join(' ');
  }

  return 'Osoba prywatna';
};
