import { Avatar, Badge, Icon, Text } from '@profitowi/component-library';

import AREAS from 'components/AreaIcons/areaIcons';
import { options } from 'types/area';
import { SummaryArea } from 'types/form';

import Table from '../Table/Table';

interface Props {
  area: SummaryArea;
}

const InsuranceTile = ({ area }: Props) => {
  const areaType = AREAS[area.areaType as keyof typeof AREAS];
  return (
    <div className="flex py-4 border-b-[1px] last:border-none">
      <Avatar
        bg={areaType.bgColor ? areaType.bgColor : 'bg-green-600'}
        className="mr-3 !h-16 !w-16 flex items-center justify-center">
        <Icon
          name={areaType.icon}
          custom={areaType.custom}
          className={areaType.custom ? 'w-12' : 'text-[36px]'}
        />
      </Avatar>
      <div className="flex flex-col items-between w-full">
        <Text weight="bold" size="base">
          {areaType.name}
        </Text>
        <div className="flex justify-between my-4">
          <Badge key={area.priority} className="mr-2 h-min w-min text-center">
            <Text size="xs" weight="bold" className="whitespace-nowrap normal-case">
              {options.find((option) => option.priority === area.priority)?.text}
            </Text>
          </Badge>
          {area.meeting && (
            <div className="flex">
              <Text size="base" weight="bold" className="text-gray-400">
                Termin spotkania<Icon name="calendar" className="mx-3"></Icon>
              </Text>
              <Text size="base"> {area.meeting?.slice(0, 10)}</Text>
            </div>
          )}
        </div>
        {area.formSummaries &&
          area.formSummaries.map((form) =>
            form.sections.map((table, index) => (
              <div key={form.formName + index} className="-ml-20">
                <Text className="py-4" size="md" weight="bold">
                  {form.formName}
                </Text>
                {table && <Table form={table} />}
              </div>
            ))
          )}
      </div>
    </div>
  );
};

export default InsuranceTile;
