import { Button } from '@profitowi/component-library';
import React, { ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAssessmentStore } from 'hooks/useStore';
import { StateType } from 'types/assessment';

type SidebarButtonProps = {
  children: ReactNode;
  to: string;
  type?: string;
  setCurrentTab: (type: string) => void;
};

const SidebarButton = ({ children, to, type = '', setCurrentTab }: SidebarButtonProps) => {
  const navigate = useNavigate();
  const assessmentState = useAssessmentStore((state) => state.assessment.state);
  const { pathname } = useLocation();

  const match = useMemo(() => {
    if (to === '/') {
      return pathname === to;
    }

    return pathname.includes(to);
  }, [pathname, to]);

  const onPress = () => {
    setCurrentTab(type);
    navigate(to);
  };

  return (
    <Button
      isDisabled={assessmentState === StateType.FINISHED}
      className="block w-full text-left !text-white"
      isActive={match}
      onPress={onPress}
      variant="light">
      <div className="flex items-center">{children}</div>
    </Button>
  );
};

export default SidebarButton;
