import http from 'utils/axios';

export const changePhoto = async (agentId: number, photoName: any, payload: FormData) => {
  const { data } = await http.post(`/agents/${agentId}/photo?fileName=${photoName}`, payload);

  return data;
};

export const downloadFile = async (fileId: number) => {
  const { data } = await http.get(`/files/${fileId}`, { responseType: 'blob' });

  return data;
};
