import { Button, Icon, Loader, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import { useMutation } from 'react-query';

import { downloadFile } from 'services/file';
import { SummaryRecommendation } from 'types/form';

interface Props {
  recommendation: SummaryRecommendation;
}

type Payload = { fileId: number; fileName: string };

const RecommendationTile = ({ recommendation }: Props) => {
  const { isLoading, mutate } = useMutation<Blob, AxiosError, Payload>(
    ({ fileId }) => downloadFile(fileId),
    {
      onSuccess: (file, { fileName }) => {
        saveAs(file, fileName);
      },
    }
  );

  return (
    <div className="flex flex-col mb-8">
      <Text weight="bold">{recommendation.productName}</Text>
      {recommendation.files.map(({ fileId, fileName }, index) => (
        <Button
          className="flex items-center space-x-2 !p-1"
          isDisabled={isLoading}
          key={fileId}
          onPress={() => mutate({ fileId, fileName })}
          variant="light">
          {isLoading && <Loader className="h-6 w-6" />}
          <Icon className="text-xl" name="file-pdf" />
          <Text weight="bold">{fileName}</Text>
        </Button>
      ))}
    </div>
  );
};

export default RecommendationTile;
