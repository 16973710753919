import { Tabs } from '@profitowi/component-library';
import { ReactNode, useMemo } from 'react';

import { BasicClientData } from 'types/assessment';
import { getClientName } from 'utils/common';

type Props = {
  children: ReactNode;
  clients: BasicClientData[];
};

const ClientNavigation = ({ clients, children }: Props) => {
  const names = useMemo(() => clients.map((client) => getClientName(client)), [clients]);

  return (
    <div>
      <Tabs categories={names} variant="dark">
        {children}
      </Tabs>
    </div>
  );
};

export default ClientNavigation;
