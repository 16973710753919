import { Button, Modal } from '@profitowi/component-library';
import React from 'react';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  action?: () => void;
  message?: string;
  confirmText?: string;
};

const ConfirmationModal = ({ visible, setVisible, action, message, confirmText }: Props) => {
  return (
    <Modal visible={visible} setVisible={setVisible} title="">
      <p className="text-center">{message ?? 'Czy jesteś pewien, że chcesz to zrobić?'}</p>
      <div className="flex justify-center gap-3">
        {action && (
          <Button
            onPress={() => {
              setVisible(false);
              action();
            }}
            variant="primary"
            className="!px-8">
            Tak
          </Button>
        )}

        <Button onPress={() => setVisible(false)} variant="outline-primary" className="!px-8">
          {confirmText ? confirmText : 'Nie'}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
