import { Box, Button, Header, Icon, InputField, Loader, Text } from '@profitowi/component-library';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import { PRODUCTS } from 'constants/queries/products';

import ErrorMessages from '../../components/ErrorMessages/ErrorMessages';
import Table from '../../components/Table/table';
import { deactivateProduct, deleteProductCondition, getProducts } from '../../services/products';
import { Product } from '../../types/products';
import ProductConditionsTable from './ProductConditions/ProductConditionsTable';
import ProductPageRoutes from './ProductPageRoutes';
import { columns } from './columns';

type CustomRow = {
  row: Row<Product>;
};

const ProductsPageView = () => {
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [nameSearch, setNameSearch] = useState<string>('');
  const [shortcutSearch, setShortcutSearch] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<number>();
  const navigate = useNavigate();

  const {
    data: productsList,
    isFetching: productsIsLoading,
    isError: productsIsError,
    error: productsError,
    refetch,
  } = useQuery(
    [PRODUCTS.PRODUCTS, nameSearch, shortcutSearch],
    () =>
      getProducts(
        nameSearch.length ? nameSearch : undefined,
        shortcutSearch.length ? shortcutSearch : undefined
      ),
    {
      keepPreviousData: true,
      select: (data) => {
        return data
          .sort((a, b) => a.orderNo - b.orderNo)
          .map((product) => ({ ...product, id: product.productId }));
      },
    }
  );

  function onSubmit({
    nameSearch,
    shortcutSearch,
  }: {
    nameSearch: string;
    shortcutSearch: string;
  }) {
    setNameSearch(nameSearch);
    setShortcutSearch(shortcutSearch);
  }

  const onDeleteRecommendation = (documentId: number) => {
    setSelectedRow(documentId);
    navigate('deleteRecommendation');
  };

  const onDeactivateProduct = (documentId: number) => {
    setSelectedRow(documentId);
    navigate('deactivateProduct');
  };

  function clearForm() {
    setNameSearch('');
    setShortcutSearch('');
  }

  const {
    mutate: mutateDeleteProductCondition,
    isLoading: deletionIsLoading,
    isError: deletionIsError,
    error: deletionError,
  } = useMutation(
    () => {
      return deleteProductCondition(selectedRow);
    },
    {
      onSuccess: () => {
        refetch();
        setSelectedRow(undefined);
        navigate(-1);
      },
    }
  );

  const {
    mutate: mutateDeactivateProductAttachments,
    isLoading: deactivationIsLoading,
    error: deactivationError,
    isError: deactivationIsError,
  } = useMutation(
    () => {
      return deactivateProduct(selectedRow);
    },
    {
      onSuccess: () => {
        refetch();
        setSelectedRow(undefined);
        navigate(-1);
      },
    }
  );

  return (
    <div>
      <Box className="space-y-6">
        <Header as="h4" size="lg" weight="semibold">
          Produkty
        </Header>

        <div className="flex justify-between">
          <Formik
            initialValues={{ nameSearch: '', shortcutSearch: '' }}
            onSubmit={onSubmit}
            onReset={clearForm}>
            <Form className="flex gap-4 flex-wrap">
              <InputField label="Nazwa produktu" name="nameSearch" placeholder="Nazwa produktu" />
              <InputField label="Kod produktu" name="shortcutSearch" placeholder="Kod produktu" />

              <Button className="" size="sm" type="submit">
                Szukaj
              </Button>
              <Button className="" size="sm" type="reset">
                Wyczyść
              </Button>
            </Form>
          </Formik>

          <div className="flex gap-4 flex-wrap">
            <Button
              className="flex items-center gap-2"
              size="sm"
              type="button"
              isDisabled={!selectedProducts.length}
              onPress={() =>
                navigate('changeProductsIda', {
                  state: {
                    selectedProducts,
                  },
                })
              }>
              {!!selectedProducts.length}
              <Icon className="text-xl font-bold" name="plus-lg" /> Zmień IDA zazanczonych produktów
            </Button>
            <Button
              className="flex items-center gap-2"
              size="sm"
              type="button"
              onPress={() => navigate('addProduct')}>
              <Icon className="text-xl font-bold" name="plus-lg" /> Dodaj produkt
            </Button>
          </div>
        </div>

        <Box.FullWidth>
          {productsIsLoading && <Loader className="h-12 w-12" />}

          {!productsIsLoading && !!productsList?.length && (
            <>
              <Table
                data={productsList}
                columns={columns(onDeactivateProduct)}
                isLoading={productsIsLoading || deletionIsLoading || deactivationIsLoading}
                isExpandable
                isSelectable
                onSelectionChange={(val) => setSelectedProducts(val)}
                renderRowSubComponent={({
                  row: {
                    original: { recConditions, productId },
                  },
                }: CustomRow) => {
                  if (!recConditions?.length) {
                    return undefined;
                  }

                  return (
                    <div className="pl-20">
                      <ProductConditionsTable
                        productConditionsData={recConditions}
                        productId={productId}
                        onDeleteRecommendation={onDeleteRecommendation}
                      />
                    </div>
                  );
                }}
              />

              {productsIsError && <ErrorMessages error={productsError} />}
              {(deletionIsLoading || deactivationIsLoading) && <Loader className="h-12 w-12" />}
            </>
          )}

          {!productsIsLoading && !productsList?.length && (
            <div className="flex justify-start align-center mt-8 mb-4 px-6">
              <Text className="text-slate-600" size="lg">
                Nie udało się znaleźć produktów odpowiadających Twojemu zapytaniu.
              </Text>
            </div>
          )}
        </Box.FullWidth>
      </Box>

      <ProductPageRoutes
        refetch={refetch}
        mutateDeleteProductCondition={mutateDeleteProductCondition}
        deletionIsError={deletionIsError}
        deletionError={deletionError as AxiosError | null}
        mutateDeactivateProductAttachments={mutateDeactivateProductAttachments}
        deactivationIsError={deactivationIsError}
        deactivationError={deactivationError as AxiosError | null}
      />
    </div>
  );
};

export default ProductsPageView;
